import React, { useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { useOnClickOutside } from "common/hooks/use-outside-click"
import { Option } from "common/models/option"
import { shallowEqual } from "common/utils/functions"
import { isEmptyString, isNullOrUndefined } from "common/utils/gates"

import { COACHING_PLAN_ARCHIVE_ITEMS, COACHING_PLAN_MENU_ITEMS } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import Button from "main-app/shared/button/Button"
import WithMenuCard from "main-app/shared/card/WithMenuCard"
import DragElement from "main-app/shared/drag-n-drop/DragElement"
import TextInput from "main-app/shared/input/TextInput"
import { GoalsSortType } from "main-app/shared/types/sort"

import { useBehavioralChangeData } from "../api/behavioral-goal.api"
import { BehavioralChange } from "../model/behavioral-goal"

import { BehavioralButton } from "./BehavioralButton"
import BehavioralPractice from "./practice/BehavioralPractice"

import "./styles.scss"

type Props = {
    index: number
    sort: GoalsSortType
    provider: any
    snapshot: any
    showDrag: boolean
    isArchived: boolean
    onArchive: (index: number) => void
    onBlurBehavioralChange: (index: number) => void
    setHasArchivedPractices: () => void
    showInitialProjectedDeleteModal: (value: string, index: number) => void
}

export const BehavioralGoalCard = ({
    index,
    sort,
    isArchived,
    provider,
    snapshot,
    showDrag,
    onArchive,
    onBlurBehavioralChange,
    setHasArchivedPractices,
    showInitialProjectedDeleteModal
}: Props) => {
    const { t } = useTranslation()

    const { user } = useAuthContext()
    const params = useParams()
    const userId = !isNullOrUndefined(params?.id) ? +params?.id : user?.id
    const { formState, getValues, register, setError, clearErrors, setValue } = useFormContext<{
        goals: BehavioralChange[]
    }>()
    const { data: goals } = useBehavioralChangeData(userId, sort)
    const [showExtra, setShowExtra] = useState(false)
    const goal = getValues(`goals.${index}`)
    const [isEdit, setIsEdit] = useState(index === 0 && !goal.title)
    const [showProjectImpact, setShowProjectImpact] = useState(false)
    const [showAdditionalNote, setShowAdditionalNote] = useState(false)
    const cardRef = useRef()

    useEffect(() => {
        setShowAdditionalNote(!!goal.additionalNotes)
        setShowProjectImpact(!!goal.projectedImpact)

        if (isEdit) {
            setShowExtra(true)
        }
    }, [goal, isEdit])

    const onMenuClick = (item: Option) => {
        if (item.value === "archive" || item.value === "unarchive") {
            onArchive(index)
        } else {
            showFields()
        }
    }

    const showFields = () => {
        setIsEdit(true)
        setShowExtra(true)
        clearErrors()
    }

    const onClickCard = () => {
        if (isArchived) {
            return
        }
        showFields()
    }

    const setValues = (setInitial: boolean) => {
        setValue(`goals.${index}.title`, setInitial ? goals[index].title : "")
        setValue(`goals.${index}.description`, setInitial ? goals[index].description : "")
        setValue(`goals.${index}.additionalNotes`, setInitial ? goals[index].additionalNotes : "")
        setValue(`goals.${index}.projectedImpact`, setInitial ? goals[index].projectedImpact : "")
    }

    const checkValidation = () => {
        const fields = getValues("goals")
        const { title, description, projectedImpact, additionalNotes } = getValues(`goals.${index}`)
        const hasOtherValues = [description, projectedImpact, additionalNotes].some(value => !isEmptyString(value))
        const isEmptyTitleOnEdit = isEmptyString(title) && goals.length === fields.length
        const isEmptyFieldsOnAdd = isEmptyString(title) && hasOtherValues && goals.length !== fields.length

        if (isEmptyTitleOnEdit || isEmptyFieldsOnAdd) {
            setError(`goals.${index}.title`, { message: t("Field is required") })
            return false
        }

        return true
    }

    const onKeyDown = (e: any) => {
        if (e.key === "Escape") {
            const fields = getValues("goals")
            const currentGoal = fields[index]
            const foundGoal = goals.find(item => item.id === currentGoal.id)
            const hadValues =
                !isNullOrUndefined(foundGoal) &&
                Object.values(foundGoal).some(value => typeof value === "string" && !isEmptyString(value))

            setIsEdit(false)
            setShowExtra(false)

            const values = [
                currentGoal.title,
                currentGoal.description,
                currentGoal.additionalNotes,
                currentGoal.projectedImpact
            ]

            if (hadValues) {
                setValues(true)
                return
            }

            const hasValues = values.some(value => !isEmptyString(value))

            if (hasValues) {
                setValues(false)
            }

            clearErrors()
            onBlurBehavioralChange(index)
            return
        }

        if (e.key === "Enter" && !e.shiftKey) {
            submit()
        }
    }
    const submit = () => {
        const isOldValues = shallowEqual(goal, goals[index])
        const isValid = checkValidation()

        if (!isValid) {
            return
        }

        if (showProjectedImpactModal()) {
            return
        }

        if (isOldValues) {
            setIsEdit(false)
            setShowExtra(false)
            clearErrors()
            return
        }

        if (showExtra) {
            setShowExtra(false)
        }

        if (isEdit) {
            setIsEdit(false)
        }

        onBlurBehavioralChange(index)
        clearErrors()
    }

    const showProjectedImpactModal = () => {
        const newGoals = getValues("goals")
        const { projectedImpact } = goal
        const oldProjectedImpact =
            newGoals?.length !== goals?.length
                ? getValues(`goals.${index}.projectedImpact`)
                : goals[index]?.projectedImpact

        if (projectedImpact !== oldProjectedImpact && !projectedImpact) {
            showInitialProjectedDeleteModal(oldProjectedImpact, index)
            setIsEdit(false)
            setShowExtra(false)
            clearErrors()
            return true
        }

        return false
    }

    useOnClickOutside(cardRef, () => {
        if (isEdit) {
            submit()
        }
    })

    const showPractice = useMemo(() => {
        const hasId = goals?.find(item => item.title === goal.title)
        return hasId
    }, [goals, goal])

    return (
        <div className="mb-2">
            <div className="d-flex align-items-stratch mb-1 with-drag-hover" ref={cardRef}>
                {showDrag && <DragElement provider={provider} snapshot={snapshot} />}
                <WithMenuCard
                    className="flex-1"
                    cardClassName={classNames("pr-4", {
                        "bg-light-accent": isArchived,
                        "border-light-accent": isArchived,
                        "no-left-borders": !isArchived && showDrag
                    })}
                    menuItems={isArchived ? COACHING_PLAN_ARCHIVE_ITEMS : COACHING_PLAN_MENU_ITEMS}
                    onMenuItemClick={onMenuClick}
                    menuOffsets={{ right: 5 }}
                    showContextMenu={!isEdit}>
                    <Button
                        variant="default"
                        className="p-0 m-0 d-block w-100 text-left behavioral-change-btn"
                        onClick={onClickCard}>
                        {isEdit && (
                            <p className="font-italic mb-10">
                                {t("What concrete behavioral changes could support progress on your goal?")}
                            </p>
                        )}
                        {!isEdit ? (
                            <p className={classNames("mb-1 color-gray font-extrabold")}>{goal.title}</p>
                        ) : (
                            <TextInput
                                groupClassName="mb-2 w-100 gray-placeholder"
                                errorClass="input-error-small"
                                variant="rect"
                                customPlaceholder={
                                    <>
                                        <span className="color-gray font-extrabold">{t("Title")}</span>{" "}
                                        <span className="color-gray">
                                            ({t("required, {{number}} character max", { number: 60 })})
                                        </span>
                                    </>
                                }
                                className="font-extrabold"
                                name={`goals.${index}.title`}
                                register={register}
                                autoFocus
                                maxLength={60}
                                onKeyDown={onKeyDown}
                                genericError={formState?.errors?.goals?.[index]?.title?.message}
                            />
                        )}
                        {!isEdit ? (
                            <p className={classNames("mb-0 color-gray ")}>{goal.description}</p>
                        ) : (
                            <TextInput
                                groupClassName={classNames("w-100 gray-placeholder", {
                                    "m-0": !showExtra,
                                    "mb-3": showExtra
                                })}
                                variant="rect"
                                errorClass="input-error-small"
                                placeholder={t("Description (optional)")}
                                name={`goals.${index}.description`}
                                register={register}
                                textArea
                                dynamicHeight
                                maxLength={1024}
                                genericError={formState?.errors?.goals?.[index]?.description?.message}
                                onKeyDown={onKeyDown}
                            />
                        )}
                        {showExtra && (
                            <>
                                <div className="gray-line" />
                                {!showProjectImpact ? (
                                    <BehavioralButton
                                        disabled={isEmptyString(goal?.title)}
                                        className="p-0 mb-1 mt-3 d-block"
                                        onClick={() => setShowProjectImpact(true)}>
                                        {t("Potential Impact")}
                                    </BehavioralButton>
                                ) : (
                                    <>
                                        <p className="mb-1 mt-3 font-italic  fs-14">{t("Potential Impact")}</p>
                                        <TextInput
                                            groupClassName="mb-1 w-100 gray-placeholder"
                                            variant="rect"
                                            errorClass="input-error-small"
                                            placeholder={t(
                                                "List a few outcomes you foresee as a result of making this behavioral change."
                                            )}
                                            name={`goals.${index}.projectedImpact`}
                                            register={register}
                                            textArea
                                            dynamicHeight
                                            maxLength={512}
                                            onKeyDown={onKeyDown}
                                        />
                                    </>
                                )}
                                {!showAdditionalNote ? (
                                    <BehavioralButton
                                        className="p-0 mb-1 d-block"
                                        disabled={isEmptyString(goal?.title)}
                                        onClick={() => setShowAdditionalNote(true)}>
                                        {t("Additional Notes")}
                                    </BehavioralButton>
                                ) : (
                                    <>
                                        <p className="mb-1 font-italic fs-14">{t("Additional Notes")}</p>
                                        <TextInput
                                            groupClassName="mb-1 w-100 gray-placeholder"
                                            variant="rect"
                                            errorClass="input-error-small"
                                            placeholder={t("Start typing...")}
                                            name={`goals.${index}.additionalNotes`}
                                            register={register}
                                            onKeyDown={onKeyDown}
                                            textArea
                                            dynamicHeight
                                            maxLength={1024}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Button>
                </WithMenuCard>
            </div>
            {showPractice && (
                <div className="pl-md-5 pl-2">
                    <BehavioralPractice
                        allowAdd={goals?.length > 1}
                        setHasArchivedPractices={setHasArchivedPractices}
                        isArchivedBehavioralChange={isArchived}
                        sort={sort}
                        behaviorIndex={index}
                    />
                </div>
            )}
        </div>
    )
}
