import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"

// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/browser"
import { useQueryClient } from "@tanstack/react-query"

import { HTTPStatus } from "common/constants"
import { http } from "common/http"
import { setUserSentry } from "common/utils/set-user-sentry"
import { setUserDataDog } from "common/utils/setuser-datadog"
import { getToken, removeToken } from "common/utils/tokens"

import Urls from "main-app/api/urls"
import { JS_CONF, ServerStateKeys } from "main-app/constants"
import User from "main-app/models/user"

import { useStoreContext } from "./GlobalStore"

export type AuthContextType = {
    user: User
    isLoading: boolean
}

export type AuthContextActionTypes = {
    setUser: React.Dispatch<React.SetStateAction<User | null>>
    getUserData: () => void
    logout: () => Promise<void>
    setLoading: (state: boolean) => void
    setAuthUser: (data) => void
    refetchUser(): Promise<void>
}

export const AuthContext = createContext<AuthContextType>(null)

export const AuthContextActions = createContext<AuthContextActionTypes>(null)

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState<User>(null)
    const isAuthenticated = useRef(JS_CONF.is_auth)
    const { resetState } = useStoreContext()
    const [isLoading, setLoading] = useState(true)
    const queryClient = useQueryClient()

    useEffect(() => {
        const token = getToken()
        if (!user && token && isAuthenticated.current) {
            getUserData()
        } else {
            setLoading(false)
        }
    }, [user, isAuthenticated])

    const getUserData = useCallback(async () => {
        try {
            const { data } = await http.get(Urls.me())
            const user: User = new User(data)
            setUser(user)
            queryClient.setQueryData([ServerStateKeys.User], () => user)
            setUserDataDog(user)
            setUserSentry(user)
            isAuthenticated.current = true
        } catch (e) {
            if (e?.response?.status === HTTPStatus.UNAUTHORIZED) {
                setUser(null)
                isAuthenticated.current = false
            }
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [])

    const refetchUser: () => Promise<void> = useCallback(async () => (await getUserData(), void 0), [getUserData])

    const setAuthUser = useCallback(
        data => {
            sessionStorage.removeItem("logout-action")
            setUser(data)
            setUserDataDog(data)
            isAuthenticated.current = true
        },
        [setUser]
    )

    const logout = useCallback(async () => {
        sessionStorage.setItem("logout-action", "done")
        try {
            await http.post(Urls.logout())
            isAuthenticated.current = false
            queryClient.clear()
            removeToken()
            setUser(null)
            resetState()
            Sentry.setUser(null)
            return Promise.resolve()
        } catch (error) {
            console.log(error)
        }
    }, [])

    const values: AuthContextType = useMemo(
        () => ({
            user,
            isLoading
        }),
        [user, isLoading]
    )

    const actions: AuthContextActionTypes = useMemo(
        () => ({
            logout,
            setLoading,
            getUserData,
            setAuthUser,
            setUser,
            refetchUser
        }),
        []
    )

    return (
        <AuthContext.Provider value={values}>
            <AuthContextActions.Provider value={actions}>{children}</AuthContextActions.Provider>
        </AuthContext.Provider>
    )
}

export interface IAuthContext extends AuthContextType, AuthContextActionTypes {}

export const useAuthContext = (): ReturnType<() => IAuthContext> => {
    const auth = useContext(AuthContext)
    const actions = useContext(AuthContextActions)
    if (auth === undefined || actions === undefined) {
        throw new Error("useAuthContext can only be used inside AuthProvider")
    }
    return {
        ...auth,
        ...actions
    }
}

export default AuthContextProvider
