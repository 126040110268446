import React, { type FC, type ReactElement } from "react"

import Heading from "common/components/Heading/Heading"
import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"

const CoachRelinkAccountsContentHeader: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="coach-relink-accounts__header">
            <Heading className="mb-1" tag="h1" textAlign="center">
                {t("coachSide.relinkAccountsPage.title")}
            </Heading>
            <p className="text-center color-gray font-sm">{t("coachSide.relinkAccountsPage.description")}</p>
        </div>
    )
}

export { CoachRelinkAccountsContentHeader }
