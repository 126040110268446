import React, { useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import * as yup from "yup"

import Heading from "common/components/Heading/Heading"
import { HTTPStatus } from "common/constants"
import { http } from "common/http"
import { getErrorMessages } from "common/utils/get-error-messages"

import Urls from "main-app/api/urls"
import { UserApi } from "main-app/models/user"
import Button from "main-app/shared/button/Button"
import NumberInput from "main-app/shared/input/NumberInput"
import Modal from "main-app/shared/modal"
import AttentionNotificationBox from "main-app/shared/notification/AttentionsNotificationBox"

import "./styles.scss"

type Props = {
    show: boolean
    email: string | null
    tfa_key: string | null
    onClose: () => void
    onSubmit: (data: UserApi & { token: string }) => void
}

const maskedInput = (value: string) =>
    value.replace(/-/g, "").replace(/(\d{1,3})-?(\d{0,3})?/, (_, group1, group2) => {
        if (!group1) return ""
        if (!group2) return group1
        return `${group1}-${group2}`
    })

const VerificationModal = ({ show, onClose, onSubmit, email, tfa_key }: Props) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [resendClicked, setResendClicked] = useState(false)
    const [error, setError] = useState(null)

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(
            yup.object({
                code: yup
                    .string()
                    .transform(value => value.split("-").join(""))
                    .min(6, "Ensure the code has at least 6 digits")
                    .required("Code is required")
            })
        )
    })

    const onResendClick = async () => {
        setResendClicked(true)

        try {
            await http.post(Urls.resend2fa(), {
                tfa_key
            })
        } catch (error) {
            setError({ status: error.response.status, message: getErrorMessages(error) })
        }
    }

    const onVerifyClick = async values => {
        setLoading(true)
        try {
            const { data } = await http.post(Urls.login2fa(), { code: values.code, tfa_key })
            onSubmit(data)
        } catch (error) {
            setError({ status: error.response.status, message: getErrorMessages(error) })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        let timeoutId
        if (resendClicked) {
            timeoutId = setTimeout(() => {
                setResendClicked(false)
            }, 5000)
        }

        return () => clearTimeout(timeoutId)
    }, [resendClicked])

    return (
        <Modal show={show} onClose={onClose} modalBodyClass="verification-modal">
            <section data-testid="verificaion-modal">
                <Heading>{t("Verify that it’s you")}.</Heading>
                <p className="mb-30 fs-14">
                    <Trans i18nKey="verificationEmailMessage">
                        <span>We sent a verification code to </span>
                        <span className="font-extrabold">{{ email }}</span>
                    </Trans>
                </p>

                {error && (
                    <AttentionNotificationBox withIcon={false} className="letter-spacing-reset mb-30">
                        <span className="font-extrabold color-gray">{t("Oops!")}</span>{" "}
                        <span>
                            {error.status !== HTTPStatus.BAD_REQUEST
                                ? error.message
                                : t("That code is either incorrect or expired. Please try again.")}
                        </span>
                    </AttentionNotificationBox>
                )}

                <p className="font-bold mb-1">{t("Verification Code")}</p>
                <NumberInput
                    groupClassName="mb-1"
                    placeholder="### - ###"
                    name="code"
                    max={7}
                    mask={maskedInput}
                    errors={errors}
                    isError={error}
                    control={control}
                />
                {resendClicked ? (
                    <p className="font-bold color-gray d-flex align-items-center fs-14 mb-30 p-1">
                        <svg
                            className="mr-1"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="20" height="20" rx="10" fill="#00E1AB" />
                            <path
                                d="M6.28271 10.1037L8.55445 12.582L13.7175 7.41895"
                                stroke="white"
                                strokeWidth="1.78125"
                                strokeLinecap="square"
                            />
                        </svg>
                        {t("We sent a new code")}.
                    </p>
                ) : (
                    <p className="mb-30 color-gray fs-14">
                        {t("Didn’t receive a code?")}{" "}
                        <Button variant="default" className="p-1 color-brand fs-14" onClick={onResendClick}>
                            {t("Resend")}
                        </Button>
                    </p>
                )}

                <div className="text-center">
                    <Button disabled={loading} isBusy={loading} onClick={handleSubmit(onVerifyClick)}>
                        {t("Verify & Sign In")}
                    </Button>
                </div>
            </section>
        </Modal>
    )
}

export default VerificationModal
