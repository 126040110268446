import React, { type ReactElement } from "react"

import { withTranslation } from "common/utils/lang"

import { Participant } from "main-app/models/participants"
import { Session } from "main-app/models/session"
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date"

import {
    ECoachTableHeaderName,
    ECoachTableType,
    type TCoachTableConfig,
    type TRenderCoachTableColumnBodyFunction
} from "../types"

import type { TCoachTableHeaderColumn } from "./CoachTableHeader"
import {
    CoachTableAvatarCell,
    CoachTableGeneralCell,
    CoachTableJoinSessionCell,
    CoachTableScheduledSessionCell,
    CoachTableSortedParticipantsCell
} from "./cells"

const sessionTableBodyColumns: TRenderCoachTableColumnBodyFunction[] = [
    (data: Session): ReactElement => (
        <CoachTableGeneralCell>
            <a className="d-flex align-items-center color-inherit">{data.cohort}</a>
        </CoachTableGeneralCell>
    ),
    (data: Session): ReactElement => (
        <CoachTableGeneralCell isLarge isHidden withCenterAlignedText>
            <p className="m-0">
                <span>{formatDateSessions(data.sessionTime)}</span>
                &nbsp;
                <span className="d-block mt-2 color-gray font-sm">{getTimezoneAbbreviation()}</span>
            </p>
        </CoachTableGeneralCell>
    ),
    (data: Session): ReactElement => (
        <CoachTableGeneralCell isLarge withCenterAlignedText withoutLeftPadding isHidden>
            <p className="m-0">
                {data.sessionType.name}
                <span className="d-block mt-2 color-gray font-sm">{data.sessionType.duration} min</span>
            </p>
        </CoachTableGeneralCell>
    ),
    (data: Session): ReactElement => (
        <CoachTableSortedParticipantsCell session={data} tableType={ECoachTableType.Sessions} />
    ),
    (data: Session): ReactElement => <CoachTableJoinSessionCell session={data} />
]

const groupsTableBodyColumns: TRenderCoachTableColumnBodyFunction[] = [
    (data: Session): ReactElement => (
        <CoachTableGeneralCell>
            <a className="d-flex align-items-center color-inherit">{data.cohort}</a>
        </CoachTableGeneralCell>
    ),
    (data: Session) => (
        <CoachTableGeneralCell isLarge isHidden>
            <span>{data?.title}</span>
        </CoachTableGeneralCell>
    ),
    (data: Session) => (
        <CoachTableGeneralCell isLarge isHidden>
            <p className="m-0">
                <span>{data.sessionType.name}</span>
                <span className="d-block mt-2 color-gray font-sm">{data.sessionType.duration} min</span>
            </p>
        </CoachTableGeneralCell>
    ),
    (data: Session): ReactElement => (
        <CoachTableGeneralCell isLarge isHidden>
            <p className="m-0">
                {formatDateSessions(data.sessionTime)}
                &nbsp;
                <span className="d-block mt-2 color-gray font-sm">{getTimezoneAbbreviation()}</span>
            </p>
        </CoachTableGeneralCell>
    ),
    (data: Session) => <CoachTableSortedParticipantsCell session={data} tableType={ECoachTableType.Groups} />
]

const participantsTableBodyColumns: TRenderCoachTableColumnBodyFunction[] = [
    (data: Participant): ReactElement => <CoachTableAvatarCell participant={data} />,
    (data: Participant): ReactElement => (
        <CoachTableGeneralCell isHidden isLarge>
            <span>{data?.cohort?.name}</span>
        </CoachTableGeneralCell>
    ),
    (data: Participant): ReactElement => <CoachTableScheduledSessionCell participant={data} />,
    (data: Participant): ReactElement => (
        <CoachTableGeneralCell>
            <span>{withTranslation(data?.currentModule?.versionName)}</span>
        </CoachTableGeneralCell>
    )
]

const sessionsTableHeaderColumns: TCoachTableHeaderColumn[] = [
    {
        name: ECoachTableHeaderName.Sessions_Cohort,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Cohort"
    },
    {
        name: ECoachTableHeaderName.Sessions_Time,
        withSortDesc: true,
        withoutLeftPadding: true,
        title: "Session Time",
        isSortingColumn: true
    },
    { name: ECoachTableHeaderName.Sessions_Type, isInvisible: true, isLargeTableCell: true, title: "Type" },
    {
        name: ECoachTableHeaderName.Sessions_Participants,
        withoutLeftPadding: true,
        withTextLeftAligned: true,
        title: "Participant(s)"
    },
    { name: ECoachTableHeaderName.Sessions_Blank, isLargeTableCell: true, title: "" }
]

const groupsTableHeaderColumns: TCoachTableHeaderColumn[] = [
    { name: ECoachTableHeaderName.Groups_Cohort, withTextLeftAligned: true, withoutLeftPadding: true, title: "Cohort" },
    {
        name: ECoachTableHeaderName.Groups_Name,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Group Name"
    },
    {
        name: ECoachTableHeaderName.Groups_SessionType,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Session Type"
    },
    {
        name: ECoachTableHeaderName.Groups_SessionTime,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Session Time",
        isSortingColumn: true
    },
    {
        name: ECoachTableHeaderName.Groups_Participants,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Participant(s)"
    }
]

const participantsTableHeaderColumns: TCoachTableHeaderColumn[] = [
    {
        name: ECoachTableHeaderName.Participants_Name,
        withSortDesc: true,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Participant Name",
        isSortingColumn: true
    },
    {
        name: ECoachTableHeaderName.Participants_Cohort,
        isInvisible: true,
        isLargeTableCell: true,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Cohort"
    },
    {
        name: ECoachTableHeaderName.Participants_NextMeeting,
        withTextLeftAligned: true,
        withoutLeftPadding: true,
        title: "Next Meeting"
    },
    {
        name: ECoachTableHeaderName.Participants_CurrentModule,
        withSortInc: true,
        isInvisible: true,
        isLargeTableCell: true,
        title: "Current Module",
        isSortingColumn: true
    }
]

const coachSessionsTableConfig: TCoachTableConfig = {
    tableType: ECoachTableType.Sessions,
    columnsBodies: sessionTableBodyColumns,
    columnHeaders: sessionsTableHeaderColumns
}

const coachGroupsTableConfig: TCoachTableConfig = {
    tableType: ECoachTableType.Groups,
    columnsBodies: groupsTableBodyColumns,
    columnHeaders: groupsTableHeaderColumns
}

const coachParticipantsTableConfig: TCoachTableConfig = {
    tableType: ECoachTableType.Participants,
    columnsBodies: participantsTableBodyColumns,
    columnHeaders: participantsTableHeaderColumns
}

export { coachSessionsTableConfig, coachGroupsTableConfig, coachParticipantsTableConfig }
