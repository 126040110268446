import React, { useEffect, useState } from "react"

import { AxiosError } from "axios"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player/vimeo"
import { useNavigate } from "react-router-dom"

import { http } from "common/http"
import { getErrorMessages } from "common/utils/get-error-messages"
import { TranslationObj, withTranslation } from "common/utils/lang"

import Urls from "main-app/api/urls"
import Button from "main-app/shared/button/Button"
import { sanitizeContent } from "main-app/utils/common"
import useNavigationOnboarding from "main-app/utils/hooks/use-navigation-onboarding"

import useNextStep from "./api/mutations/use-next-step"
import { StepsNavigator } from "./components/StepsNavigator"
import { WarningMessage } from "./components/WarningMessage"
import { OnboardingSteps } from "./constants"
import OnboardingLayout from "./layout/OnboardingLayout"

interface IProps {}

type DataApi = {
    on_boarding_text: TranslationObj
    on_boarding_video: TranslationObj
}

const VideoStep: React.FC<IProps> = () => {
    const { t } = useTranslation()
    const defaultDataApi: { on_boarding_text: TranslationObj; on_boarding_video: string } = {
        on_boarding_text: {},
        on_boarding_video: ""
    }
    const navigate = useNavigate()
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [isBusy, setIsBusy] = useState(false)
    const [dataApi, setDataApi] = useState(defaultDataApi)
    const { getNextStepUrl } = useNavigationOnboarding()
    const handleNextStep = useNextStep()

    useEffect(() => {
        handleProfileData()
    }, [])

    const handleProfileData = async () => {
        try {
            const { data }: { data: DataApi } = await http.get(Urls.onboardingStep1())
            const prefLangVideo = withTranslation(data?.on_boarding_video)
            const on_boarding_video =
                prefLangVideo?.length > 0 ? prefLangVideo.match(/\d+/)[0] : data?.on_boarding_video?.en.match(/\d+/)[0]
            const videoId = `https://vimeo.com/${on_boarding_video}`
            const apiData = { ...data, on_boarding_video: videoId }
            setDataApi(apiData)
        } catch (error) {
            setErrorsMsg(getErrorMessages(error))
            console.log(error)
        }
    }

    const handleNext = () => {
        setIsBusy(true)
        handleNextStep.mutate(
            { step: OnboardingSteps.VIDEO_STEP },
            {
                async onSuccess() {
                    const nextStep = await getNextStepUrl(OnboardingSteps.VIDEO_STEP)
                    navigate(nextStep.url)
                },
                onError(error: AxiosError) {
                    const errorMessage = getErrorMessages(error)
                    setErrorsMsg(errorMessage)
                    console.log(errorMessage)
                },
                onSettled() {
                    setIsBusy(false)
                }
            }
        )
    }

    return (
        <OnboardingLayout header={<StepsNavigator activeStep={{ id: OnboardingSteps.VIDEO_STEP, order: 1 }} />}>
            <WarningMessage message={errorsMsg} />
            <div className="onboarding-video-block mb-6">
                {dataApi.on_boarding_video && (
                    <div className="vimeo-container mx-auto">
                        <div className="vimeo-container">
                            <ReactPlayer
                                url={dataApi.on_boarding_video}
                                className="react-player"
                                controls
                                width="100%"
                                height="100%"
                                playing={false}
                                playsinline={false}
                                onError={e => {
                                    console.log(e)
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="pt-4">
                    {withTranslation(dataApi.on_boarding_text) && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizeContent(withTranslation(dataApi.on_boarding_text), {
                                    allowedTags: false
                                })
                            }}
                            className="onboarding-video_text font-light"
                        />
                    )}
                    <div className="mt-5 text-center">
                        <Button onClick={handleNext} isBusy={isBusy} className="z-index-10 font-weight-800 btn-200">
                            {t("Next")}
                        </Button>
                    </div>
                </div>
            </div>
        </OnboardingLayout>
    )
}

export default VideoStep
