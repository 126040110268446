import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { http } from "common/http"
import { withTranslation } from "common/utils/lang"

import Urls from "main-app/api/urls"
import { Summary } from "main-app/models/summary"
import ChevronToggle from "main-app/shared/chevron-toggle/ChevronToggle"
import LikertResultsItem from "main-app/shared/likert/LikertResultsItem"
import { sanitizeContent } from "main-app/utils/common"

import NextButton from "./NextButton"

import "./styles.scss"

interface IProps {
    onNextNavigation: () => void
    onNextComponent: (component: any, answer: any) => void
    isLast: boolean
    chapterSummary: Summary
    component: any
    disabledBtn: boolean
    likertQuestionsWithSummary: string[]
}

const ResultsLikertGroup: React.FC<IProps> = ({
    isLast = false,
    onNextNavigation,
    likertQuestionsWithSummary,
    chapterSummary,
    component,
    disabledBtn,
    onNextComponent
}) => {
    const { t } = useTranslation()
    const params = useParams()
    const [isActive, setIsActive] = useState(false)
    const [normalizedAnswers, setNormalizedAnswers] = useState([])
    const [components, setComponents] = useState([])
    const [summaryList, setSummaryList] = useState([])

    useEffect(() => {
        const summaries = []

        const answersList = components.reduce((answers, current) => {
            if (shouldIncludeComponent(current)) {
                answers.push(...getAnswersFromComponent(current))
                summaries.push(...getSummariesFromComponent(current))
            }
            return answers
        }, [])

        setSummaryList(summaries.length === 0 ? [chapterSummary] : summaries)
        setNormalizedAnswers(answersList)
    }, [likertQuestionsWithSummary, components])

    const shouldIncludeComponent = current => {
        return (
            likertQuestionsWithSummary.includes(current.uuid) &&
            "answer_data" in current &&
            current?.answer_data?.answer_list?.length > 0
        )
    }

    const getAnswersFromComponent = current => {
        const answers = []
        current?.answer_data?.answer_list?.forEach(answer => {
            current?.question_list?.forEach(question => {
                if (question.uuid === answer.question_uuid) {
                    answers.push({
                        uuid: question.uuid,
                        question: withTranslation(question.question),
                        option_uuid: answer.option_uuid,
                        options: question.options,
                        allow_free_response: answer?.free_response?.length > 0,
                        free_response: answer.free_response,
                        not_applicable_input: answer.not_applicable_input
                    })
                }
            })
        })
        return answers
    }

    const getSummariesFromComponent = current => {
        const summaries = []
        current.summary_list?.forEach(summary => {
            if (current.answer_data?.rank >= +summary.rank_from && current.answer_data?.rank <= +summary.rank_to) {
                summaries.push(summary)
            }
        })
        return summaries
    }

    useEffect(() => {
        getComponents()
    }, [])

    const getComponents = async () => {
        try {
            const { data } = await http.get(Urls.chapter(+params.id))
            setComponents(data.components)
        } catch (error) {
            console.log(error)
        }
    }

    const toggleAccordion = () => {
        setIsActive(prev => !prev)
    }

    const onClick = () => {
        if (isLast) {
            onNextComponent(component, component?.answer_data?.answer_list)
        } else {
            onNextNavigation()
        }
    }

    return (
        <>
            <div className="container--phase text-center">
                <div className="px-3">
                    {summaryList.map(summary => (
                        <div className="mb-2">
                            <h1 className="h2 font-extrabold font-italic">
                                {withTranslation(summary?.short_summary) ?? t("Your results")}
                            </h1>
                            <h2 className="h5 color-brand text-uppercase font-extrabold">
                                {withTranslation(summary?.summary_subtitle)}
                            </h2>
                            <div className="text-container text-container--sm content-text">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeContent(withTranslation(summary?.summary))
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    <NextButton isLast={isLast} className="mt-3 mb-40" onClick={onClick} disabled={disabledBtn} />
                </div>
            </div>
            <div className="container--phase-md px-3">
                <button className="result-collapse-toggle" onClick={toggleAccordion}>
                    <span className="show-response-text">{t("Show My Responses")}</span>
                    <span className="result-collapse-toggle-icon">
                        {/* <svg viewBox="0 0 24 24">
                            <path d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" fill="currentColor" />
                        </svg> */}
                        <ChevronToggle active={isActive} variant="light" />
                    </span>
                </button>
                <div className="free-response-results">
                    {isActive &&
                        normalizedAnswers?.map((answer, index) => (
                            <LikertResultsItem
                                answer={answer}
                                questionNumber={index + 1}
                                questionsCount={normalizedAnswers?.length}
                                key={answer.uuid || answer.question_uuid}
                            />
                        ))}
                </div>
            </div>
        </>
    )
}

export default ResultsLikertGroup
