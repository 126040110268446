import React from "react"

import "./styles.scss"

const CoachSetupListStep = () => {
    return (
        <ul className="p-0">
            <li className="d-flex align-items-center mb-2">
                <div className="coach-setup__list-step-order mr-3">1</div>
                <span>Link your calendar(s)</span>
            </li>
            <li className="d-flex align-items-center mb-2">
                <div className="coach-setup__list-step-order mr-3">2</div>
                <span>Link your Zoom account</span>
            </li>
            <li className="d-flex align-items-center mb-2">
                <div className="coach-setup__list-step-order mr-3">3</div>
                <span>Edit your availability</span>
            </li>
            <li className="d-flex align-items-center">
                <div className="coach-setup__list-step-order mr-3">4</div>
                <span>Confirm your availability</span>
            </li>
        </ul>
    )
}

export default CoachSetupListStep
