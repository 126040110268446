import * as React from "react"

function CheckMarkModuleIcon(props: React.SVGProps<SVGSVGElement> & { isActive?: boolean }) {
    const { width = 9, height = 7, isActive = false } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 9 7"
            fill="none"
            data-testid="check-mark-module-svg">
            <path
                d="M1.60596 3.98026L3.37452 5.90961L7.394 1.89014"
                stroke={isActive ? "#FD4D00" : "#6F6F6F"}
                strokeWidth="1.5"
                strokeLinecap="square"
            />
        </svg>
    )
}

export default React.memo(CheckMarkModuleIcon)
