import React from "react"

import { useTranslation } from "react-i18next"
import sanitizeHtml from "sanitize-html"

import { withTranslation } from "common/utils/lang"

import { WarningMessage } from "./WarningMessage"

import "./styles.scss"

type Props = {
    errors: any
    item: any
    error?: string | null
    name?: string
    register: any
    questionNumber: number
    questionStyle?: "one-line" | "brand"
}

const FreeResponseComponent = ({
    errors,
    item,
    register,
    questionNumber,
    error,
    name,
    questionStyle = "brand"
}: Props) => {
    const { t } = useTranslation()

    return (
        <>
            {questionStyle === "brand" && (
                <h4 className="h5 color-brand text-uppercase font-extrabold title-underline mb-3">
                    <span>{questionNumber}</span>
                </h4>
            )}
            <WarningMessage message={error || errors?.[item.uuid]?.message?.toString()} />
            <div className="card br-5 mb-50 card-offboarding">
                <div className="card-header br-5 border-bottom-0 px-20 py-3 card-header-offboarding">
                    {questionStyle === "one-line" && (
                        <p className="m-0">
                            <span className="font-extrabold">{questionNumber}. </span>
                            <span
                                className="d-inline"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(withTranslation(item?.question), { allowedTags: [] })
                                }}
                            />
                        </p>
                    )}
                </div>
                <textarea
                    className="type-text-field"
                    aria-label={t("Start typing...")}
                    data-testid="free-response-text-area"
                    {...register(name ?? item.uuid)}
                    placeholder={sanitizeHtml(withTranslation(item.prompt) || t("Start typing..."), {
                        allowedTags: []
                    })}
                />
            </div>
        </>
    )
}

export default FreeResponseComponent
