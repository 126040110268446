import React from "react"

import { useTranslation } from "react-i18next"

import "../styles.scss"

export const ConversationEndMessage = () => {
    const { t } = useTranslation()

    return (
        <div className="conversation-end">
            <div className="line-left" />
            <div className="conversation-end-text">
                <span>{t("Conversation Ended")}</span>
            </div>
            <div className="line-right" />
        </div>
    )
}
