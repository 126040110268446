import React, { FC, ReactElement } from "react"

import { useTranslation } from "react-i18next"

import ProfileImageLetter from "common/components/ProfileImageLetter/ProfileImageLetter"

import { Session } from "main-app/models/session"
import { CohortModalities } from "main-app/models/types"
import GuestBadge from "main-app/svgs/GuestBadge"

import {
    type ICroppedAndSortedParticipantList,
    useCroppedAndSortedParticipantList
} from "../../../hooks/use-cropped-and-sorted-participant-list"
import { ECoachTableType } from "../../types"

import { CoachTableGeneralCell } from "./CoachTableGeneralCell"

type TCoachTableSortedParticipantsCellProps = {
    session: Session
    tableType: ECoachTableType
}

const CoachTableSortedParticipantsCell: FC<TCoachTableSortedParticipantsCellProps> = ({
    session,
    tableType
}: TCoachTableSortedParticipantsCellProps): ReactElement => {
    const {
        sortedParticipantList,
        isParticipantsAmountExceedingViewLimit,
        exceedingParticipantsCount
    }: ICroppedAndSortedParticipantList = useCroppedAndSortedParticipantList({
        participantList: session?.participants
    })

    const { t } = useTranslation()

    const cellLabel: string =
        (tableType === ECoachTableType.Sessions &&
            session?.cohortModality === CohortModalities.Individual &&
            session?.participants?.length === 1) ||
        (tableType === ECoachTableType.Groups && session?.participants?.length === 1)
            ? `${session?.participants?.[0]?.firstName} ${session?.participants?.[0]?.lastName}`
            : session.title

    return (
        <CoachTableGeneralCell withoutLeftAlignedText>
            <div className="d-flex align-items-center justify-content-lg-start mb-2">
                {sortedParticipantList?.map((participant, index) => (
                    <React.Fragment key={participant.id}>
                        {participant.photo || participant.isGuest ? (
                            <div
                                className="session-participant-img"
                                title={`${participant.firstName} ${participant.lastName}`}
                                style={{ zIndex: index + 1 }}>
                                {participant.isGuest ? (
                                    <GuestBadge width={27} height={27} className="guest-participant-avatar-badge" />
                                ) : participant.photo ? (
                                    <img src={participant.photo} />
                                ) : null}
                            </div>
                        ) : (
                            <div className="session-participant-img" style={{ zIndex: index + 1 }}>
                                <ProfileImageLetter
                                    firstName={participant.firstName}
                                    lastName={participant.lastName}
                                    width={27}
                                    height={27}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
                {isParticipantsAmountExceedingViewLimit && (
                    <span className="color-gray">
                        {t("coachDashboard.sessionsTab.participantCount", { count: exceedingParticipantsCount })}
                    </span>
                )}
            </div>
            <span className="color-gray text-left d-block w-100 font-sm">{cellLabel}</span>
        </CoachTableGeneralCell>
    )
}

export { CoachTableSortedParticipantsCell, type TCoachTableSortedParticipantsCellProps }
