import { useMutation } from "@tanstack/react-query"

import { http } from "common/http"

import { Urls } from "../urls"

export function useSubmitEnrollment(participantId: number) {
    return useMutation((moduleId: number) =>
        http.post<{ id: number }>(Urls.submitModuleEnrollment(participantId), { module: moduleId })
    )
}
