import React from "react"

import { useTranslation } from "react-i18next"

import { sanitizeContent } from "main-app/utils/common"

type Props = {
    question: string
    answer: string
}

const AssessmentResponseItem = ({ question, answer }: Props) => {
    const { t } = useTranslation()

    return (
        <div className="assessment-responses-item font-sm">
            <span className="color-gray mb-2">
                {sanitizeContent(question, {
                    allowedTags: ["b", "i", "em", "strong", "a"]
                })}
            </span>
            <span className="d-block color-gray font-extrabold">
                {t("You said")}: {answer}
            </span>
        </div>
    )
}

export default AssessmentResponseItem
