import { useCallback } from "react"

import {
    QueryClient,
    type QueryObserverOptions,
    type UseQueryResult,
    useQuery,
    useQueryClient
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { ServerStateKeys } from "main-app/constants"

interface ICoachAccountToRelinkDto {
    profile_id: string
    profile_name: string
    relink_url: string
}

interface ICoachAccountCalendarToRelinkDto extends ICoachAccountToRelinkDto {
    profile_primary: boolean
    disconnect_url: string
}

interface ICoachAccountsToRelinkDto {
    calendar_profiles: ICoachAccountCalendarToRelinkDto[]
    zoom_profile: ICoachAccountToRelinkDto
}

interface ICoachAccountToRelinkModel {
    profileId: string
    profileName: string
    relinkUrl: string
}

interface ICoachAccountZoomToRelinkModel extends ICoachAccountToRelinkModel {}

interface ICoachAccountCalendarToRelinkModel extends ICoachAccountToRelinkModel {
    isPrimaryAccount: boolean
    disconnectUrl: string
}

interface ICoachAccountsToRelinkModel {
    calendars: ICoachAccountCalendarToRelinkModel[]
    zoom: ICoachAccountZoomToRelinkModel
}

type TGetCoachAccountsToRelinkQueryParams = QueryObserverOptions

type TUseCoachAccountsToRelinkQuery = UseQueryResult<ICoachAccountsToRelinkModel, AxiosError<any, any>>

function coachAccountsToRelinkAdapter({
    calendar_profiles,
    zoom_profile
}: ICoachAccountsToRelinkDto): ICoachAccountsToRelinkModel {
    return {
        calendars: calendar_profiles.map(
            ({
                profile_id,
                profile_name,
                relink_url,
                profile_primary,
                disconnect_url
            }: ICoachAccountCalendarToRelinkDto): ICoachAccountCalendarToRelinkModel => ({
                profileName: profile_name,
                profileId: profile_id,
                relinkUrl: relink_url,
                isPrimaryAccount: profile_primary,
                disconnectUrl: disconnect_url
            })
        ),
        zoom: {
            profileName: zoom_profile.profile_name,
            relinkUrl: zoom_profile.relink_url,
            profileId: zoom_profile.profile_id
        }
    }
}

async function coachAccountsToRelinkFetcher(): Promise<ICoachAccountsToRelinkDto> {
    const { data } = await http.get(Urls.getCoachAccountsToRelink())

    return data
}

function useCoachAccountsToRelinkQuery({
    ...params
}: TGetCoachAccountsToRelinkQueryParams): ReturnType<
    (params?: TGetCoachAccountsToRelinkQueryParams) => TUseCoachAccountsToRelinkQuery
> {
    const client: QueryClient = useQueryClient()

    return useQuery([ServerStateKeys.CoachRelinkingAccounts], useCallback(coachAccountsToRelinkFetcher, []), {
        select: useCallback(coachAccountsToRelinkAdapter, []),
        enabled: params.enabled,
        initialData: useCallback(() => client.getQueryData([ServerStateKeys.CoachRelinkingAccounts]), [client])
    })
}

export {
    useCoachAccountsToRelinkQuery,
    type TUseCoachAccountsToRelinkQuery,
    type ICoachAccountsToRelinkModel,
    type ICoachAccountZoomToRelinkModel,
    type ICoachAccountCalendarToRelinkModel
}
