import React, {
    type Dispatch,
    type FC,
    type ReactElement,
    type SetStateAction,
    startTransition,
    useCallback,
    useEffect,
    useState
} from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import Button from "main-app/shared/button/Button"
import type { TEmptyCallback } from "main-app/shared/types/functions"

import { shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime } from "../utils"

type TGuestParticipantSwapSessionOpenerProps = { sessionTime: string; sessionId: number; shouldBeHidden: boolean }

const GuestParticipantSwapSessionOpener: FC<TGuestParticipantSwapSessionOpenerProps> = ({
    sessionTime,
    sessionId,
    shouldBeHidden
}: TGuestParticipantSwapSessionOpenerProps): ReactElement => {
    const { t } = useTranslation()

    const navigate: NavigateFunction = useNavigate()

    const handleOpenerClick: TEmptyCallback = useCallback(
        (): void => navigate(`/session-swapping/${sessionId}`),
        [navigate, sessionId]
    )

    const [shouldOpenerBeVisibleBasedOnSessionDate, setShouldOpenerBeVisibleBasedOnSessionDate]: [
        boolean,
        Dispatch<SetStateAction<boolean>>
    ] = useState<boolean>(shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime))

    const handleOpenerVisibility: TEmptyCallback = (): void => {
        startTransition(() =>
            setShouldOpenerBeVisibleBasedOnSessionDate(
                shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime)
            )
        )
    }

    useEffect(
        () => (
            setInterval(handleOpenerVisibility, 1000), () => clearInterval(setInterval(handleOpenerVisibility, 1000))
        )
    )

    return (
        <Button
            variant="outline-secondary"
            onClick={handleOpenerClick}
            className={classNames("swap-session__opener", {
                hidden: shouldBeHidden || !shouldOpenerBeVisibleBasedOnSessionDate
            })}>
            {t("participantSide.coachingSessionsScreen.swapSessionOpener")}
        </Button>
    )
}

export { GuestParticipantSwapSessionOpener, type TGuestParticipantSwapSessionOpenerProps }
