import React, { type FC } from "react"

import { Participant } from "main-app/models/participants"
import { CoachSession } from "main-app/models/session"
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date"

import { CoachTableGeneralCell } from "./CoachTableGeneralCell"

type TCoachTableScheduledSessionCellProps = {
    participant: Participant
}

const CoachTableScheduledSessionCell: FC<TCoachTableScheduledSessionCellProps> = ({
    participant
}: TCoachTableScheduledSessionCellProps) => {
    const nextSession: CoachSession | undefined = participant?.upcomingSessions?.find(
        (session: CoachSession) => session.sessionTime
    )

    return (
        <CoachTableGeneralCell isLarge isHidden>
            {nextSession ? (
                <p className="m-0">
                    {formatDateSessions(nextSession?.sessionTime)}{" "}
                    <span className="d-block mt-2 color-gray font-sm">{getTimezoneAbbreviation()}</span>
                </p>
            ) : (
                <span>Not yet scheduled</span>
            )}
        </CoachTableGeneralCell>
    )
}

export { CoachTableScheduledSessionCell, type TCoachTableScheduledSessionCellProps }
