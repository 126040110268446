import React, { useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useMediaQuery from "common/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { sanitizeContent } from "main-app/utils/common"

interface IProps {
    description: string
}

const TrackPracticeDescription: React.FC<IProps> = ({ description }) => {
    const { t } = useTranslation()

    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const [isActiveDescription, setIsActiveDescription] = useState(false)

    const toggleAccordion = () => {
        setIsActiveDescription(prev => !prev)
    }

    const isDesktop = !matches

    const isMobileAndShowAccordion = matches && isActiveDescription

    return (
        <>
            {matches && (
                <div
                    className={classNames(
                        "hide_assignment d-flex align-items-center justify-content-center cursor-pointer",
                        { active: isActiveDescription }
                    )}
                    onClick={toggleAccordion}>
                    <p className="font-sm color-brand font-extrabold m-0 mr-2">
                        {isActiveDescription ? t("Hide") : t("Show")} Practice Assignment
                    </p>
                    <svg
                        width="13"
                        height="9"
                        viewBox="0 0 13 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ transform: `rotate(${isActiveDescription ? "180deg" : "0deg"})` }}>
                        <path
                            d="M10.75 6.625L6.5 2.375L2.25 6.625"
                            stroke="#FD4D00"
                            strokeWidth="2"
                            strokeLinecap="square"
                        />
                    </svg>
                </div>
            )}
            {isDesktop || isMobileAndShowAccordion ? (
                <div
                    className="track-practice-assignment-box"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeContent(description, {
                            allowedStyles: {
                                "*": {
                                    color: [
                                        /^#(0x)?[0-9a-f]+$/i,
                                        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
                                    ],
                                    "text-align": [/^left$/, /^right$/, /^center$/],
                                    "font-size": [/^\d+(?:px|em|%)$/]
                                }
                            }
                        })
                    }}
                />
            ) : null}
        </>
    )
}

export default TrackPracticeDescription
