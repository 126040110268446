import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "common/http"

import Urls from "main-app/api/urls"

import { ServerStateOnbordingKeys } from "../constants"

const getSessionHold = async () => {
    const { data } = await http.get(Urls.onboardingStep6())
    return data
}

export default function useSessionHolds(enabled: boolean = true) {
    return useQuery([ServerStateOnbordingKeys.SessionHold], getSessionHold, {
        select: useCallback(data => {
            return data
        }, []),
        enabled
    })
}
