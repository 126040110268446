import React from "react"

import { ComponentType } from "main-app/constants"

import QuestionFreeResponses from "./QuestionFreeResponses"
import QuestionLikertResponses from "./QuestionLikertResponses"
import TrackPracticeResponses from "./TrackPracticeResponses"

interface IProps {
    chapter: any
    participantName: string
}
const renderComponent = props => {
    return {
        [ComponentType["Question: Free Response"]]: <QuestionFreeResponses {...props} />,
        [ComponentType["Question: Likert Group"]]: <QuestionLikertResponses {...props} />,
        [ComponentType["Track Practice"]]: <TrackPracticeResponses {...props} />
    }
}

const ResponsesItem: React.FC<IProps> = ({ chapter, participantName }) => {
    return <>{chapter?.components?.map(component => renderComponent({ component, participantName })[component.type])}</>
}

export default ResponsesItem
