import React from "react"

import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
// eslint-disable-next-line import/no-unresolved
import "vite/modulepreload-polyfill"

import useInitDataDog from "common/hooks/use-init-datadog"
import "common/i18n/i18n"

import { OnboardingStoreProvider } from "main-app/components/onboarding/onboardingStore"
import { ROOT_ELEMENT_SELECTOR } from "main-app/constants"

import Main from "./components/Main"
import AuthContextProvider from "./context/Auth"
import { GlobalStoreContexProvider } from "./context/GlobalStore"
import { queryClient } from "./query-client"
import { ErrorBoundary } from "./shared/error-boundary/component"

const pageContainer: HTMLDivElement = document.getElementById(ROOT_ELEMENT_SELECTOR) as HTMLDivElement
const root = createRoot(pageContainer)

const MainContainerWithProfiler = Sentry.withProfiler(Main)

const MainApp = () => {
    useInitDataDog()

    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ErrorBoundary>
                    <GlobalStoreContexProvider>
                        <AuthContextProvider>
                            <OnboardingStoreProvider>
                                <MainContainerWithProfiler />
                            </OnboardingStoreProvider>
                        </AuthContextProvider>
                    </GlobalStoreContexProvider>
                </ErrorBoundary>
            </QueryClientProvider>
        </BrowserRouter>
    )
}

if (pageContainer) {
    root.render(
        <React.StrictMode>
            <MainApp />
        </React.StrictMode>
    )
}
