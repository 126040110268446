import React, { createContext, useContext, useMemo, useState } from "react"

export type OnboardingStore = {
    storeData: any
    setStoreData?: (data: any) => any
}

export const initialOnboardingStore: OnboardingStore = {
    storeData: {},
    setStoreData: ({}) => ({})
}

export const OnboardingStoreContext = createContext(initialOnboardingStore)

export const OnboardingStoreProvider = ({ children }: any) => {
    const [storeData, setStoreData] = useState<any>({})

    const value = useMemo(
        () => ({
            storeData,
            setStoreData
        }),
        [storeData]
    )

    return <OnboardingStoreContext.Provider value={value}>{children}</OnboardingStoreContext.Provider>
}

export const useOnboardingStoreContext = () => {
    const store = useContext(OnboardingStoreContext)

    if (store === undefined) {
        throw new Error("useStoreContext can only be used inside storeContextProvider")
    }

    return {
        ...store
    }
}
