import React, { memo } from "react"

import classNames from "classnames"

import useMediaQuery from "common/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { useStoreContext } from "main-app/context/GlobalStore"
import ProductTypeLogo from "main-app/shared/ProductTypeLogo"

import UserHeaderDropdown from "./UserHeaderDropdown"

interface IProps {
    header?: JSX.Element | React.ReactNode | null
    withBurgerMenu?: boolean
    showLogo?: boolean
    fullWidth?: boolean
}

const ParticipantLayoutHeader: React.FC<IProps> = ({
    header,
    withBurgerMenu = true,
    fullWidth = false,
    showLogo = true
}) => {
    const { logout, user } = useAuthContext()
    const { isOpenBurgerMenu, toggleBurgerMenu } = useStoreContext()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const handleLogout = e => {
        e.preventDefault()

        logout()
    }

    return (
        <div
            className={classNames("participant-layout-header", {
                "gray-bg": isOpenBurgerMenu,
                "w-100": fullWidth,
                "justify-content-between": fullWidth,
                "show-spacing": !!header
            })}>
            <div
                className={classNames("sidebar-menu__logo--mobile", {
                    "d-none": isOpenBurgerMenu || !isMobile || !showLogo,
                    "d-inline": fullWidth
                })}>
                <ProductTypeLogo />
            </div>
            <div className={classNames("participant-layout-header-center", { "d-none": isOpenBurgerMenu })}>
                {header}
            </div>
            <UserHeaderDropdown handleLogout={handleLogout} user={user} showOnMobile={!isMobile} />
            {withBurgerMenu && (
                <button
                    className={classNames("menu-hamburger ml-auto", { open: isOpenBurgerMenu })}
                    onClick={toggleBurgerMenu}>
                    <span />
                    <span />
                    <span />
                </button>
            )}
        </div>
    )
}

export default memo(ParticipantLayoutHeader)
