import React from "react"

import classNames from "classnames"

import { JS_CONF } from "main-app/constants"

import "./styles.scss"

interface IProps {
    className?: string
    width?: number | string
    height?: number | string
}

const OwlLoader: React.FC<IProps> = ({ className, width = 100, height = 100 }) => {
    return (
        <div
            className={classNames("owl-loader", {
                [className]: className
            })}
            role="status"
            style={{ width, height }}>
            <img src={`${JS_CONF?.static}img/owl-loading.gif`} alt="owl loader" />
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default OwlLoader
