import React from "react"

import { withTranslation } from "common/utils/lang"

import { IQuestionFreeResponse } from "Manager/chapter/models/types"

interface IProps {
    component: IQuestionFreeResponse
}

const QuestionFreeResponses: React.FC<IProps> = ({ component }) => {
    const hasAnswer = "answer_data" in component

    return (
        <div className="text-center component-item">
            <h2 className="h5  ext-uppercase font-extrabold">{withTranslation(component?.title)}</h2>
            {hasAnswer ? (
                <div className="free-response-results">
                    <div className="result-collapse-container striped-gray">
                        <div className="result-collapse-item unset-min-height">
                            <h5 className="h5 font-extrabold mb-3">{withTranslation(component?.question)}</h5>
                            <p>{component?.answer_data?.answer}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <h1 className="question-responses-text">No response yet</h1>
            )}
        </div>
    )
}

export default QuestionFreeResponses
