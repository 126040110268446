import React, { useEffect, useState } from "react"

import { AxiosError } from "axios"
import { useTranslation } from "react-i18next"

import { http } from "common/http"

import Modal from "main-app/shared/modal"
import { joinDigitsWithDot } from "main-app/utils/common"

import Button from "../button/Button"

const ReloadAppModal = () => {
    const { t } = useTranslation()
    const [isVersionMismatch, setIsVersionMismatch] = useState(false)

    useEffect(() => {
        const interceptor = http.interceptors.response.use(
            response => {
                const serverVersion = response.headers["x-backend-version"]
                const staticVersion = joinDigitsWithDot(JSAPP_CONF?.static)
                if (serverVersion && serverVersion !== staticVersion) {
                    setIsVersionMismatch(true)
                }
                return response
            },
            (error: AxiosError) => {
                console.log(error)
                return Promise.reject(error)
            }
        )

        return () => {
            http.interceptors.response.eject(interceptor)
        }
    }, [])

    const onReloadClick = () => {
        window.location.reload()
    }

    return (
        <Modal show={isVersionMismatch} onClose={() => {}} enableOutSideClick={false} closeOnEscapePress={false}>
            <section className="p-5 text-center">
                <p className="font-extrabold fs-18">
                    {t("Please reload the page to load the latest version of the application")}
                </p>
                <Button onClick={onReloadClick}>{t("Reload Page")}</Button>
            </section>
        </Modal>
    )
}

export default ReloadAppModal
