import React from "react"

import classNames from "classnames"

import "./styles.scss"

interface IProps {
    className?: string
    variant?: "secondary" | "accent"
    size?: "small" | "normal"
    width?: number | string
    height?: number | string
}

const Spinner: React.FC<IProps> = ({ className, width = "2rem", height = "2rem", variant = "", size = "normal" }) => {
    return (
        <div
            className={classNames("spinner-border spinner-wiser", {
                [className]: className,
                sm: size === "small",
                [variant]: variant
            })}
            role="status"
            style={{ width, height }}>
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default Spinner
