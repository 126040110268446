import React from "react"

import classNames from "classnames"

import { isNullOrUndefined } from "common/utils/gates"

import { sanitizeContent } from "main-app/utils/common"

type Props = {
    order: number | null
    question: string
    isRequired?: boolean
    className?: string
}

const QuestionRow = ({ order, question, isRequired = false, className = "" }: Props) => {
    return (
        <>
            {question ? (
                <div className={classNames("mb-3 d-flex", className)}>
                    {isNullOrUndefined(order) ? null : <span className="font-extrabold mr-1">{order}. </span>}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: sanitizeContent(`${question}${isRequired ? "*" : ""}`, { allowedTags: [] })
                        }}
                    />
                </div>
            ) : null}
        </>
    )
}

export default QuestionRow
