import React from "react"

import SimpleLayout from "main-app/layouts/SimpleLayout"

import "./styles.scss"

export const PrivacyPolicyPage = () => {
    return (
        <SimpleLayout isSimpleHeader withoutHeader>
            <div className="c13 doc-content">
                <h1 className="c0 c3 privacy" id="h.i3aqtvujkoal">
                    <span className="c8">Privacy Policy</span>
                </h1>
                <p className="c0">
                    <span className="c4">Last updated: February 29th, 2024</span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span>WISER DOES NOT SELL YOUR PERSONAL DATA UNDER ANY CIRCUMSTANCES</span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span>
                        Welcome to Wiser! The privacy and security of your personal information is important to us. If
                        you have any questions about this Privacy Policy, please contact us at{" "}
                    </span>
                    <span className="c9 c12">
                        <a className="c11" href="mailto:privacy@wisermanagers.com">
                            privacy@wisermanagers.com
                        </a>
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h1 className="c0 c3" id="h.4f77mmr8j6td">
                    <span className="c8">1. Introduction and Definitions </span>
                </h1>
                <p className="c0">
                    <span className="c1">
                        This privacy policy describes our privacy practices relevant to the Site, Services and Platform
                        and the impact these practices have on Site Visitors, Coaches, Users, Stakeholders and User
                        Organizations. Each term is defined below.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        Let&rsquo;s start with a brief overview of what we do, how and where we might be collecting data
                        and then define some relevant terms. GetWiser, Inc. (&ldquo;Wiser&rdquo;) is a leadership
                        coaching platform that offers developmental coaching (the &ldquo;Services&rdquo;) through our
                        online platform (the &ldquo;Platform&rdquo;). This policy will also cover the activities of
                        GetWiser, Inc.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We will need to distinguish between the people to whom we provide Services directly via the
                        Platform (who we will refer to as &ldquo;Users&rdquo;) and the entities who contract with us to
                        provide Services to their employees on the Platform (who we will refer to as &ldquo;User
                        Organizations&rdquo;). We also have a subset of Users called Stakeholders; these are colleagues
                        of Users who, with permission of the User Organization, support the User by providing Coaches
                        with relevant input.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We collect, use and share data differently for Users and User Organizations, although they might
                        sometimes be one and the same. In the event you receive Wiser Services as a result of your
                        employer or other sponsoring organization, please be aware of the data which may be shared back
                        to your User Organization as a result of using the Wiser Platform.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We also collect data from coaches (&ldquo;Coaches&rdquo;) who typically contract with us to
                        provide services to Users via the Platform and from visitors (&ldquo;Visitors&rdquo;) to our
                        website www.wisercoaching.com and its subdomains (the &ldquo;Site&rdquo;).
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h1 className="c0 c3" id="h.45u3cwg59dn9">
                    <span className="c8">2. What Data We Collect </span>
                </h1>
                <h2 className="c0 c3" id="h.pzvx82g78rzz">
                    <span className="c7">Data From Our Website </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        If you are a visitor to our public website, wisercoaching.com and its subdomains, we and trusted
                        third parties acting on our behalf will collect certain information.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We use cookies and similar technologies (e.g., web beacons, pixels and ad tags) to collect data
                        (e.g., device IDs) from our public Site to recognize you and your device(s) on, off and across
                        different services and devices where you have engaged with our Site. We also allow third parties
                        to use cookies as the Cookie Policy describes, including to assist in our marketing and
                        advertising campaigns. We may also collect (or rely on others who collect) data about your
                        device where you have not engaged with our Site (e.g., ad ID, IP address, operating system and
                        browser data) so we can provide our Visitors with a better user experience. You can opt out from
                        our use of data from cookies and similar technologies that track your behavior on the sites of
                        others for ad targeting and other ad-related purposes.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span>Please review the </span>
                    <span>Cookie Policy</span>
                    <span className="c1">&nbsp;for all relevant details. </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.dc83xlnpbk8s">
                    <span className="c7">Data From Coaches </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        If you are a Coach, we collect data when you use the Platform, when you request technical
                        support, and when you otherwise initiate communication with us. We never require Coaches to
                        provide us any sensitive Personal Data (e.g. financial data), although we request demographic or
                        other personal data from Coaches to support matching Coaches with User Organizations and Users.
                        The type of demographic or personal data we may request may include gender, race, ethnicity,
                        locations, specialized skills such as language, and relevant experience. When asking for
                        demographic data, Wiser does so because we believe it will improve the services for our Users
                        and Coaches; however, providing such personal information shall remain optional.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.1wbl209gz7yp">
                    <span className="c7">Data From Users </span>
                </h2>
                <p className="c0">
                    <span>If you are a </span>
                    <span>User</span>
                    <span className="c1">
                        , we collect data when you register for the Platform and when you access the Services via the
                        Platform. We require certain Personal Data in order to register your account, such as:
                    </span>
                </p>
                <ul className="c6 lst-kix_wmdj1swbjd5y-0 start">
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">Your name </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">Your email address </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">Your phone number </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">Your role or title </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">Your Country and State (where applicable) </span>
                    </li>
                </ul>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We require this data for at least the following reasons: 1) to create your account; 2) to ensure
                        you are matched with a coach in an appropriate time zone; 3) ensure we have minimum location
                        data to honor your specific privacy rights; and 4) to ensure you receive applicable surveys or
                        questionnaires commensurate with your management level. If your access to the Wiser Services is
                        being provided by your User Organization, this type of data may be shared with Wiser from your
                        User Organization.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We collect data to provide and improve your experience on the Platform. For example, we may ask
                        Users to:
                    </span>
                </p>
                <ul className="c6 lst-kix_eoltm5bkvqdd-0 start">
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            complete surveys to help us match you with a Coach that aligns with your coaching goals.
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            complete an assessment that will allow you to gain insight into your strengths and
                            weaknesses
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">review your coaching and Platform experience. </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            enter your personal preferences within your User or Coach profile, such as preferred
                            pronouns and preferred language
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            answer reflection questions to help deepen understanding of issues, and of self
                        </span>
                    </li>
                </ul>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        To facilitate a robust and meaningful coaching experience, both you and your Coach will have
                        access to your matching survey, assessment responses, and reflection questions. Your sponsoring
                        User Organization will never receive access to your individual assessment or personalization
                        responses although they may see aggregate and de-identified reports of all Users connected to
                        your sponsoring Organization. We collect usage data from Users accessing the Platform, such as
                        which areas you focus on, your session ratings, when you attend a coaching session, your
                        employment details and any other usage data you share with us (or your User Organization shares
                        with us) in connection with the Platform.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        Your sponsoring User Organization will receive information from Wiser about your usage on the
                        Platform in the following ways:
                    </span>
                </p>
                <ul className="c6 lst-kix_389wm81e0yp-0 start">
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            Individually identifiable data regarding your date of registration, number of coaching
                            sessions completed, the date you have completed certain assessments, and similar engagement
                            level metrics. Wiser will never provide your sponsoring Organization details about the
                            content of your specific coaching session.
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            Aggregated and de-identified insights for topics and themes discussed by all Users
                            associated with your sponsoring Organization.
                        </span>
                    </li>
                </ul>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        If you are a Stakeholder we collect some, but not all, of the same data that we might for a
                        User. We collect this data to support the User and the Coach to get the most out of the
                        Services.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.d59lebbbmgbk">
                    <span className="c9">Data We Collect About Users from Others</span>
                    <span className="c7">&nbsp;</span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        If you are a User registering on the Platform through a license purchased by a User Organization
                        (which may or may not be your employer), the User Organization may provide us with certain
                        demographic data about you. In some cases, a User Organization may choose to integrate its human
                        resources information system (&ldquo;HRIS&rdquo;) with the Platform. If applicable, the User
                        Organization may share employment data such as, but not limited to, your hire date, your history
                        promotions or job changes, or anything else your sponsoring Organization may choose to provide
                        to Wiser.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        In some cases, we may send out Stakeholder surveys, per the instructions of the User
                        Organization, to relevant colleagues of the User.
                    </span>
                </p>
                <ul className="c6 lst-kix_eaq585wwemsy-0 start">
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            User Organization will provide us with the name and emails of Stakeholders
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            Stakeholders will be given the option of completing a survey about the User. Only the Coach
                            will have access to identifiable data and specific responses. User and User Organization
                            will have access to aggregate data.
                        </span>
                    </li>
                </ul>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.36ezdnplb8v">
                    <span className="c7">Aggregated Data from Use of Platform </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        Whether you are a Coach or User, we may aggregate and de-identify the data related to your use
                        of the Platform apart from what has been detailed above. We may analyze your usage data and
                        content when it is uploaded, sent, received, or stored. We may use your data, activity logs, and
                        direct feedback to improve our Platform and Services.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.vz0pvxe5m1mq">
                    <span className="c7">Cookies and Similar Technologies </span>
                </h2>
                <p className="c0">
                    <span>
                        First, while we use similar technologies within our Platform, they are separate and apart from
                        the cookies and similar technology used on our public website, wisercoaching.com, which are all
                        disclosed within our Cookie Policy. In order to provide specific functionality within our
                        Platform, there is limited use of tracking technology within our Platform, as also disclosed
                        within our{" "}
                    </span>
                    <span>Cookie Policy</span>
                    <span className="c1">.</span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.56c74ryo5yti">
                    <span className="c7">Your Device and Location </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        We do collect data from Platform Visitors such as data about your device and location via IP
                        address, the type of browser you use, amount of time you spend on the Platform, pages viewed,
                        and forms submitted. Wiser will not request precise geo-location data from our Users. If you
                        access the Platform via a mobile device, we may collect data such as the type of device that is
                        being used.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h1 className="c0 c3" id="h.n9pgnc3grh0e">
                    <span className="c8">3. How We Use Data </span>
                </h1>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.hk1o728rxeqd">
                    <span className="c7">Services </span>
                </h2>
                <p className="c0">
                    <span>Wiser </span>
                    <span className="c1">
                        will use your data to drive insights and analytics to serve you, our Users, and User
                        Organizations. The primary manner in which we use all data is to provide the Services and help
                        us improve the Site, the Platform, and the quality of our Services, and this extends to Personal
                        Data.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.goqiunfqepzs">
                    <span className="c7">Communicate With Users and Coaches </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        We require certain Personal Data from both User and Coaches for routine communication with each
                        and to facilitate the relationship between them.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.3cebchssy2p5">
                    <span className="c7">Market To You </span>
                </h2>
                <p className="c0">
                    <span>As further described in the </span>
                    <span>Cookie Policy </span>
                    <span>
                        and above, we collect certain data from Site Visitors for marketing and advertising purposes.
                        Site Visitors may also need to provide data to schedule a demo. We may also use this information
                        to communicate with You or potentially your User Organization about product, services, offers,
                        promotions, rewards, and events offered by Wiser or others; provide news and information we
                        think will be of interest to You or your User Organization; and deliver and measure
                        advertisements on other media and third party websites. You have control over what information
                        is collected and used, per our{" "}
                    </span>
                    <span>Cookie Policy</span>
                    <span className="c1">. </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.pk1gxtcn1qyu">
                    <span className="c7">Provide Customer Support </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        You may provide data via the Site or Platform that we use to provide a better customer support
                        experience to you. For instance, if you were to email care@wisermanagers.com our team would
                        request specific information for verification purposes and as necessary to address your
                        concerns.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.t36co4mzb5wb">
                    <span className="c7">Generate Insights That Do Not Identify You </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        Most data that we use internally is de-identified and aggregated and used to help us improve the
                        experience of You and other Users and User Organizations.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h1 className="c0 c3" id="h.4iaw2i9p6q1z">
                    <span className="c8">4. How We Share Data</span>
                </h1>
                <p className="c0">
                    <span className="c1">AS REFERENCED ABOVE, WISER DOES NOT SELL YOUR PERSONAL DATA. </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We share certain Personal Data about Users with Stakeholders and Coaches (and vice versa) such
                        as contact information and responses to surveys to facilitate the ability for Coaches to provide
                        services to Users.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        If you are a User who is accessing the Platform through a paid subscription through a User
                        Organization (e.g. your employer), we share certain usage data with your sponsoring User
                        Organization, such as data about your Platform usage (e.g. the number of sessions you attended,
                        when the session was attended, or date of completion of a survey or reflection). We share this
                        information with your sponsoring Organization to ensure a User Organization has the tools
                        necessary to ensure the Services are being utilized and that they are effective. We do NOT share
                        your assessment or survey responses or your individual coaching sessions with your User
                        Organization.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        Your sponsoring Organization may see de-identified and aggregated analysis from all
                        participating Users within your organization as aggregated from your interactions with resources
                        within the Wiser Platform and general session topics you select, or as identified by your Coach.
                        &nbsp;We do share some limited individual level data with the sponsoring Organization to ensure
                        expected use of the Platform.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        If you are a Visitor to our public site we may share data about your interactions with the Site
                        and the Services (such as whether you made a purchase) with our marketing and advertising
                        service partners to help us create and administer campaigns, target and deliver relevant
                        advertisements on third-party websites, and measure and optimize their effectiveness. To opt-out
                        of sharing your data for these purposes, please opt out via the provided links or manage within
                        your cookie preferences.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        If you are a Visitor, Coach, User or User Organization, we may also share data about you as
                        follows:
                    </span>
                </p>
                <ul className="c6 lst-kix_o6f6pi8xu7bt-0 start">
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            With vendors, consultants, and other service providers (including Coaches) who need access
                            to such data to carry out work on our behalf, on a need-to-know basis;
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            In response to a request for data if we believe disclosure is in accordance with any
                            legitimate law, regulation, or legal process, or as otherwise required by any applicable
                            law, rule, or regulation;
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            If we believe your actions are inconsistent with the spirit or language of our user
                            agreements or policies, to protect the rights, property, and safety of others;
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            In connection with, or during negotiations of, any merger, sale of our assets, financing, or
                            acquisition of all or a portion of our business to another company;
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            With your consent or at your direction, including if we notify you through the Site,
                            Services or Platform that certain data you provide will be shared in a particular manner and
                            you choose to provide this data
                        </span>
                    </li>
                </ul>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h1 className="c0 c3" id="h.915fdlujd8p5">
                    <span className="c8">5. Cookie Policy</span>
                </h1>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span>
                        We, or third parties we do business with, may use certain technologies to automatically collect
                        log files and other information about your usage of, and the devices you use to access, the
                        Services (&ldquo;Tracking Technologies&rdquo;). For example, we may use Tracking Technologies
                        like cookies, log files, web beacons,{" "}
                    </span>
                    <span>session replay scripts</span>
                    <span className="c1">
                        , or similar technologies to help us analyze our web page flow, customize our services, content
                        and advertising, measure promotional effectiveness and promote trust and safety.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span>You may</span>
                    <span>
                        &nbsp;delete and block all cookies from the Services, but parts of the Services will not work
                    </span>
                    <span className="c1">. </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We want to be open about our cookie use. Even if you are only browsing the Services, certain
                        information (including computer and connection information, browser type and version, operating
                        system and platform details, and the time of accessing the Services) is automatically collected
                        about you. This information will be collected every time you access the Services and it will be
                        used for the purposes outlined in this Privacy Policy.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        You can reduce the information cookies collected from your device. An easy way of doing this is
                        often to change the settings in your browser. If you do that you should know that (a) your use
                        of the Services may be adversely affected (and possibly entirely prevented), (b) your experience
                        of this and other sites that use cookies to enhance or personalize your experience may be
                        adversely affected, and (c) you may not be presented with advertising that reflects the way that
                        you use our and other sites. You find out how to make these changes to your browser at this
                        site: www.allaboutcookies.org/manage-cookies/. Unless you have adjusted your browser settings so
                        that it will refuse cookies, our system will send cookies as soon as you access the Services. By
                        using the Services you consent to this, unless you change your browser settings.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span>Web beacons </span>
                    <span>may be used to track the traffic patterns of users from one page to another in</span>
                    <span className="c1">
                        &nbsp;order to maximize web traffic flow, and to track when emails are opened in order to
                        optimize email campaigns. Third-party advertising service providers may also use web beacons to
                        recognize you when you access the Services and to help determine how you found the Services. If
                        you would like more information about this and to know your choices about not having this
                        information used by these companies, please visit: the Digital Advertising Alliance&rsquo;s
                        website, http://www.aboutads.info/, or the Network Advertising Initiative&rsquo;s website,
                        http://networkadvertising.org/consumer/opt_out.asp.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h1 className="c0 c3" id="h.swlz3k5is6w0">
                    <span>6</span>
                    <span className="c8">. Your Choices, Rights and Obligations </span>
                </h1>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.8o5fcl3o90nd">
                    <span className="c7">Data Retention </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        We store Personal Data for as long as is necessary for the purpose(s) for which we originally
                        collected it, or for other legitimate business purposes, including to meet our legal,
                        regulatory, or other compliance obligations.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.xyk7z4z8qwy0">
                    <span className="c7">Your Choices About Data Generally </span>
                </h2>
                <p className="c0">
                    <span>You may update, correct, or delete Personal Data at any time by contacting us at </span>
                    <span>privacy@wisermanagers.com</span>
                    <span className="c1">
                        . Your User Profile within the Platform also affords you the ability to update certain
                        preferences and personal information. Please note that we may retain certain data as required by
                        law or for legitimate business purposes. We may also retain cached or archived copies of data
                        about you for a certain period.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.wao9cdfm6je2">
                    <span className="c7">Your Choices About Email Subscriptions </span>
                </h2>
                <p className="c0">
                    <span>Visitors </span>
                    <span className="c1">
                        may opt out of receiving email communications from us by following the unsubscribe link within
                        those communications and Users by emailing us at care@wisermanagers.com.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.2x9o2rwb1ecd">
                    <span className="c7">Your Choices About Promotional Communication </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        If you are outside of the European Union (&ldquo;EU&rdquo;), you may opt out of receiving
                        promotional communications from us by following the instructions in those communications or by
                        emailing us at care@wisermanagers.com. If you opt out, we may still send you non-promotional
                        communications specifically related to your use of the Platform.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h2 className="c0 c3" id="h.vvs67gjwvxqb">
                    <span>Your Jurisdiction-specific Rights</span>
                </h2>
                <h3 className="c0 c3" id="h.et6biwdl1o29">
                    <span className="c10">A. Your Rights under State level privacy laws</span>
                </h3>
                <p className="c0">
                    <span className="c1">
                        At this time, the United States is seeing a handful of states passing comprehensive privacy
                        legislation. We understand each state is doing their best to protect the individual privacy
                        rights for their own citizens, which is why Wiser has taken the approach of honoring all privacy
                        rights such as the generally accepted principles of right of access, deletion, and correction
                        for all our Users, and will honor the rights described within this Privacy Policy as well as all
                        rights afforded you under your local state laws.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h3 className="c0 c3" id="h.wquu3bbo9eq0">
                    <span className="c10">
                        B. Your Rights under the General Data Privacy Regulation (&ldquo;GDPR&rdquo;){" "}
                    </span>
                </h3>
                <p className="c0">
                    <span className="c1">
                        If you are a resident of the European Union (EU) and European Economic Area (EEA), GDPR affords
                        you certain rights. These include:
                    </span>
                </p>
                <ul className="c6 lst-kix_nefj35nlska3-0 start">
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The right to access, delete, or update the Personal Data we have on you{" "}
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The right to have your Personal Data rectified if it is incomplete or incorrect{" "}
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">The right to object to our processing of your Personal Data </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The right to request that we restrict the processing of your Personal Data{" "}
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The right to request a copy of your Personal Data in a machine-readable and commonly used
                            format
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The right to withdraw your consent at any time where we rely on your consent to process your
                            Personal Data
                        </span>
                    </li>
                </ul>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        To exercise any of the above rights, please send a request to care@wisermanagers.com. Please
                        note that we may ask you to verify your identity before responding to such requests.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        Your Personal Data may be processed outside your jurisdiction, and in countries that are not
                        subject to an adequacy decision by the European Commission or your local legislature and/or
                        regulator, and that may not provide for the same level of data protection as your jurisdiction,
                        such as the European Economic Area.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        We are based in the United States and the information we collect is governed by U.S. law. By
                        accessing or using the Services or otherwise providing information to us, you consent to the
                        processing and transfer of information in and to the United States and other countries, where
                        you might not have the same rights and protections as you do under local law.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        Wiser may be obligated to disclose personal information in response to lawful requests by public
                        authorities, including to meet national security or law enforcement requirements. You have the
                        right to complain to a Data Protection Authority (as defined by the GDPR) about our collection
                        and use of your Personal Data. For contact details of your local Data Protection Authority,
                        please see here.
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h3 className="c0 c3" id="h.v15mc6skb0jk">
                    <span className="c10">
                        C. Your Rights under the California Consumer Privacy Act (&ldquo;CCPA&rdquo;){" "}
                    </span>
                </h3>
                <p className="c0">
                    <span className="c1">
                        If you are a California resident, you are entitled to learn what data we collect about you, ask
                        to delete your data and ask us not to sell it. You may request what personal information we have
                        about you. If you make this request, we will return to you:
                    </span>
                </p>
                <ul className="c6 lst-kix_zdsy5s8btqjs-0 start">
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The categories of personal information (as defined by the CCPA) we have collected about you.
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The categories of sources from which we collect your personal information.{" "}
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The business or commercial purpose for collecting or selling your personal information.
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The categories of third parties with whom we share personal information.{" "}
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            The specific pieces of personal information we have collected about you.{" "}
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            A list of categories of personal information that we have sold, along with the category of
                            any other company we sold it to. If we have not sold your personal information, we will
                            inform you of that fact.
                        </span>
                    </li>
                    <li className="c0 c5 li-bullet-0">
                        <span className="c1">
                            A list of categories of personal information that we have disclosed for a business purpose,
                            along with the category of any other company we shared it with.
                        </span>
                    </li>
                </ul>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <p className="c0">
                    <span className="c1">
                        If you request we delete your data pursuant to the CCPA, we will delete the personal information
                        we hold about you as of the date of your request from our records and direct any service
                        providers to do the same. In some cases, deletion may be accomplished through de- identification
                        of the information. If you choose to delete your personal information, you may not be able to
                        use certain functions that require your personal information to operate. We will not
                        discriminate against you under any circumstances for exercising your rights in connection with
                        the CCPA. To exercise your California data protection rights described above, please send a
                        request to care@wisermanagers.com
                    </span>
                </p>
                <p className="c0 c2">
                    <span className="c1" />
                </p>
                <h1 className="c0 c3" id="h.tyf4v947kspp">
                    <span>7</span>
                    <span className="c8">. Other Important Information</span>
                </h1>
                <h2 className="c0 c3" id="h.7guj9g21vk28">
                    <span className="c7">Is Wiser a Data Controller or a Data Processor? </span>
                </h2>
                <p className="c0">
                    <span className="c1">
                        Certain data protection laws distinguish between data processors and data controllers, depending
                        on how the data is collected and shared. Wiser is a Processor with respect to data provided by a
                        User Organization when registering on behalf of Users it is contracting for.
                    </span>
                </p>
            </div>
        </SimpleLayout>
    )
}
