import React from "react"

import classNames from "classnames"

import "./styles.scss"

type Props = {
    className?: string
    withIcon?: boolean
    children: React.ReactNode
}
const AttentionNotificationBox = ({ children, withIcon = true, className = "" }: Props) => {
    return (
        <div className={classNames("attention-notification-box", className)}>
            {withIcon && (
                <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2">
                    <path
                        d="M8.44941 10.824L7.88541 5.04H10.1174L9.54141 10.824H8.44941ZM8.02941 13.5V11.58H9.97341V13.5H8.02941Z"
                        fill="#FD4D00"
                    />
                    <rect x="1" y="1.5" width="16" height="16" rx="8" stroke="#FD4D00" strokeWidth="2" />
                </svg>
            )}
            <div className="attention-notification-box-content">{children}</div>
        </div>
    )
}
export default AttentionNotificationBox
