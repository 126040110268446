import { useEffect, useRef } from "react"

import { USER_ACTIVITY_DEBOUNCE_TIME, USER_ACTIVITY_EXPIRE_TIME } from "main-app/constants"

type Options = {
    onInactivity: () => void
    onActivity: () => void
    inactivityTime?: number
    enabled?: boolean
    debounceTime?: number
}

const useUserActivity = ({
    onInactivity,
    onActivity,
    enabled = true,
    inactivityTime = USER_ACTIVITY_EXPIRE_TIME,
    debounceTime = USER_ACTIVITY_DEBOUNCE_TIME
}: Options) => {
    const inactivityTimerRef = useRef(null)
    const activityDebounceTimerRef = useRef(null)

    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimerRef.current)
        inactivityTimerRef.current = setTimeout(onInactivity, inactivityTime)
    }

    const handleActivity = () => {
        clearTimeout(activityDebounceTimerRef.current)
        activityDebounceTimerRef.current = setTimeout(onActivity, debounceTime)
    }

    useEffect(() => {
        const handleUserActivity = () => {
            resetInactivityTimer()
            handleActivity()
        }

        const events = ["click", "mousemove", "keypress", "scroll", "touchstart"]

        if (!Number.isNaN(inactivityTime) && inactivityTime > 0 && enabled) {
            events.forEach(e => window.addEventListener(e, handleUserActivity))
            resetInactivityTimer()
        }

        return () => {
            events.forEach(e => window.removeEventListener(e, handleUserActivity))
            clearTimeout(inactivityTimerRef.current)
            clearTimeout(activityDebounceTimerRef.current)
        }
    }, [onInactivity, onActivity, inactivityTime, debounceTime, enabled])

    return null
}

export default useUserActivity
