import React from "react"

import classNames from "classnames"

import HtmlContent from "main-app/shared/html-content/HtmlContent"

import "../styles.scss"

import { SimulabStageIndicator } from "./SimulabStageIndicator"

type Props = {
    name: string
    phase: string
    completed: boolean
    isCurrentStage: boolean
    isFirstStage: boolean
    showLine?: boolean
    disabled?: boolean
    className?: string
}

export const StageName = ({
    name,
    isCurrentStage,
    completed,
    isFirstStage,
    phase,
    showLine = true,
    disabled = false,
    className = ""
}: Props) => {
    return (
        <div
            className={classNames("stage-name", className, { current: isCurrentStage, first: isFirstStage, disabled })}>
            <SimulabStageIndicator phase={phase} completed={completed} />
            <div className="flex-1">
                <p className="font-extrabold color-black mb-0">{phase}</p>
                <div className="d-flex align-items-center">
                    <ul className="p-0 m-0 color-black">
                        <li>
                            <HtmlContent renderTag="span" content={name} attributes={{ allowedTags: [] }} />
                        </li>
                    </ul>
                    {showLine && <div className="stage-line" />}
                </div>
            </div>
            {/* <HtmlContent renderTag="span" content={name} attributes={{ allowedTags: [] }} /> */}
        </div>
    )
}
