import { v4 as uuid } from "uuid"

import { TranslationObj } from "common/utils/lang"

import { ReflectionComponentType } from "main-app/constants"

import { AnswerOptionApi, PairingQuestionAnswerOptionItemApi, PairingQuestionApi } from "../types"

export class PairingQuestionAnswerOption {
    optionText: TranslationObj

    color?: string

    uuid?: string

    constructor(data: PairingQuestionAnswerOptionItemApi) {
        this.optionText = data.option_text || {}
        this.color = data.color || ""
        this.uuid = uuid()
    }
}

export class AnswerOption {
    component_type: ReflectionComponentType.LIKERT_GROUP | ReflectionComponentType.COLORED_LIKERT_GROUP

    id: number

    internalName: string

    options: PairingQuestionAnswerOption[]

    optionsNumber: number

    constructor(data: AnswerOptionApi) {
        this.component_type = data.component_type
        this.id = data.id || null
        this.internalName = data.internal_name || ""
        this.options = Object.values(data.options_data).map(item => new PairingQuestionAnswerOption(item)) || []
        this.optionsNumber = data.options_number || null
    }
}

export class PairingQuestion {
    id: number

    internalName: string

    questionData: TranslationObj

    uuid: string

    answerOptions: AnswerOption

    constructor(data: PairingQuestionApi) {
        this.id = data.id || null
        this.internalName = data.internal_name || ""
        this.questionData = data.question_data || null
        this.uuid = data.uuid || ""
        this.answerOptions = new AnswerOption(data.answer_options)
    }
}
