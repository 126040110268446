import React from "react"

import { useWatch } from "react-hook-form"

interface IProps {
    register: any
    onFocus: () => void
    placeholder?: string
    name: string
    onBlur: () => void
    className?: string
    control: any
    hasFocus?: boolean
}

const TrackPracticeTextAreaDesktop: React.FC<IProps> = ({
    register,
    onBlur,
    onFocus,
    name,
    placeholder,
    control,
    hasFocus = false,
    className = ""
}) => {
    const commentInput = useWatch({ control, name })

    return (
        <textarea
            {...register(name)}
            name={name}
            className={className}
            onFocus={onFocus}
            rows={commentInput?.length > 220 ? 7 : 4}
            onBlur={onBlur}
            autoFocus={hasFocus}
            placeholder={placeholder || ""}
        />
    )
}

export default TrackPracticeTextAreaDesktop
