import React, { forwardRef } from "react"

import { useTranslation } from "react-i18next"

import { SimulabTipBadge, useSimulabContext } from "main-app/entities/simulab"

type Props = {}

export const SimulabEndConversation = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { t } = useTranslation()
    const { conversation } = useSimulabContext()

    return (
        <div className="simulab-end-feedback" ref={ref}>
            <div className="simulab-end-feedback__wrapper">
                <p className="text-center">{conversation?.feedback?.impact}</p>
                <div className="simulab-end-feedback__strength fs-14">
                    <p className="font-extrabold m-0">{t("Your Strengths")}</p>
                    <p className="m-0">{conversation?.feedback?.strengths} </p>
                </div>
                <div className="simulab-end-feedback__growth-areas">
                    {conversation?.feedback?.growthAreas?.map((area, index) => (
                        <div className="simulab-end-feedback__growth-item" key={area?.title + index}>
                            <SimulabTipBadge />
                            <div className="simulab-end-feedback__growth-item__body fs-14">
                                <p className="font-extrabold m-0">{area?.title}</p>
                                <p className="m-0">{area?.description} </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})
