import React, { SVGProps, memo } from "react"

interface Props extends SVGProps<SVGSVGElement> {
    height?: number
    width?: number
    className?: string
}

const FeedbackSubmitSvg = memo(({ width = 36, height = 27, className = "", ...props }: Props) => (
    <svg
        width={width}
        height={height}
        className={className}
        viewBox="0 0 36 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M7.62671 3.78481V2.89873C7.62671 1.85009 8.4768 1 9.52544 1H33.1014C34.15 1 35.0001 1.85009 35.0001 2.89873V19.038C35.0001 20.0866 34.15 20.9367 33.1014 20.9367H30.4409C30.3252 20.9367 30.2188 20.9998 30.1633 21.1013L27.762 25.4923C27.6419 25.7118 27.3267 25.7118 27.2067 25.4923L24.8053 21.1013C24.7498 20.9998 24.6434 20.9367 24.5277 20.9367H9.52544C8.4768 20.9367 7.62671 20.0866 7.62671 19.038V19.0154"
            stroke="#B0B0B0"
            strokeWidth={1.39241}
            strokeLinecap="round"
        />
        <g>
            <path d="M15.6963 7.3291L28.671 7.3291" stroke="#B0B0B0" strokeWidth={1.39241} strokeLinecap="round" />
            <path d="M17.595 10.1772H29.3038" stroke="#B0B0B0" strokeWidth={1.39241} strokeLinecap="round" />
            <path d="M16.3292 13.0254H23.9242" stroke="#B0B0B0" strokeWidth={1.39241} strokeLinecap="round" />
        </g>
        <g>
            <rect x={2.27856} y={6.56958} width={9.74684} height={9.74684} rx={4.87342} fill="#B0B0B0" />
            <rect
                x={2.27856}
                y={6.56958}
                width={9.74684}
                height={9.74684}
                rx={4.87342}
                stroke="#B0B0B0"
                strokeWidth={1.39241}
            />
            <path
                d="M5.0199 11.504L6.41593 12.9661L9.58873 9.92017"
                stroke="white"
                strokeWidth={1.39241}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
))

export { FeedbackSubmitSvg }
