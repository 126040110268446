import React from "react"

import { DateTime } from "luxon"

import { withTranslation } from "common/utils/lang"

import "./styles.scss"

interface IProps {
    columns: any[]
    rows: any[]
}

const TrackPracticeColumnsMobile: React.FC<IProps> = ({ columns, rows }) => {
    const date = DateTime.now().toFormat("LLL. dd, yyyy")

    return (
        <div className="mt-3">
            {rows?.map((row, rowIndex) => (
                <div className="track-practice-mobile-column" key={row.id}>
                    <div className="track-column-date br-top-10 d-flex justify-content-between align-items-center">
                        <p className="font-sm">{date}</p>
                    </div>

                    <div className="track-column-comment br-bottom-10">
                        {columns?.map((col, colIndex) => (
                            <div key={col.uuid}>
                                <h4 className="h4 font-extrabold text-left font-sm">{withTranslation(col.title)}</h4>
                                <p className="font-sm">{rows?.[rowIndex]?.columns[colIndex]?.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TrackPracticeColumnsMobile
