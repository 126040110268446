import React, {
    type Dispatch,
    type FC,
    type LazyExoticComponent,
    type ReactElement,
    type SetStateAction,
    Suspense,
    lazy,
    startTransition,
    useCallback,
    useState
} from "react"

import {
    CoachRelinkAccountsConfirmationBox as ConfirmationBox,
    CoachRelinkAccountsContentHeader as ContentHeader,
    ECoachRelinkAccountsAccountsListType as EAccountListType,
    CoachRelinkAccountsHelpLink as HelpLink,
    CoachRelinkAccountFallbackSpinner as Spinner,
    type TCoachRelinkAccountsAccountListProps as TAccountListProps,
    type TCoachRelinkAccountsConfirmDeactivationModalProps as TConfirmDeactivationModalProps
} from "."
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { isEmptyArray, isNullOrUndefined } from "common/utils/gates"

import { CoachUrls } from "main-app/constants"
import { type AuthContextActionTypes, useAuthContext } from "main-app/context/Auth"
import type { TEmptyCallback } from "main-app/shared/types/functions"

import {
    type TUseCoachAccountsRelinkingConfirmMutation,
    type TUseCoachAccountsRelinkingDisconnectMutation,
    type TUseCoachAccountsToRelinkQuery,
    coachRelinkAccountsApiHooks
} from "../api"
import type {
    THandleCoachAccountsConfirmRelinkMutationSuccessFunction,
    THandleCoachAccountsConfirmRelinkMutationSuccessFunctionParams,
    THandleDeactivateButtonClickFunction,
    THandleRefreshPageButtonClickFunction,
    THandleSubmitModalButtonClickFunction
} from "../types"
import { coachRelinkAccountsUtils } from "../utils"

const AccountList: LazyExoticComponent<FC<TAccountListProps>> = lazy(
    (): Promise<{ default: FC<TAccountListProps> }> => import("./CoachRelinkAccountsAccountList")
)
const ConfirmDeactivationModal: LazyExoticComponent<FC<TConfirmDeactivationModalProps>> = lazy(
    (): Promise<{ default: FC<TConfirmDeactivationModalProps> }> =>
        import("./CoachRelinkAccountsConfirmDeactivationModal")
)

const CoachRelinkAccountsContainer: FC = (): ReactElement => {
    const navigate: NavigateFunction = useNavigate()

    const { refetchUser: refetchCurrentAuthenticatedUser }: AuthContextActionTypes = useAuthContext()

    const {
        data: coachAccountToRelinkData,
        isFetching: isCoachAccountsDataToRelinkFetching,
        refetch: handleCoachAccountToRelinkDataRefetch
    }: TUseCoachAccountsToRelinkQuery = coachRelinkAccountsApiHooks.useCoachAccountsToRelinkQuery({
        enabled: true
    })

    const {
        mutateAsync: handleCoachAccountsDisconnectMutation,
        isLoading: isCoachAccountsDisconnectMutationLoading,
        error: coachAccountsDisconnectMutationError
    }: TUseCoachAccountsRelinkingDisconnectMutation = coachRelinkAccountsApiHooks.useCoachAccountsRelinkingDisconnectMutation()

    const {
        mutateAsync: handleCoachAccountsConfirmRelinkMutation,
        isLoading: isCoachAccountsConfirmRelinkMutationLoading
    }: TUseCoachAccountsRelinkingConfirmMutation =
        coachRelinkAccountsApiHooks.useCoachAccountsRelinkingConfirmMutation()

    const [profileIdToDeactivate, setProfileIdToDeactivate]: [string, Dispatch<SetStateAction<string>>] =
        useState<string>(String())

    const [isConfirmDeactivationModalShown, setIsConfirmDeactivationModalShown]: [
        boolean,
        Dispatch<SetStateAction<boolean>>
    ] = useState<boolean>(false)
    const handleConfirmDeactivationModalClose: TEmptyCallback = useCallback(
        (): void => startTransition((): void => setIsConfirmDeactivationModalShown(false)),
        []
    )
    const handleConfirmDeactivationModalOpen: TEmptyCallback = useCallback(
        (): void => startTransition((): void => setIsConfirmDeactivationModalShown(true)),
        []
    )

    const handleConfirmDeactivationModalSubmit: THandleSubmitModalButtonClickFunction = useCallback(
        async () =>
            !isNullOrUndefined(profileIdToDeactivate)
                ? (await handleCoachAccountsDisconnectMutation(
                      { profileId: profileIdToDeactivate },
                      {
                          onSuccess: async () => (
                              handleConfirmDeactivationModalClose(),
                              await handleCoachAccountToRelinkDataRefetch(),
                              void 0
                          )
                      }
                  ),
                  void 0)
                : void 0,
        [
            profileIdToDeactivate,
            handleCoachAccountsDisconnectMutation,
            handleConfirmDeactivationModalClose,
            handleCoachAccountToRelinkDataRefetch
        ]
    )

    const handleDisconnectButtonClick: THandleDeactivateButtonClickFunction = useCallback(
        (profileId: string): void => (setProfileIdToDeactivate(profileId), handleConfirmDeactivationModalOpen()),
        [handleConfirmDeactivationModalOpen]
    )

    const handleCoachAccountsConfirmRelinkMutationSuccess: THandleCoachAccountsConfirmRelinkMutationSuccessFunction =
        useCallback(
            async (data: THandleCoachAccountsConfirmRelinkMutationSuccessFunctionParams): Promise<void> => (
                await handleCoachAccountToRelinkDataRefetch(),
                startTransition(() =>
                    coachRelinkAccountsUtils.doesCoachAccountsConfirmRelinkMutationResponseDataAllowToMakeRedirect(data)
                        ? (refetchCurrentAuthenticatedUser(), navigate(CoachUrls.SESSIONS_LIST))
                        : void 0
                )
            ),
            [handleCoachAccountToRelinkDataRefetch, refetchCurrentAuthenticatedUser, navigate]
        )

    const handleRefreshPageButtonClick: THandleRefreshPageButtonClickFunction = useCallback(
        async () =>
            handleCoachAccountsConfirmRelinkMutation(coachAccountToRelinkData, {
                onSuccess: handleCoachAccountsConfirmRelinkMutationSuccess
            }),
        [
            handleCoachAccountsConfirmRelinkMutation,
            coachAccountToRelinkData,
            handleCoachAccountsConfirmRelinkMutationSuccess
        ]
    )

    return (
        <>
            <section className="coach-relink-accounts__container">
                <ContentHeader />
                <Suspense fallback={<Spinner />}>
                    <AccountList
                        isAccountsDataLoading={
                            isCoachAccountsDataToRelinkFetching || isEmptyArray(coachAccountToRelinkData?.calendars)
                        }
                        accountsData={coachAccountToRelinkData?.calendars}
                        listType={EAccountListType.Google}
                        handleDisconnectButtonClick={handleDisconnectButtonClick}
                    />
                    <AccountList
                        isAccountsDataLoading={
                            isCoachAccountsDataToRelinkFetching || isNullOrUndefined(coachAccountToRelinkData?.zoom)
                        }
                        accountsData={[coachAccountToRelinkData?.zoom]}
                        listType={EAccountListType.Zoom}
                        handleDisconnectButtonClick={handleDisconnectButtonClick}
                    />
                </Suspense>
                <ConfirmationBox
                    handleRefreshPage={handleRefreshPageButtonClick}
                    shouldRefreshButtonBeLoading={isCoachAccountsConfirmRelinkMutationLoading}
                />
                <HelpLink />
            </section>

            {isConfirmDeactivationModalShown && (
                <ConfirmDeactivationModal
                    show={isConfirmDeactivationModalShown}
                    onClose={handleConfirmDeactivationModalClose}
                    onConfirmClick={handleConfirmDeactivationModalSubmit}
                    mutationError={coachAccountsDisconnectMutationError}
                    shouldSubmitButtonBeLoading={isCoachAccountsDisconnectMutationLoading}
                />
            )}
        </>
    )
}

export { CoachRelinkAccountsContainer }
