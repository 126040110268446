import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { ModulesCategory } from "main-app/models/modules-category"

import { ServerStateOnbordingKeys } from "../constants"

const getModulesCategory = async () => {
    const { data } = await http.get(Urls.onboardingStep2())
    return data
}

export default function useModulesByCategory() {
    return useQuery<ModulesCategory[]>([ServerStateOnbordingKeys.ModulesByCategory], getModulesCategory, {
        select: useCallback(data => {
            return data.map(item => new ModulesCategory(item))
        }, [])
    })
}
