import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import { ServerStateKeys, SortKeysSession } from "main-app/constants"
import { Session } from "main-app/models/session"

import Urls from "./urls"

interface Query {
    search: string
    order: SortKeysSession
    cohort: string
    enabled: boolean
    period: string
    page: number
}

interface CoachSessionsData {
    sessions: Session[]
    cohorts: Array<{ name: string; id: number }>
    totalSessionsCount: number
}

const getCoachSessions = async (query: Query) => {
    const { data } = await http.get(
        Urls.coachSessions(query.search, query.order, query.cohort, query.period, query.page)
    )
    return data
}

export default function useGetCoachSessions(query: Query) {
    return useQuery<any, AxiosError, CoachSessionsData>(
        [ServerStateKeys.CoachSessions, query],
        () => getCoachSessions(query),
        {
            select: useCallback(
                data => ({
                    sessions: data?.results?.map(session => new Session(session)) ?? [],
                    cohorts: data?.filters?.cohorts || [],
                    totalSessionsCount: data?.count ?? 0
                }),
                []
            ),
            enabled: query.enabled
        }
    )
}
