import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { PREVIOUS_PAGE } from "common/constants"

import { Chevron } from "main-app/svgs"

import "./styles.scss"

interface IProps {
    className?: string
}

const BaсkButton: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClickBack = () => {
        navigate(PREVIOUS_PAGE)
    }

    return (
        <button className={classNames("btn back-button", { [className]: className })} onClick={handleClickBack}>
            <Chevron /> <span>{t("Back")}</span>
        </button>
    )
}

export default BaсkButton
