import React, { SVGProps, memo } from "react"

interface Props extends SVGProps<SVGSVGElement> {}

const ThreeWayIconSvg = ({ width = 15, height = 15 }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="15" height="15" rx="7.5" fill="#0404CB" />
            <path
                d="M7.49964 7.31811C8.65905 7.31811 9.60889 6.36823 9.60889 5.20886C9.60889 4.04949 8.65905 3.09961 7.49964 3.09961C6.34022 3.09961 5.39039 4.04949 5.39039 5.20886C5.39039 6.36827 6.34022 7.31811 7.49964 7.31811ZM7.49964 3.79804C8.28186 3.79804 8.91046 4.42662 8.91046 5.20886C8.91046 5.9911 8.28187 6.61968 7.49964 6.61968C6.7174 6.61968 6.08881 5.9911 6.08881 5.20886C6.08881 4.42662 6.7174 3.79804 7.49964 3.79804Z"
                fill="white"
            />
            <path
                d="M7.49992 11.8993C9.1063 11.8993 10.7686 11.8993 10.7686 10.0973C10.7686 8.21155 9.27392 6.61914 7.49992 6.61914C5.72591 6.61914 4.23128 8.21155 4.23128 10.0973C4.23128 11.8993 5.89353 11.8993 7.49992 11.8993ZM7.49992 7.31757C8.86883 7.31757 10.0701 8.61664 10.0701 10.0973C10.0701 10.9913 9.59519 11.2008 7.49992 11.2008C5.40465 11.2008 4.9297 10.9913 4.9297 10.0973C4.9297 8.61664 6.131 7.31757 7.49992 7.31757Z"
                fill="white"
            />
        </svg>
    )
}

export default memo(ThreeWayIconSvg)
