import React, { useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { AxiosError } from "axios"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Heading from "common/components/Heading/Heading"
import { HTTPStatus } from "common/constants"
import { Option } from "common/models/option"
import { getErrorMessages } from "common/utils/get-error-messages"
import { getUserLanguage } from "common/utils/lang"

import { useAuthContext } from "main-app/context/Auth"
import Button from "main-app/shared/button/Button"
import TextInput from "main-app/shared/input/TextInput"
import ProfilePhotoHandler from "main-app/shared/profile-photo-handler/ProfilePhotoHandler"
import Select from "main-app/shared/select-default/Select"
import { getLanguageOptions } from "main-app/utils/common"
import useNavigationOnboarding from "main-app/utils/hooks/use-navigation-onboarding"

import useNextStep from "./api/mutations/use-next-step"
import { WarningMessage } from "./components/WarningMessage"
import ProfileSetupModal from "./components/modal/ProfileSetupModal"
import { OnboardingSteps } from "./constants"
import OnboardingLayout from "./layout/OnboardingLayout"
import { useOnboardingStoreContext } from "./onboardingStore"
import { profileSetupValidation } from "./validation/profile-setup-validation"

interface IProps {}

const OnBoardingProfileSetup: React.FC<IProps> = () => {
    const { t } = useTranslation()
    const { storeData } = useOnboardingStoreContext()
    const { getNextStepUrl } = useNavigationOnboarding()
    const handleNextStep = useNextStep()
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [isBusy, setIsBusy] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const dataModal: string = storeData?.profileSetupDataModal?.onboarding_manager_info || ""
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        resolver: yupResolver(profileSetupValidation)
    })

    const { user } = useAuthContext()

    const getBaseLanguage = () => {
        return languageOptions?.length === 1
            ? languageOptions[0].value
            : languageOptions?.find(lang => lang.value === getUserLanguage())?.value
    }

    const onSubmitProfileSetup = values => {
        const step_data = {
            ...values,
            manager_first_name: values.manager_first_name || null,
            manager_last_name: values.manager_last_name || null,
            manager_email: values.manager_email || null,
            photo: values.photo || null,
            language_code: values.language_code || getBaseLanguage() || JSAPP_CONF?.lang
        }

        setIsBusy(true)
        handleNextStep.mutate(
            { step: OnboardingSteps.PERSONAL_INFO_STEP, step_data },
            {
                async onSuccess() {
                    const nextStep = await getNextStepUrl()
                    window.location.replace(nextStep.url)
                },
                onError(error: AxiosError) {
                    const errorMessage = getErrorMessages(error)
                    if (error?.response?.status === HTTPStatus.BAD_REQUEST) {
                        setErrorsMsg(errorMessage)
                    }
                },
                onSettled() {
                    setIsBusy(false)
                }
            }
        )
    }

    const languageOptions: Option[] = useMemo((): Option[] => {
        if (storeData?.currenUser) {
            return getLanguageOptions(storeData?.currenUser?.cohort_available_languages)
        }
        if (user) {
            return getLanguageOptions(user?.cohortAvailableLanguages)
        }
        return []
    }, [storeData?.currenUser])

    const defaultLanguage = useMemo(() => {
        const index = languageOptions?.findIndex(lang => lang.value === getUserLanguage())
        if (languageOptions?.length === 1) {
            return 0
        }
        return index < 0 ? languageOptions?.findIndex(lang => lang.value === "en") : index
    }, [languageOptions])

    useEffect(() => {
        if (storeData.currenUser) {
            /* TODO refactor next lines using model of data */
            const cleanedData = { ...storeData.currenUser }
            delete cleanedData.enrolled_modules
            delete cleanedData.onboarding_finished
            delete cleanedData.journey_length
            Object.entries(cleanedData).forEach(([name, value]: any) => setValue(name, value))
            const defaultLanguageCode = getBaseLanguage() || JSAPP_CONF?.lang
            setValue("language_code", defaultLanguageCode)
        }
    }, [storeData.currenUser])

    const handleProfilePicture = (profilePhoto: string | ArrayBuffer) => {
        setValue("photo", profilePhoto)
    }

    const onLanguageSelect = (language: Option) => {
        setValue("language_code", language.value, { shouldDirty: true })
    }

    return (
        <OnboardingLayout pageTitle={t("Profile Setup")}>
            <div className="profile-form">
                <div className="container-fluid">
                    <form onSubmit={handleSubmit(onSubmitProfileSetup)}>
                        <div className="mb-4">
                            <h2 className="form-title mb-0">{t("Your Information")}</h2>
                        </div>
                        <WarningMessage message={errorsMsg} />
                        <div className="row">
                            <div className="col-12">
                                <TextInput
                                    name="first_name"
                                    type="text"
                                    placeholder={t("First Name*")}
                                    register={register}
                                    errors={errors}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <TextInput
                                    name="last_name"
                                    type="text"
                                    placeholder={t("Last Name*")}
                                    register={register}
                                    errors={errors}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {languageOptions.length > 1 && (
                            <div className="row flex-column mb-4">
                                <div className="col-12 col-md-3 pr-0 mb-3 d-flex align-items-center">
                                    <Heading tag="h3" fontSize={16} className="m-0">
                                        {t("Preferred Language")}:
                                    </Heading>
                                </div>
                                <div className="col-sm-12 col-md-5">
                                    <Select
                                        backgroundWhite
                                        defaultOptionIndex={defaultLanguage}
                                        optionsList={languageOptions}
                                        showDisabledIcon={false}
                                        disabled={languageOptions?.length === 1}
                                        onSelect={onLanguageSelect}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <ProfilePhotoHandler onChange={handleProfilePicture} />
                            </div>
                        </div>
                        <div className="text-center mt-6">
                            <Button type="submit" isBusy={isBusy}>
                                {t("Create Profile")}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <ProfileSetupModal show={openModal} onClose={() => setOpenModal(false)} data={dataModal} />
        </OnboardingLayout>
    )
}

export default OnBoardingProfileSetup
