import React from "react"

import classNames from "classnames"

import Spinner from "./Spinner"

interface IProps {
    className?: string
}

const FullSpinner: React.FC<IProps> = ({ className }) => {
    return (
        <div
            className={classNames("w-100 h-100 d-flex justify-content-center", { [className]: className })}
            data-testid="full-spinner">
            <Spinner />
        </div>
    )
}

export default FullSpinner
