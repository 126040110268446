import React, { useCallback, useState } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import Heading from "common/components/Heading/Heading"
import useBoolean from "common/hooks/use-boolean"
import { withTranslation } from "common/utils/lang"

import useOnboardingSteps from "main-app/api/use-onboarding-steps"
import CategoryModal from "main-app/elements/category-modal/CategoryModal"
import DiagnosticResponsesModal from "main-app/elements/diagnostic-responses-modal/DiagnosticResponsesModal"
import DiagnosticSummaryQuestions from "main-app/elements/diagnostic-summary-questions/DiagnosticSummaryQuestions"
import ParticipantWrapper from "main-app/elements/participant-wrapper"
import ParticipantLayout from "main-app/layouts/ParticipantLayout"

import LeftSidebar from "../dashboard/LeftSidebar"
import useDiagnosticResults from "../onboarding/api/use-diagnostic-results"
import { OnboardingSteps } from "../onboarding/constants"

interface IProps {}

const Diagnostic: React.FC<IProps> = () => {
    const { t } = useTranslation()
    const { data: steps, isLoading: isLoadingSteps } = useOnboardingSteps()
    const navigate = useNavigate()
    const { value: showDiagnosticResponseModal, setValue: setShowDiagnosticResponseModal } = useBoolean(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const { data: diagnosticSummary, isLoading } = useDiagnosticResults()
    const { value: showCategoryModal, setValue: setShowCategoryModal } = useBoolean()

    const hasDiagnosticeStep =
        steps?.previousSteps?.includes(OnboardingSteps.DIAGNOSTIC_STEP) ||
        steps?.previousSteps?.includes(OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP)

    if (!hasDiagnosticeStep && !isLoadingSteps) {
        navigate("/")
    }

    const onCloseDiagnostic = useCallback(() => {
        setShowDiagnosticResponseModal(false)
    }, [])

    const onOpenDiagnostic = useCallback(() => {
        setShowDiagnosticResponseModal(true)
    }, [])

    const onClickCategoryModalTooltip = useCallback(category => {
        setSelectedCategory(category)
        setShowCategoryModal(true)
    }, [])

    const onCloseCategoryModal = useCallback(() => {
        setShowCategoryModal(false)
        setSelectedCategory(null)
    }, [])

    return (
        <ParticipantLayout
            leftSidebar={<LeftSidebar />}
            title={<Heading className="m-0">{t("Diagnostics & Outcomes")}</Heading>}>
            <ParticipantWrapper size="max-md">
                <p className="color-gray mb-30 text-center">
                    {t(
                        "These results serve only as a starting point. You will work with your coach to decide which modules you’d like to explore."
                    )}
                </p>
                <DiagnosticSummaryQuestions
                    diagnosticSummary={diagnosticSummary}
                    isLoading={isLoading}
                    showQuestions
                    showFullDiagnostic
                    onTooltipClick={onClickCategoryModalTooltip}
                    onFullDiagnosticeClick={onOpenDiagnostic}
                />
            </ParticipantWrapper>
            <DiagnosticResponsesModal
                show={showDiagnosticResponseModal}
                onClose={onCloseDiagnostic}
                participantName={t("My")}
                preProgramDiagnostics={diagnosticSummary?.pre_program_diagnostic}
            />
            <CategoryModal
                show={showCategoryModal}
                onClose={onCloseCategoryModal}
                categoryDescription={withTranslation(selectedCategory?.description)}
                categoryName={withTranslation(selectedCategory?.name)}
            />
        </ParticipantLayout>
    )
}

export default Diagnostic
