import { useMutation } from "@tanstack/react-query"

import { http } from "common/http"

import { IChapterApi } from "main-app/models/chapter"

import Urls from "../urls"

export default function useChapterComplete(id: number) {
    return useMutation((chapter: IChapterApi) => http.put(Urls.chapterComplete(id), chapter))
}
