import React from "react"

import { CalendarAccount } from "../models"

import CalendarSyncAvailabilityItem from "./CalendarSyncAvailabilityItem"

import "./styles.scss"

type Props = {
    data: CalendarAccount[]
}

export const CalendarSyncAvailability = ({ data }: Props) => {
    return (
        <>
            {data?.map(calendar => (
                <div className="sync-calendar mb-3 text-left" key={calendar.profileId}>
                    <p className="mb-2 pl-2 font-extrabold">{calendar.profileName}</p>
                    {calendar?.profileCalendars?.map(profile => (
                        <CalendarSyncAvailabilityItem name={profile.calendarId} profile={profile} />
                    ))}
                </div>
            ))}
        </>
    )
}
