import React, { type FC, type ReactElement } from "react"

import { Link } from "react-router-dom"

import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"

import { CoachSettingsUrls } from "../../coach-settings/constants"

const CoachRelinkAccountsHelpLink: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="coach-relink-accounts__help-link">
            <span className="color-gray">{t("coachSide.relinkAccountsPage.helpLink.description")}</span>
            &nbsp;
            <Link to={CoachSettingsUrls.CALENDARS} className="text-underline color-brand ml-1 font-extrabold">
                <span>{t("coachSide.relinkAccountsPage.helpLink.action")}</span>
            </Link>
        </div>
    )
}

export { CoachRelinkAccountsHelpLink }
