import React, { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Heading from "common/components/Heading/Heading"
import { HTTPStatus } from "common/constants"
import { http } from "common/http"
import { getErrorMessages } from "common/utils/get-error-messages"

import Urls from "main-app/api/urls"
import TextInput from "main-app/shared/input/TextInput"
import Modal from "main-app/shared/modal"
import { emailSchema } from "main-app/validation/reset-password"

import { WarningMessage } from "../onboarding/components/WarningMessage"

import "./styles.scss"

interface IProps {
    active: boolean
    setActive: (value: boolean) => void
}

const EmailModal: React.FC<IProps> = ({ active, setActive }) => {
    const { t } = useTranslation()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(emailSchema)
    })
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [resetPasswordText, setResetPasswordMessageText] = useState({ title: "", message: "" })
    const [hasLink, setHasLink] = useState(false)

    const onSubmitEmail = async values => {
        try {
            const { status, data } = await http.post(Urls.resetPasswordEmail(), values)

            if (data.redirect) {
                window.location.replace(data.redirect)
                return
            }

            if (status === HTTPStatus.OK) {
                setHasLink(true)
                setResetPasswordMessageText({ title: data.title, message: data.message })
            }
        } catch (error) {
            if (error.response.status === HTTPStatus.BAD_REQUEST) {
                setErrorsMsg(getErrorMessages(error))
            }
            console.log(error)
        }
    }

    const handleBtnClick = values => {
        if (hasLink) {
            setActive(false)
            setHasLink(false)
        } else {
            onSubmitEmail(values)
        }
    }

    return (
        <Modal
            show={active}
            onClose={() => setActive(false)}
            modalBodyClass="reset-modal__body"
            headerContent={
                <div className="p-3">
                    <Heading tag="h2" className="text-center m-0" fontSize={24}>
                        {hasLink ? resetPasswordText.title : t("Let's reset your password")}
                    </Heading>
                </div>
            }>
            <div className="reset-modal__form">
                <div className="step-1 text-center mb-5">
                    {hasLink ? (
                        <p className="text-center">{resetPasswordText.message}</p>
                    ) : (
                        <>
                            <p className="text-center mb-5">
                                {t(
                                    "Please provide the email address associated with your account and we will send a link to your email inbox which you can follow to reset your password."
                                )}
                            </p>
                            <form className="mt-44-xs" onSubmit={handleSubmit(onSubmitEmail)}>
                                <div className="registration-input__group mb-sm-group">
                                    <div className="registration-flex__wrapper d-flex flex-fill flex-column registration-flex__wrapper">
                                        <WarningMessage message={errorsMsg} />
                                        <TextInput
                                            name="email"
                                            groupClassName="reset-modal__email-input"
                                            register={register}
                                            placeholder={t("Email")}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                            </form>
                        </>
                    )}
                </div>
                <div className="d-flex justify-content-center">
                    <button
                        className="registration-input__register btn-reset-continue btn-flip registration-input__mobile"
                        data-back={hasLink ? t("Close") : t("Continue")}
                        data-front={hasLink ? t("Close") : t("Continue")}
                        type="submit"
                        onClick={handleSubmit(handleBtnClick)}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default EmailModal
