import React from "react"

import classNames from "classnames"

import "./styles.scss"

type Props = {
    className?: string
    fullWith?: boolean
}

const DividerLine = ({ className = "", fullWith = false }: Props) => {
    return <div className={classNames("divider-line mx-auto", className, { "full-width": fullWith })} />
}

export default DividerLine
