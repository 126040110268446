import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import Coach, { CoachApi } from "main-app/models/coach"

import { ServerStateOnbordingKeys } from "../constants"

const getCoaches = async () => {
    const { data } = await http.get(Urls.individualCoachesList())
    return data.results
}

type QueryOptions = {
    enabled?: boolean
}

export default function useAvailableCoaches({ enabled = false }: QueryOptions) {
    return useQuery<CoachApi[], Error, Coach[]>([ServerStateOnbordingKeys.AvailableCoaches], getCoaches, {
        select: useCallback(data => data.map(coach => new Coach(coach)), []),
        enabled
    })
}
