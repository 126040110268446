import React from "react"

import { useFormContext } from "react-hook-form"

import ReflectionQuestion from "../reflection-question/ReflectionQuestion"

interface IProps {
    moduleReflection: any[]
    errors: any
}

const ReflectionQuestions: React.FC<IProps> = ({ moduleReflection, errors }) => {
    const { register } = useFormContext()

    return (
        <>
            {moduleReflection?.map((item: any, indexItem) => (
                <ReflectionQuestion
                    item={item}
                    order={null}
                    key={`${item.uuid}-${indexItem}`}
                    register={register}
                    error={errors?.module_reflection?.[item.uuid]?.message?.toString()}
                    name={`module_reflection.${item.uuid}`}
                    className={indexItem !== moduleReflection?.length - 1 ? "mb-40" : ""}
                />
            ))}
        </>
    )
}

export default ReflectionQuestions
