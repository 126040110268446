import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react"

import classNames from "classnames"
import { CSSTransition } from "react-transition-group"

import useHover from "common/hooks/use-hover"

import { SECONDARY_PORTAL_ID } from "main-app/constants"

import Portal from "../portal/Portal"

import { calculateTooltipPosition } from "./calculate-tooltip-position"
import { TooltipDimensions, TooltipPosition, TooltipStyle } from "./types"

import "./styles.scss"

interface TooltipProps {
    children: ReactNode
    targetRef: React.RefObject<HTMLElement>
    position?: TooltipPosition
    offset?: number | { x: number; y: number }
    style?: React.CSSProperties
    className?: string
}

export const Tooltip = ({
    children,
    targetRef,
    position = "top",
    style = {},
    offset = 10,
    className = ""
}: TooltipProps) => {
    const isVisible = useHover(targetRef)
    const [tooltipDimensions, setTooltipDimensions] = useState<TooltipDimensions>({ width: 0, height: 0 })
    const [tooltipStyle, setTooltipStyle] = useState<TooltipStyle>({})
    const tooltipRef = useRef<HTMLDivElement>(null)

    const offsetX = typeof offset === "number" ? offset : offset?.x ?? 10
    const offsetY = typeof offset === "number" ? offset : offset?.y ?? 10

    useEffect(() => {
        if (tooltipRef.current && isVisible && tooltipDimensions.width === 0) {
            setTooltipDimensions({
                width: tooltipRef.current.offsetWidth,
                height: tooltipRef.current.offsetHeight
            })
        }

        if (isVisible) {
            document.documentElement.style.setProperty(
                "--tooltip-offset",
                `${typeof offset === "number" ? offset : 10}px`
            )
        }

        return () => {
            document.documentElement.style.removeProperty("--tooltip-offset")
        }
    }, [isVisible])

    useLayoutEffect(() => {
        if (targetRef.current && tooltipDimensions.width > 0 && tooltipDimensions.height > 0) {
            const bound = targetRef.current.getBoundingClientRect()

            const { adjustedLeft, adjustedTop } = calculateTooltipPosition(
                position,
                tooltipDimensions,
                { offsetX, offsetY },
                bound
            )

            const newStyle: TooltipStyle = {
                ...style,
                position: "absolute",
                zIndex: 1000,
                left: adjustedLeft,
                top: adjustedTop
            }

            if (JSON.stringify(newStyle) !== JSON.stringify(tooltipStyle)) {
                setTooltipStyle(newStyle)
            }
        }
    }, [position, style, tooltipDimensions, offset])

    return (
        <Portal portalId={SECONDARY_PORTAL_ID}>
            <CSSTransition in={isVisible} timeout={300} classNames={`tooltip-w-${position} tooltip-w`} unmountOnExit>
                <div style={tooltipStyle} ref={tooltipRef} className={classNames("tooltip-i", className)}>
                    {children}
                </div>
            </CSSTransition>
        </Portal>
    )
}
