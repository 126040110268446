import React from "react"

import "./styles.scss"

interface IProps {
    text: string
}

const SimpleTextField: React.FC<IProps> = ({ text }) => {
    return <div className="text-field">{text}</div>
}

export default SimpleTextField
