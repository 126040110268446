import React, { memo } from "react"

import { useTranslation } from "react-i18next"

interface IProps {
    height?: number
    width?: number
}

const AttentionSvg: React.FC<IProps> = ({ width = 20, height = 20 }) => {
    const { t } = useTranslation()
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{t("Info icon")}</title>
            <path
                d="M9.44941 11.324L8.88541 5.54H11.1174L10.5414 11.324H9.44941ZM9.02941 14V12.08H10.9734V14H9.02941Z"
                fill="#FD4D00"
            />
            <rect x="2" y="2" width="16" height="16" rx="8" stroke="#FD4D00" strokeWidth="2" />
        </svg>
    )
}

export default memo(AttentionSvg)
