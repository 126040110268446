import React, { useCallback, useState } from "react"

import { useTranslation } from "react-i18next"
import { Navigate, useNavigate, useParams } from "react-router-dom"

import Heading from "common/components/Heading/Heading"
import { HTTPStatus } from "common/constants"
import { getErrorMessages } from "common/utils/get-error-messages"

import useGroupInfo from "main-app/api/use-group-info"
import { SessionType } from "main-app/constants"
import AttendanceModal from "main-app/elements/attendance-modal/AttendanceModal"
import RsvpModal from "main-app/elements/rsvp-modal/RsvpModal"
import CoachLayout from "main-app/layouts/CoachLayout"
import { GroupSessionParticipant } from "main-app/models/participants"
import CareTeamLink from "main-app/shared/CareTeamLink"
import LiveNowLine from "main-app/shared/livenow-line/LiveNowLine"
import useLiveSessionCheck from "main-app/utils/hooks/use-live-session-check"

import { WarningMessage } from "../onboarding/components/WarningMessage"

import GroupParticipants from "./GroupParticipants"
import PaginatedSessions from "./PaginatedSessions"
import SessionAccordion from "./SessionAccordion"

import "./styles.scss"

interface IProps {}

const GroupInfo: React.FC<IProps> = () => {
    const { t } = useTranslation()
    const params = useParams()
    const navigate = useNavigate()
    const { data, isLoading, refetch, isError, error } = useGroupInfo(params.id)
    const [showAttendanceModal, setShowAttendanceModal] = useState(false)
    const [showRsvpModal, setShowRsvpModal] = useState(false)
    const [sessionId, setSessionId] = useState(null)
    const [selectedRsvp, setSelectedRsvp] = useState<GroupSessionParticipant | null>(null)

    const onCloseAttendanceModal = useCallback(() => {
        setShowAttendanceModal(false)
    }, [])

    const onOpenAttendanceModal = useCallback((id: number) => {
        setSessionId(id)
        setShowAttendanceModal(true)
    }, [])

    const onClickParticipant = useCallback(
        (id: number) => {
            navigate(`/participant-works/${id}`)
        },
        [navigate]
    )

    const onCloseRsvpModal = useCallback(() => {
        setShowRsvpModal(false)
        setSelectedRsvp(null)
    }, [])

    const onClickOpenRsvp = useCallback((participant: GroupSessionParticipant) => {
        setShowRsvpModal(true)
        setSelectedRsvp(participant)
    }, [])

    const session = data?.upcoming_sessions.find(session => session.session_time)

    useLiveSessionCheck({ date: session?.session_time }, () => {
        refetch()
    })

    if (isError && error?.response?.status === HTTPStatus.FORBIDDEN) {
        return <Navigate to="/forbidden" replace />
    }

    return (
        <CoachLayout isLoadingPage={isLoading}>
            <div className="group-info">
                {data ? (
                    <>
                        <Heading textAlign="center" className="mb-50">
                            {data.group_info.title}
                        </Heading>
                        {data.live_session ? (
                            <div className="mb-50">
                                <div className="mb-3">
                                    <LiveNowLine joinLink={data.live_session.join_url} />
                                </div>

                                <SessionAccordion
                                    isLive
                                    session={data.live_session}
                                    onOpenAttendanceModal={onOpenAttendanceModal}
                                    onClickRsvpNote={onClickOpenRsvp}
                                    onClickParticipant={onClickParticipant}
                                />
                            </div>
                        ) : null}
                        <Heading tag="h2" fontSize={24} textAlign="center" className="mb-3">
                            {t("Upcoming Sessions")}
                        </Heading>
                        <PaginatedSessions
                            sessions={data.upcoming_sessions}
                            type={SessionType.UPCOMING}
                            onClickParticipant={onClickParticipant}
                            onClickRsvpNote={onClickOpenRsvp}
                        />

                        <Heading tag="h2" fontSize={24} textAlign="center" className="mt-50 mb-3">
                            {t("Past Sessions")}
                        </Heading>
                        <PaginatedSessions
                            sessions={data.passed_sessions}
                            type={SessionType.PAST}
                            onOpenAttendanceModal={onOpenAttendanceModal}
                            onClickRsvpNote={onClickOpenRsvp}
                            onClickParticipant={onClickParticipant}
                        />

                        <div className="line-separator" />
                        <Heading tag="h2" fontSize={24} textAlign="center" className="mt-3 mb-3">
                            {t("Group Participants")}
                        </Heading>
                        <div className="mb-50">
                            <GroupParticipants participants={data.group_info.participants_info} />
                        </div>
                        <CareTeamLink />
                    </>
                ) : null}
                {isError ? (
                    <WarningMessage
                        message={error ? getErrorMessages(error)?.join("") : t("An error occurred, please try again.")}
                    />
                ) : null}
            </div>

            {showAttendanceModal && (
                <AttendanceModal show={showAttendanceModal} sessionId={sessionId} onClose={onCloseAttendanceModal} />
            )}
            {showRsvpModal && <RsvpModal show={showRsvpModal} onClose={onCloseRsvpModal} participant={selectedRsvp} />}
        </CoachLayout>
    )
}

export default GroupInfo
