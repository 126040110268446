import { TranslationObj, withTranslation } from "common/utils/lang"

export interface WaitilingListApi {
    contact_email: string
    text_data: TranslationObj
    text_info_button_data: TranslationObj
    text_info_email_data: TranslationObj
    text_subtitle_data: TranslationObj
    text_title_data: TranslationObj
    website_link: string
}

export class WaitingList {
    text: string

    textTitleData: string

    websiteLink: string

    textInfoButtonData: string

    textInfoEmailData: string

    textSubtitleData: string

    contactEmail: string

    constructor(data: WaitilingListApi) {
        this.text = withTranslation(data.text_data)
        this.textTitleData = withTranslation(data.text_title_data)
        this.websiteLink = data.website_link
        this.contactEmail = data.contact_email
        this.textInfoButtonData = withTranslation(data.text_info_button_data)
        this.textInfoEmailData = withTranslation(data.text_info_email_data)
        this.textSubtitleData = withTranslation(data.text_subtitle_data)
    }
}
