import React, { useMemo } from "react"

import Skeleton from "common/components/Skeleton/Skeleton"

import { useAuthContext } from "main-app/context/Auth"
import { GroupedModule, groupByCategory, useModulesByParticipant } from "main-app/entities/module"
import { SelfEnrollment } from "main-app/features/self-enrollment"

export const SelfEnrollmentWidget = () => {
    const { user } = useAuthContext()
    const { data, isLoading } = useModulesByParticipant({ participantId: user?.id })

    const groupedModules: GroupedModule[] = useMemo(() => {
        if (data?.length) {
            return Object.values(groupByCategory(data) as GroupedModule[])
        }
        return []
    }, [data])

    if (isLoading) {
        return (
            <>
                <Skeleton rows={1} className="mb-3" />
                <Skeleton rows={1} className="mb-3" height={150} />
                <Skeleton rows={1} className="mb-3" height={150} />
            </>
        )
    }

    return <SelfEnrollment groupedModules={groupedModules} />
}
