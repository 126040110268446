import React, { useMemo } from "react"

import { AxiosError } from "axios"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { Role } from "main-app/constants"
import { SimulabStage, useEndConversation, useSimulabContext } from "main-app/entities/simulab"
import { SimulabActionContextType, SimulabContextType } from "main-app/entities/simulab/context/SimulabContextProvider"
import Button from "main-app/shared/button/Button"

type Props = {
    currentViewStage: SimulabStage
}

export const SimulabInputHeader = ({ currentViewStage }: Props) => {
    const { t } = useTranslation()
    const endConversation = useEndConversation()
    const {
        conversation,
        simulabMessages,
        conversationStarted,
        onError,
        refetchConversation
    }: SimulabContextType & SimulabActionContextType = useSimulabContext()

    const endConversationReached = useMemo(() => {
        const isLastStage = conversation?.stages?.at(-1)?.id === currentViewStage?.id
        const hasMessage = simulabMessages?.messages?.at(-1)?.stageId === currentViewStage?.id

        return isLastStage && hasMessage
    }, [conversation?.stages, currentViewStage, simulabMessages])

    const onEndConversationClick = () => {
        endConversation.mutate(
            { conversationId: String(conversation.id) },
            {
                onSuccess: async () => refetchConversation(),
                onError(error: AxiosError) {
                    onError(error)
                }
            }
        )
    }

    return (
        <header className="simulab-chat-input-box__header">
            <span className={classNames("fs-14 font-extrabold", { "color-gray": !conversationStarted })}>
                {t("Message to Coachee")}
            </span>
            {endConversationReached && (
                <Button
                    className="simulab-chat-input-box__end-conversation"
                    onClick={onEndConversationClick}
                    disabled={simulabMessages?.messages?.at(-1)?.authorRole === Role.Participant}>
                    {t("End Conversation")}
                </Button>
            )}
        </header>
    )
}
