import * as React from "react"

import { Link } from "react-router-dom"

import { ErrorPage } from "./ErrorPage"

export const NotFoundPage = () => {
    return (
        <ErrorPage
            errorContent={
                <div>
                    <span className="font-extrabold color-gray  d-block d-md-inline text-center">404 Error!</span>{" "}
                    <span className="color-gray">
                        This page doesn&lsquo;t exist - let&lsquo;s go back to your dashboard and try again.
                    </span>{" "}
                    <Link to="/" className="color-brand font-bold d-block d-md-inline text-center">
                        Back to dashboard
                    </Link>
                </div>
            }
        />
    )
}
