import React from "react"

import { Navigate } from "react-router-dom"

import { ParticipantUrls } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import SimpleLayout from "main-app/layouts/SimpleLayout"
import { FullSpinner } from "main-app/shared/spinner"

const PostJourneyRedirect = () => {
    const { user, isLoading } = useAuthContext()

    return (
        <SimpleLayout withProfileHeader>
            {isLoading && !user ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <FullSpinner />
                </div>
            ) : (
                <Navigate to={ParticipantUrls.END_OF_JOURNEY_PAGE} replace />
            )}
        </SimpleLayout>
    )
}

export default PostJourneyRedirect
