import * as React from "react"

import { useTranslation } from "react-i18next"

function QueuedModuleSvg(props: React.SVGProps<SVGSVGElement>) {
    const { width = 20, height = 20 } = props
    const { t } = useTranslation()

    return (
        <svg
            width={width}
            height={height}
            aria-label={t("Click to open info modal")}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <title>{t("Question icon")}</title>
            <path
                d="M9.40136 11.324V10.76C9.40136 10.408 9.46536 10.084 9.59336 9.788C9.72136 9.484 9.94136 9.164 10.2534 8.828C10.4774 8.588 10.6334 8.384 10.7214 8.216C10.8094 8.04 10.8534 7.86 10.8534 7.676C10.8534 7.452 10.7694 7.276 10.6014 7.148C10.4414 7.02 10.2094 6.956 9.90536 6.956C9.16136 6.956 8.46136 7.224 7.80536 7.76L7.21736 6.392C7.56936 6.088 7.99736 5.848 8.50136 5.672C9.00536 5.496 9.51736 5.408 10.0374 5.408C10.5654 5.408 11.0294 5.496 11.4294 5.672C11.8294 5.84 12.1374 6.08 12.3534 6.392C12.5774 6.696 12.6894 7.052 12.6894 7.46C12.6894 7.828 12.6054 8.168 12.4374 8.48C12.2774 8.792 11.9894 9.12 11.5734 9.464C11.1974 9.776 10.9374 10.048 10.7934 10.28C10.6574 10.512 10.5734 10.736 10.5414 10.952L10.4814 11.324H9.40136ZM8.96936 14V12.08H10.9134V14H8.96936Z"
                fill="#6F6F6F"
            />
            <rect x="2" y="2" width="16" height="16" rx="8" stroke="#B0B0B0" strokeWidth="2" />
        </svg>
    )
}

export default React.memo(QueuedModuleSvg)
