import * as React from "react"
import { SVGProps } from "react"

interface Props extends SVGProps<SVGSVGElement> {
    disabled?: boolean
}

const FlagIconSvg = React.memo(({ width = 11, height = 17, className = "", ...rest }: Props) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 17"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <path
            d="M5.7267 7.94174C8.19041 9.233 10.3602 7.22789 10.3817 7.20737C10.4422 7.15043 10.4705 7.06748 10.4575 6.98547L9.5388 1.21119C9.52402 1.11941 9.46008 1.04321 9.37181 1.01312C9.28431 0.983766 9.18679 1.00435 9.1191 1.06802C9.10003 1.0861 7.19122 2.85014 5.04069 1.72296C2.57699 0.432425 0.407206 2.43727 0.385543 2.4576C0.376768 2.46585 0.370239 2.47567 0.362717 2.48492C0.359687 2.48894 0.356188 2.49218 0.353419 2.4962C0.336391 2.52028 0.324324 2.54661 0.316802 2.57466C0.315809 2.5779 0.315287 2.58145 0.314556 2.58469C0.307556 2.61551 0.304526 2.64711 0.309802 2.67924L1.22846 8.45351C1.22846 8.45351 1.22846 8.45424 1.22872 8.4545L2.39436 15.7887C2.41415 15.9122 2.52045 16 2.64158 16C2.65464 16 2.66791 15.999 2.68118 15.997C2.81783 15.9751 2.91111 15.8468 2.88954 15.7099L1.74569 8.5124C2.16138 8.17239 3.85156 6.95841 5.72635 7.94126L5.7267 7.94174ZM0.82791 2.73659C1.24716 2.38782 2.91572 1.17551 4.80803 2.16744C6.68804 3.15282 8.39674 2.21884 9.11055 1.71185L9.94021 6.9265C9.524 7.2665 7.83341 8.48008 5.95955 7.49764C4.08008 6.5128 2.37138 7.44624 1.6573 7.95323L0.82811 2.73658L0.82791 2.73659Z"
            fill="#6F6F6F"
            stroke="#6F6F6F"
            strokeWidth="0.2"
        />
    </svg>
))

export { FlagIconSvg }
