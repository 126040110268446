import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { ServerStateKeys } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"

export const getCoachingSessions = async ({ queryKey }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id }] = queryKey
    const { data } = await http.get(Urls.coachingSessions(id))
    return data
}

type QueryOptions = {
    id?: number
    enabled?: boolean
}

export default function useCoachingSessions({ id, enabled = true }: QueryOptions = {}) {
    const { user } = useAuthContext()
    return useQuery([ServerStateKeys.CoachingSession, { id }], getCoachingSessions, {
        select: useCallback(data => data, []),
        enabled: !!user && enabled
    })
}
