import React from "react"

import Skeleton from "common/components/Skeleton/Skeleton"

import { useSimulabCoachees } from "main-app/entities/simulab"

import { CoacheeCard } from "./CoacheeCard"

import "./styles.scss"

export const CoacheeList = () => {
    const { data, isLoading } = useSimulabCoachees()

    return (
        <section className="coachee-list">
            {isLoading ? (
                <Skeleton rows={3} height={297} />
            ) : (
                data?.map(coachee => <CoacheeCard coachee={coachee} key={coachee.name} />)
            )}
        </section>
    )
}
