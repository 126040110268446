import { useCallback } from "react"

import { type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"

interface ICoachAccountsRelinkingDisconnectMutationParams {
    profileId: string
}

interface ICoachAccountsRelinkingDisconnectApiData {
    profile_id: string
}

type TUseCoachAccountsRelinkingDisconnectMutation = UseMutationResult<
    AxiosResponse<ICoachAccountsRelinkingDisconnectApiData>,
    AxiosError
>

async function postCoachAccountRelinkingDisconnect({
    profileId
}: ICoachAccountsRelinkingDisconnectMutationParams): Promise<AxiosResponse> {
    return http.post(Urls.postCoachAccountsRelinkingDisconnect({ profileId }), { profile_id: profileId })
}

function useCoachAccountsRelinkingDisconnectMutation(): ReturnType<() => TUseCoachAccountsRelinkingDisconnectMutation> {
    return useMutation(useCallback(postCoachAccountRelinkingDisconnect, []))
}

export {
    useCoachAccountsRelinkingDisconnectMutation,
    type TUseCoachAccountsRelinkingDisconnectMutation,
    type ICoachAccountsRelinkingDisconnectApiData
}
