import React from "react"

import { Trans, useTranslation } from "react-i18next"

import Heading from "common/components/Heading/Heading"
import { withTranslation } from "common/utils/lang"

import { GroupedModule, Module } from "main-app/entities/module"
import { ModuleAccordion } from "main-app/entities/module/ui/ModuleAccordion"
import { sanitizeContent } from "main-app/utils/common"

import "./styles.scss"

type Props = {
    groupedModules: GroupedModule[]
    onSelectModule?: (module: Module) => void
}

export const SelfEnrollment = ({ groupedModules, onSelectModule }: Props) => {
    const { t } = useTranslation()

    return (
        <section className="self-enrollment">
            <p className="mb-30 fs-14">
                <span className="fs-24 font-extrabold d-block d-md-inline mr-0 mr-md-1">{t("Modules")}</span>
                {"  "}
                {t("You may browse the available modules.")}
            </p>

            {groupedModules?.map(group => (
                <div className="self-enrollment-group" key={group.category}>
                    <div className="self-enrollment-category">
                        <Heading tag="h2" fontSize={16}>
                            <Trans i18nKey="<0>Modules for </0><1>{categoryName}</1>">
                                <span className="fs-16 font-weight-bold">Modules for </span>
                                <span className="fs-16 font-weight-bold color-brand">
                                    {{ categoryName: group.category }}
                                </span>
                            </Trans>
                        </Heading>
                        <p
                            className="self-enrollment-category-description"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeContent(withTranslation(group.categoryDescription))
                            }}
                        />
                    </div>
                    <div>
                        {group.modules?.map((module, index, array) => (
                            <ModuleAccordion
                                key={module.id}
                                module={module}
                                className={array.length - 1 === index ? "mb-0" : "mb-10"}
                                onSelectModule={onSelectModule}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </section>
    )
}
