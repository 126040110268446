import React, { type FC } from "react"

import { DateTime } from "luxon"

import { Session } from "main-app/models/session"
import Button from "main-app/shared/button/Button"

import { CoachTableGeneralCell } from "./CoachTableGeneralCell"

type TCoachTableJoinSessionCellProps = { session: Session }

const CoachTableJoinSessionCell: FC<TCoachTableJoinSessionCellProps> = ({
    session
}: TCoachTableJoinSessionCellProps) => {
    const isSessionAvailable: string = ((): string => {
        const currentTime = DateTime.now()
        const sessionTime = DateTime.fromISO(session.sessionTime)
        const sessionAvailableTime = sessionTime.minus({ minutes: 15 })
        const sessionEndTime = sessionTime.plus({ minutes: session.sessionType.duration })

        return (
            currentTime.toMillis() >= sessionAvailableTime.toMillis() &&
            currentTime.toMillis() < sessionEndTime.toMillis() &&
            session?.joinUrl
        )
    })()

    return (
        <CoachTableGeneralCell isLarge isHidden withoutLeftAlignedText withoutLeftPadding>
            {isSessionAvailable ? (
                <Button className="table-btn w-auto" variant="brand" onClick={e => e.stopPropagation()}>
                    <a href={session?.joinUrl} target="_blank" className="font-extrabold" rel="noreferrer">
                        Join Session
                    </a>
                </Button>
            ) : null}
        </CoachTableGeneralCell>
    )
}

export { CoachTableJoinSessionCell, type TCoachTableJoinSessionCellProps }
