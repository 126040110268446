import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { ThreeWayManager } from "../model/three-way"

type Props = {
    threeWayManager: ThreeWayManager
    className?: string
}

export const ThreeWayInfo = ({ threeWayManager, className }: Props) => {
    const { t } = useTranslation()

    return (
        <div className={classNames("three-way-info text-center", className)}>
            <p className="mb-1 font-extrabold">{t("About the Session")}</p>
            <p className="m-0">
                {t(
                    "This coaching session is a three-way session. This means your manager will be joining alongside your and your coach. {{managerName}} has received an invite at their email: {{managerEmail}}",
                    {
                        managerName: `${threeWayManager.managerFirstName} ${threeWayManager.managerLastName}`,
                        managerEmail: threeWayManager.managerEmail
                    }
                )}
            </p>
        </div>
    )
}
