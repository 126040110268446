import React, { useRef } from "react"

import classNames from "classnames"

import useHover from "common/hooks/use-hover"
import { Option } from "common/models/option"

import Button from "main-app/shared/button/Button"
import WithMenuCard from "main-app/shared/card/WithMenuCard"
import DragElement from "main-app/shared/drag-n-drop/DragElement"

import { OldBelief } from "../model/types/old-belief"

import "./styles.scss"

type Props = {
    goal: OldBelief
    oldBeliefIndex: number
    provider: any
    snapshot: any
    isArchived: boolean
    index: number
    showDrag?: boolean
    onArchiveClick: (index: number) => void
    onClickOldeBelief: (index: number) => void
}

const ITEMS = [new Option({ value: "edit", label: "Edit" }), new Option({ value: "archive", label: "Archive" })]
const ARCHIVE = [new Option({ value: "unarchive", label: "Unarchive" })]

export const OldBeliefGoalCard = ({
    index,
    goal,
    oldBeliefIndex,
    isArchived,
    provider,
    showDrag = true,
    snapshot,
    onClickOldeBelief,
    onArchiveClick
}: Props) => {
    const cardRef = useRef()
    const hover = useHover(cardRef)

    const onMenuItemClick = (item: Option) => {
        if (item.value === "archive" || item.value === "unarchive") {
            onArchiveClick(index)
            return
        }

        if (isArchived) {
            return
        }

        onClickOldeBelief(oldBeliefIndex)
    }
    const onClick = () => {
        if (isArchived) {
            return
        }

        onClickOldeBelief(oldBeliefIndex)
    }

    return (
        <div className="old-belief-goal-card-wrapper" ref={cardRef}>
            {showDrag && hover && (
                <DragElement
                    provider={provider}
                    snapshot={snapshot}
                    withStyles={false}
                    className="old-belief-drag-elem"
                />
            )}
            <WithMenuCard
                cardClassName={classNames("pr-4 old-belief-card", { "bg-light-accent": isArchived })}
                menuItems={isArchived ? ARCHIVE : ITEMS}
                onMenuItemClick={onMenuItemClick}
                menuOffsets={{ right: -100, top: 45 }}
                menuPosition="right"
                showThreeDots={hover}>
                <Button variant="default" className="p-0 w-100 h-100 old-belief-goal-button" onClick={onClick}>
                    <p className="m-0 color-gray fs-14 font-italic text-center">{goal.title}</p>
                </Button>
            </WithMenuCard>
        </div>
    )
}
