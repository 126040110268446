import React, { useCallback, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useUpdateEffect from "common/hooks/use-update-effect"

import { FeedbackTypes, SimulabQueryKey, useSimulabContext } from "main-app/entities/simulab"
import Button from "main-app/shared/button/Button"
import { WiserLogo } from "main-app/svgs"
import CloseIconSvg from "main-app/svgs/CloseIconSvg"

import "../styles.scss"

type Props = {
    viewOnly?: boolean
    className?: string
}

export const SimulabBadMessage = ({ viewOnly = false, className = "" }: Props) => {
    const queryClient = useQueryClient()
    const { badMessage, newMessages, resetErrors, setReportData } = useSimulabContext()
    const [showBadMessage, setShowBadMessage] = useState(viewOnly)
    const { t } = useTranslation()

    useUpdateEffect(() => {
        if (!viewOnly) {
            setShowBadMessage(badMessage)
        }
    }, [badMessage, viewOnly])

    const onCloseClick = useCallback(() => {
        setShowBadMessage(false)
        resetErrors()
    }, [])

    const onReportErrorClick = () => {
        const lastBadMessage = newMessages?.badRatingFeedbacks?.at(-1)
        const lastSentMessage: string = queryClient.getQueryData([SimulabQueryKey.LasSentMessageParticipant])
        setReportData({
            type: FeedbackTypes.PRACTICE_LAB_REJECTED_QUESTION,
            message: lastSentMessage,
            message_id: lastBadMessage?.messageId
        })
    }

    const feedback = newMessages?.badRatingFeedbacks?.at(-1)
    const feedbackText = feedback
        ? `${feedback?.feedbackData?.feedbackSummary} ${feedback?.feedbackData?.feedbackEmoji} ${feedback?.feedbackData?.feedbackTip}`
        : ""

    return (
        showBadMessage && (
            <div className={classNames("simulab-bad-message", className)}>
                <div className="simulab-bad-message__owl">
                    <WiserLogo width={19} height={25} />
                </div>
                <div className="simulab-bad-message__box">
                    <p className="font-extrabold m-0 fs-14 text-center">{t("Try asking a different question.")}</p>
                    <p className="fs-14 text-center m-0">
                        {feedbackText ||
                            t(
                                "This question could provoke a defensive response and does not foster a vision for a better situation or encourage self-reflection."
                            )}
                    </p>
                    {!viewOnly && (
                        <>
                            <div className="text-center">
                                <Button
                                    className="p-0 simulab-bad-message__box__report-error"
                                    variant="default"
                                    data-testid="report-btn"
                                    onClick={onReportErrorClick}>
                                    Report Error
                                </Button>
                            </div>

                            <Button
                                variant="default"
                                className="simulab-bad-message__close-btn"
                                data-testid="close-btn"
                                onClick={onCloseClick}>
                                <CloseIconSvg width={13} height={13} />
                            </Button>
                        </>
                    )}
                </div>
            </div>
        )
    )
}
