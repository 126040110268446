import React from "react"

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { CARE_TEAM_EMAIL } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import Sidebar from "main-app/shared/sidebar/Sidebar"
import WiserLogo from "main-app/svgs/WiserLogo"

import { dashboardURL } from "../onboarding/constants"

const MobileMenuBurger = () => {
    const { t } = useTranslation()
    const { logout } = useAuthContext()

    const handleLogout = async e => {
        e.preventDefault()

        logout()
    }

    return (
        <Sidebar className="d-md-none" sidebarLogo={<WiserLogo width={26} height={26} />}>
            <ul className="menu-capitalized">
                <li className="">
                    <Link to={dashboardURL}>
                        <span>{t("Dashboard")}</span>
                    </Link>
                </li>
                <li>
                    <a href={CARE_TEAM_EMAIL}>
                        <span>{t("Contact Care Team")}</span>
                    </a>
                </li>
                <li>
                    <a href="" onClick={handleLogout}>
                        <span>{t("Sign Out")}</span>
                    </a>
                </li>
            </ul>
        </Sidebar>
    )
}

export default MobileMenuBurger
