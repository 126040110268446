import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { ServerStateKeys } from "main-app/constants"

const getGroupInfo = async (id: string) => {
    const { data } = await http.get(Urls.groupInfo(id))
    return data
}

export default function useGroupInfo(id: string) {
    return useQuery<any, AxiosError, any>([ServerStateKeys.GroupInfo, id], () => getGroupInfo(id), {
        select: useCallback(data => {
            return data
        }, [])
    })
}
