import React, { useState } from "react"

import { AxiosError } from "axios"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { HTTPStatus } from "common/constants"
import useBoolean from "common/hooks/use-boolean"
import { getErrorMessages } from "common/utils/get-error-messages"
import { withTranslation } from "common/utils/lang"

import CategoryModal from "main-app/elements/category-modal/CategoryModal"
import DiagnosticResponsesModal from "main-app/elements/diagnostic-responses-modal/DiagnosticResponsesModal"
import Button from "main-app/shared/button/Button"
import useNavigationOnboarding from "main-app/utils/hooks/use-navigation-onboarding"

import useNextStep from "./api/mutations/use-next-step"
import useDiagnosticResults from "./api/use-diagnostic-results"
import DiagnosticResults from "./components/DiagnosticResults"
import { StepsNavigator } from "./components/StepsNavigator"
import { WarningMessage } from "./components/WarningMessage"
import { OnboardingSteps } from "./constants"
import OnboardingLayout from "./layout/OnboardingLayout"

interface IProps {}

const DiagnosticResponsesStep: React.FC<IProps> = () => {
    const { t } = useTranslation()
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [isBusy, setIsBusy] = useState(false)
    const { value: showCategoryModal, setValue: setShowCategoryModal } = useBoolean()
    const { data: diagnosticSummary } = useDiagnosticResults()
    const [selectedCategory, setSelectedCategory] = useState(null)
    const { value: showDiagnosticResponseModal, setValue: setShowDiagnosticResponseModal } = useBoolean(false)
    const { getNextStepUrl } = useNavigationOnboarding()
    const handleNextStep = useNextStep()
    const navigate = useNavigate()

    const handleNext = () => {
        setIsBusy(true)
        handleNextStep.mutate(
            { step: OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP },
            {
                async onSuccess() {
                    const nextStep = await getNextStepUrl(OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP)
                    navigate(nextStep.url)
                },
                onError(error: AxiosError) {
                    const errorMessage = getErrorMessages(error)
                    if (error?.response?.status === HTTPStatus.BAD_REQUEST) {
                        setErrorsMsg(errorMessage)
                    }
                    console.log(errorMessage)
                },
                onSettled() {
                    setIsBusy(false)
                }
            }
        )
    }

    const onClickCategoryModalTooltip = category => {
        setSelectedCategory(category)
        setShowCategoryModal(true)
    }

    const onCloseCategoryModal = () => {
        setShowCategoryModal(false)
        setSelectedCategory(null)
    }

    const onCloseDiagnostic = () => {
        setShowDiagnosticResponseModal(false)
    }

    const onOpenDiagnostic = () => {
        setShowDiagnosticResponseModal(true)
    }

    return (
        <OnboardingLayout
            header={<StepsNavigator activeStep={{ id: OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP, order: 4 }} />}>
            <div className="container-md">
                <WarningMessage message={errorsMsg} />
                <div className="mb-4 mt-6 text-center">
                    <h1 className="h1 font-extrabold">{t("Pre-Program Diagnostic Results")}</h1>
                    <p className="color-gray fs-14">
                        {t(
                            "These results serve only as a starting point. You will work with your coach to decide which modules you’d like to explore."
                        )}
                    </p>
                </div>
                <div className="accordion-mob">
                    <div className="container container-max-md p-0">
                        <DiagnosticResults
                            showTooltip
                            onTooltipClick={onClickCategoryModalTooltip}
                            onOpenFullDiagnostic={onOpenDiagnostic}
                        />
                    </div>

                    <div className="mt-50 text-center">
                        <Button onClick={handleNext} isBusy={isBusy} className="btn btn--brand btn-200">
                            {t("Next")}
                        </Button>
                        <p className="mt-3 font-italic font-sm color-gray">
                            {t("You will be able to revisit these results from your dashboard.")}
                        </p>
                    </div>
                </div>
            </div>
            <DiagnosticResponsesModal
                show={showDiagnosticResponseModal}
                onClose={onCloseDiagnostic}
                participantName={t("My")}
                preProgramDiagnostics={diagnosticSummary?.pre_program_diagnostic}
            />
            <CategoryModal
                show={showCategoryModal}
                onClose={onCloseCategoryModal}
                categoryDescription={withTranslation(selectedCategory?.description)}
                categoryName={withTranslation(selectedCategory?.name)}
            />
        </OnboardingLayout>
    )
}

export default DiagnosticResponsesStep
