import React, { useEffect, useMemo, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import useUpdateEffect from "common/hooks/use-update-effect"
import { Option } from "common/models/option"
import { isNullOrUndefined } from "common/utils/gates"
import { getErrorMessages } from "common/utils/get-error-messages"

import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage"
import { useAuthContext } from "main-app/context/Auth"
import { GoalsSortSelector } from "main-app/features/goals-sort-selector/ui/GoalsSortSelector"
import PlusIcon from "main-app/shared/assets/icon/PlusIcon"
import Button from "main-app/shared/button/Button"
import { GoalsSortType } from "main-app/shared/types/sort"
import Arrow from "main-app/svgs/Arrow"

import { QueryKey } from "../consts"
import { useOldBeliefApi } from "../hooks/use-old-belief-api"
import { OldBelief } from "../model/types/old-belief"

import { OldBeliefGoalList } from "./OldBeliefGoalList"
import { OldBeliefGoalModal } from "./OldBeliefGoalModal"

import "./styles.scss"

type Props = {
    isToggled: boolean
    toggle: () => void
}

export const OldBeliefGoal = ({ isToggled, toggle }: Props) => {
    const { t } = useTranslation()

    const queryClient = useQueryClient()
    const { user } = useAuthContext()
    const params = useParams()
    const userId = !isNullOrUndefined(params?.id) ? +params?.id : user?.id

    const [sort, setSort] = useState<GoalsSortType>("active")
    const [isOpenOldBeliefModal, setIsOpenOldBeliefModal] = useState(false)
    const [currentOldBelief, setCurrentOldBelief] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)

    const {
        oldBeliefs,
        isLoadingOldBeliefs,
        oldBeliefError,
        archivedOldBeliefs,
        archiveError,
        createOldBelief,
        editOldBelief,
        archiveOldBelief,
        unarchiveOldBelief,
        sortOldBeliefs
    } = useOldBeliefApi({ userId, sort })

    const methods = useForm<{ goals: OldBelief[] }>({
        defaultValues: {
            goals: []
        }
    })

    const { fields, replace, move } = useFieldArray({
        control: methods.control,
        name: "goals",
        keyName: "uuid"
    })

    const hasArchived = useMemo(() => {
        return archivedOldBeliefs?.length > 0
    }, [archivedOldBeliefs])

    useEffect(() => {
        if (!isLoadingOldBeliefs && oldBeliefs) {
            replace(oldBeliefs)
        }
    }, [oldBeliefs, isLoadingOldBeliefs])

    useUpdateEffect(() => {
        if (!hasArchived && sort === "archived") {
            setSort("active")
        }
    }, [hasArchived])

    const onClickOldBelief = (index: number) => {
        setCurrentOldBelief(index)
        setIsOpenOldBeliefModal(true)
    }

    const onSubmitOldBeliefModal = async (values?: Pick<OldBelief, "title" | "description">) => {
        const oldBelief = methods.getValues(`goals.${currentOldBelief}`)
        const fields = methods.getValues("goals")
        const { title, description } = values

        try {
            if (isNullOrUndefined(oldBelief)) {
                await createOldBelief.mutateAsync({
                    title,
                    description,
                    order: fields.length + 1
                })
            } else {
                await editOldBelief.mutateAsync({ order: currentOldBelief + 1, id: oldBelief.id, title, description })
            }
            invalidateQueries()
        } catch (error) {
            console.log(error)
        }

        setCurrentOldBelief(null)
        setIsOpenOldBeliefModal(false)
    }

    const invalidateQueries = () => {
        queryClient.invalidateQueries([QueryKey.OldBelief])
    }

    const onCloseOldBeliefModal = () => {
        setCurrentOldBelief(null)
        setIsOpenOldBeliefModal(false)
    }

    const onChangeSort = (sort: Option) => {
        setSort(sort.value as GoalsSortType)
    }

    const onAddOldBeliefGoal = () => {
        if (oldBeliefError || archiveError) {
            return
        }
        setIsOpenOldBeliefModal(true)
    }

    const onDragEnd = async result => {
        const { source, destination, type } = result
        if (!destination) return
        if (type === "old-belief-goal-drag") {
            move(source.index, destination.index)

            const ids = methods.getValues("goals").map(goal => goal.id)

            try {
                await sortOldBeliefs.mutateAsync({ ids })
                invalidateQueries()
            } catch (error) {
                setErrorMsg(getErrorMessages(error))
            }
        }
    }

    const onArchive = async (index: number) => {
        const { id } = methods.getValues(`goals.${index}`)
        const isArchived = archivedOldBeliefs.includes(id)

        try {
            if (isArchived) {
                await unarchiveOldBelief.mutateAsync(id)
            } else {
                await archiveOldBelief.mutateAsync(id)
            }
            invalidateQueries()
        } catch (error) {
            setErrorMsg(getErrorMessages(error))
        }
    }

    const errorMessage = useMemo(() => {
        if (oldBeliefError || archiveError) {
            const error = oldBeliefError ?? archiveError
            const msg = getErrorMessages(error?.message)?.join() ?? t("An error occurred, please try again.")
            return msg
        }
        return ""
    }, [oldBeliefError, archiveError])

    return (
        <FormProvider {...methods}>
            <div className="d-flex justify-content-between">
                <Button
                    variant="default"
                    className={classNames("p-0 m-0", { "btn-old-belief-toggled": isToggled })}
                    onClick={toggle}>
                    <div className="old-belief-goal__arrow-btn">
                        <div className="old-belief-goal__arrow-btn_arrows">
                            <Arrow />
                            <Arrow />
                        </div>
                    </div>
                </Button>

                {hasArchived && <GoalsSortSelector sort={sort} className="pr-2" onChangeSelect={onChangeSort} />}
            </div>
            <div className="old-belief-goal">
                <p className="color-gray text-center font-extrabold">{t("Old Beliefs")}</p>
                <WarningMessage message={errorMessage} />
                <div className="old-belief-goal-container">
                    {oldBeliefs?.length === 0 && fields.length === 0 ? (
                        <p className="m-0 pl-3 pr-3 color-gray fs-14 font-italic text-center">
                            {t("What old beliefs have been holding you back? What is their significance?")}
                        </p>
                    ) : (
                        <OldBeliefGoalList
                            onArchiveCick={onArchive}
                            archivedBeliefs={archivedOldBeliefs}
                            goals={fields}
                            onClickOldeBelief={onClickOldBelief}
                            onDragEnd={onDragEnd}
                        />
                    )}
                </div>
            </div>
            <WarningMessage message={errorMsg} className="mt-3" />
            <div className="d-flex justify-content-center mt-3">
                <Button variant="outline-rect" onClick={onAddOldBeliefGoal}>
                    <PlusIcon />
                    <span className="d-inline-block ml-2">{t("Add Old Belief")}</span>
                </Button>
            </div>
            {isOpenOldBeliefModal && (
                <OldBeliefGoalModal
                    oldBeliefs={oldBeliefs}
                    oldBeliefIndex={currentOldBelief}
                    isOpen={isOpenOldBeliefModal}
                    onClose={onCloseOldBeliefModal}
                    onSubmit={onSubmitOldBeliefModal}
                />
            )}
        </FormProvider>
    )
}
