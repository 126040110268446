import React, { useCallback, useEffect, useRef } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { CSSTransition } from "react-transition-group"

import useMediaQuery from "common/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { useSimulabContext } from "main-app/entities/simulab"
import { SIMULAB_CONVERSATION_STATUS } from "main-app/entities/simulab/constants"
import Button, { IButtonProps } from "main-app/shared/button/Button"
import { scrollToBottom } from "main-app/utils/common"
import { ESimulabBuiltInMode } from "main-app/widgets/simulab/ui/Simulab"

import { SimulabChatHeader } from "./SimulabChatHeader"
import { SimulabChatInputBox } from "./SimulabChatInput"
import { ConversationEndMessage } from "./end-conversation/ConversationEndMessage"
import { SimulabEndConversation } from "./end-conversation/SimulabEndConverastion"
import { SimulabEndConversationLoader } from "./end-conversation/SimulabEndConversationkLoader"
import { SimulabBadMessage } from "./messages/SimulabBadMessage"
import { SimulabChatFeed } from "./messages/SimulabChatFeed"
import { SimulabErrorBox } from "./messages/SimulabErrorBox"
import { SimulabFirstMesageStage } from "./messages/SimulabFirstMesageStage"
import { SimulabFeedbackMobile } from "./mobile/SimulabFeedbackMobile"

import "./styles.scss"

type Props = {
    mode?: ESimulabBuiltInMode
    onClickMobileFeedback: () => void
    chatEndConversationButtonProps?: IButtonProps
}

export const SimulabChat = ({
    mode = ESimulabBuiltInMode.Standalone,
    onClickMobileFeedback,
    chatEndConversationButtonProps
}: Props) => {
    const endOfChatBox = useRef<HTMLDivElement>(null)
    const startOfChatBox = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const loaderRef = useRef()
    const feedbackRef = useRef()

    const { simulabMessages, hasError, badMessage, conversation, conversationStarted, setConversationStarted } =
        useSimulabContext()

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!simulabMessages?.messages?.length && mode !== ESimulabBuiltInMode.Standalone) {
                startOfChatBox?.current?.scrollIntoView({ behavior: "auto" })
            } else {
                scrollToBottom(endOfChatBox, { behavior: "auto" })
            }
        }, 0)

        return () => clearTimeout(timeout)
    }, [simulabMessages?.messages?.length, hasError, badMessage, conversation])

    const startConversation = useCallback(() => {
        setConversationStarted(true)
    }, [setConversationStarted])

    const isConversationInProgress = conversation?.status === SIMULAB_CONVERSATION_STATUS.IN_PROGRESS
    const isConversationEnded =
        conversation?.status === SIMULAB_CONVERSATION_STATUS.COMPLETED && !!conversation?.feedback
    const isConversationFeedbackInProgress = conversation?.status === SIMULAB_CONVERSATION_STATUS.REVIEW_IN_PROGRESS

    const endConversationButtonProps: IButtonProps = {
        variant: "outline",
        className: "simulab-end-feedback__back-btn",
        onClick: () => navigate("/simulab-coachees"),
        children: t("Back to Coachees"),
        ...chatEndConversationButtonProps
    }

    const chatComponents = (
        <>
            <div ref={startOfChatBox} />
            {isMobile && isConversationInProgress && <SimulabFeedbackMobile onClickFeedback={onClickMobileFeedback} />}
            <SimulabChatHeader
                coachee={conversation?.coachee}
                showButton={simulabMessages?.messages?.length === 0 && !conversationStarted}
                onButtonClick={startConversation}
            />
            <SimulabChatFeed />
            <SimulabBadMessage />
            <SimulabErrorBox />
            <SimulabFirstMesageStage />
            {(isConversationFeedbackInProgress || isConversationEnded) && <ConversationEndMessage />}
            <CSSTransition
                in={isConversationFeedbackInProgress}
                nodeRef={loaderRef}
                classNames="simulab-feedback-loader"
                timeout={600}
                unmountOnExit>
                <SimulabEndConversationLoader ref={loaderRef} />
            </CSSTransition>
            <CSSTransition
                in={isConversationEnded}
                nodeRef={feedbackRef}
                classNames="simulab-end-feedback"
                timeout={1200}
                unmountOnExit>
                <div ref={feedbackRef} className="simulab-end-feedback-wrapper">
                    <SimulabEndConversation />
                    <div className="text-center py-3">
                        <Button {...endConversationButtonProps} />
                    </div>
                </div>
            </CSSTransition>

            {isConversationInProgress && <SimulabChatInputBox />}
            <div ref={endOfChatBox} />
        </>
    )

    return (
        <div className="simulab-chat">
            <main
                className={classNames("simulab-chat__container custom-scrollbar", {
                    "has-final-feedback": isConversationEnded
                })}>
                {isMobile ? (
                    <>{chatComponents}</>
                ) : (
                    <section className="simulab-chat__wrapper">{chatComponents}</section>
                )}
            </main>
        </div>
    )
}
