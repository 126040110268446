import React from "react"

import Heading from "common/components/Heading/Heading"

import { useZoom } from "main-app/entities/coach-calendar/api/use-zoom"
import CoachLayout from "main-app/layouts/CoachLayout"
import DateWidget from "main-app/shared/DateWidget"
import Card from "main-app/shared/card/Card"
import ActiveCheckMarkSvg from "main-app/svgs/ActiveCheckMarkSvg"

import LeftSidebar from "./ui/LeftSidebar"

import "./styles.scss"

const CoachZoomAccount = () => {
    const { data } = useZoom()

    const hasUrl = data ? "url" in data : false

    return (
        <CoachLayout
            leftSidebar={<LeftSidebar />}
            headerTitle={<DateWidget className="coach-date-title" />}
            withContainer={false}
            fullWidth
            align="left">
            <div className="coach-calendars-wrapper coach-zoom-account">
                <Heading tag="h1" className="mb-2 mt-50" fontSize={36}>
                    My Zoom Account
                </Heading>
                <p className="color-gray fs-14 mb-0">
                    The Zoom account associated with your Wiser profile is shown below. <br />
                    This is the account you will use to access your video coaching sessions.
                </p>
                <Card className="mt-60">
                    <div className="d-flex align-items-center">
                        {hasUrl ? (
                            <p className="text-center m-0 color-gray font-italic">No accounts linked yet.</p>
                        ) : (
                            <>
                                <div className="flex-1">
                                    <span>{data?.profile_name}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className="color-brand font-extrabold mr-2">Success</span>
                                    <ActiveCheckMarkSvg />
                                </div>
                            </>
                        )}
                    </div>
                </Card>
            </div>
        </CoachLayout>
    )
}

export default CoachZoomAccount
