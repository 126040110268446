import React, { useCallback, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import useMediaQuery from "common/hooks/use-media-query"
import { usePageRefreshed } from "common/hooks/use-page-refreshed"
import useUpdateEffect from "common/hooks/use-update-effect"
import { isEmptyString } from "common/utils/gates"

import { MAX_WIDTH_MOBILE_MEDIA, Role } from "main-app/constants"
import { useSimulabContext } from "main-app/entities/simulab"
import { SimulabActionContextType, SimulabContextType } from "main-app/entities/simulab/context/SimulabContextProvider"
import Button from "main-app/shared/button/Button"
import TextInput from "main-app/shared/input/TextInput"
import { DisabledSendIconSvg } from "main-app/svgs/DisabledSendIconSvg"
import { MessageSendIconSvg } from "main-app/svgs/MessageSendSvg"

import { SIMULAB_INPUT_CHARACTER_LIMIT, SIMULAB_WARNING_CHARACTER_NUMBER } from "../constants"

import { SimulabInputHeader } from "./SimulabInputHeader"

import "./styles.scss"

export const SimulabChatInputBox = () => {
    const { t } = useTranslation()
    const inputRef = useRef<HTMLDivElement>()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const {
        conversation,
        sendMessage,
        simulabMessages,
        badMessage,
        hasError,
        conversationStarted,
        unselectMessage,
        resetErrors,
        selectMessage,
        onError
    }: SimulabContextType & SimulabActionContextType = useSimulabContext()

    const { register, handleSubmit, watch, setValue, setFocus } = useForm()

    const [lastMessageValue, setLastMessageValue] = useState("")
    const [isFocused, setIsFocused] = useState(false)

    const isRefreshedPage = usePageRefreshed()

    useUpdateEffect(() => {
        if (badMessage || hasError) {
            setValue("simulab_mesage_text", lastMessageValue)
        }
    }, [badMessage, hasError, lastMessageValue])

    const currentViewStage = useMemo(() => {
        return conversation?.stages?.find(stage => stage.current)
    }, [conversation?.stages])

    const messageTextPrompt = watch("simulab_mesage_text")

    const isMoreThanWarningLimit = messageTextPrompt?.length > SIMULAB_WARNING_CHARACTER_NUMBER

    const disabledSendBtn =
        (simulabMessages?.messages?.at(-1)?.authorRole === Role.Participant && !isRefreshedPage && !hasError) ||
        isEmptyString(messageTextPrompt)

    const onFocus = useCallback(() => {
        setIsFocused(true)
    }, [])

    const onBlur = useCallback(() => {
        setIsFocused(false)
    }, [])

    const setFocusToInput = useCallback(
        event => {
            event.stopPropagation()
            setFocus("simulab_mesage_text")
            onFocus()
        },
        [setFocus]
    )

    const onSendMessage = handleSubmit(values => {
        const message = values.simulab_mesage_text
        selectMessage(null)
        unselectMessage(false)
        setLastMessageValue(message)
        sendMessage.mutate(
            { message, stage: currentViewStage?.id },
            {
                onSuccess() {
                    setValue("simulab_mesage_text", "")
                    resetErrors()
                },
                onError(error) {
                    onError(error)
                }
            }
        )
    })

    const onKeyDown = e => {
        if (isMobile) {
            inputRef.current?.scrollIntoView()
        }
        if (e.key === "Enter" && !disabledSendBtn) {
            onSendMessage()
        }
    }

    return (
        <section className="simulab-chat-input-wrapper">
            <div className={classNames("simulab-chat-input-box", { focused: isFocused })}>
                <SimulabInputHeader currentViewStage={currentViewStage} />
                <form
                    onSubmit={onSendMessage}
                    className={classNames("d-flex flex-column simulab-chat-input-form", {
                        "not-allowed": !conversationStarted
                    })}
                    data-testid="simulab-chat-input-form"
                    onClick={setFocusToInput}>
                    <div ref={inputRef}>
                        <TextInput
                            textArea
                            dynamicHeight
                            maxHeight={180}
                            maxLength={500}
                            currentValue={messageTextPrompt}
                            preventEnter
                            placeholder={t("Begin typing...")}
                            name="simulab_mesage_text"
                            variant="rect-no-border"
                            groupClassName="m-0"
                            className={classNames("simulab-chat-input", {
                                "not-allowed": !conversationStarted
                            })}
                            register={register}
                            disabled={!conversationStarted}
                            autoFocus={conversationStarted}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                        />
                    </div>
                    <div className="d-flex align-self-end">
                        {isMoreThanWarningLimit && (
                            <p className="max-character-counter">
                                {t("Max.")} {messageTextPrompt?.length}/{SIMULAB_INPUT_CHARACTER_LIMIT}
                            </p>
                        )}
                        <Button
                            aria-label="send message"
                            variant="default"
                            className={classNames("simulab-chat-input-box__send-btn p-0 ml-2", {
                                disabled: disabledSendBtn
                            })}
                            type="submit"
                            disabled={disabledSendBtn}>
                            {disabledSendBtn ? <DisabledSendIconSvg /> : <MessageSendIconSvg />}
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    )
}
