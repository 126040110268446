import React from "react"

import useMediaQuery from "common/hooks/use-media-query"
import { withTranslation } from "common/utils/lang"

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { Resource } from "main-app/models/resource"
import ArrowRightMobile from "main-app/svgs/ArrowRightMobile"
import ArrowRightNav from "main-app/svgs/ArrowRightNav"

interface IProps {
    resource: Resource
}

const ResourceLink: React.FC<IProps> = ({ resource }) => {
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    return (
        <li className="resources-item ">
            <a
                href={resource.url}
                target="_blank"
                className="align-items-start justify-content-md-start justify-content-center"
                rel="noreferrer">
                <span className="resources-arrow mr-20 d-none d-md-block">
                    <ArrowRightNav />
                </span>
                <span className="resources-arrow d-block d-md-none mt-62-mobile">
                    <ArrowRightMobile />
                </span>
                <span className="mr-20 fs-16-regular font-weight-light">
                    {withTranslation(resource.name)}{" "}
                    <span
                        className="resources-item__source"
                        style={matches ? { display: "block" } : { display: "inline" }}>
                        {resource.source}
                        {resource.duration ? `, ${resource.duration}` : ""}
                    </span>
                </span>
            </a>
        </li>
    )
}

export default React.memo(ResourceLink)
