import React, { useState } from "react"

import classNames from "classnames"
import { DateTime } from "luxon"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Trash from "main-app/svgs/Trash"

import TrackPracticeTextAreaDesktop from "./TrackPracticeTextAreaDesktop"

interface IProps {
    rowIndex: number
    rowsLength: number
    onAddRowsFocus: boolean
    onDeleteRow: (index: number) => void
}

const TrackPracticeBoxes: React.FC<IProps> = ({ rowIndex, rowsLength, onDeleteRow, onAddRowsFocus }) => {
    const { t } = useTranslation()
    const { control, getValues, register } = useFormContext()

    const [focused, setFocused] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(null)

    const { fields } = useFieldArray({
        control,
        name: `rows[${rowIndex}].columns`,
        keyName: "column_uuid"
    })

    const date = DateTime.now().toFormat("LLL. dd, yyyy")

    const onFocus = (index: number) => {
        setFocused(true)
        setFocusedIndex(index)
    }

    const onBlur = () => {
        setFocused(false)
        setFocusedIndex(null)
    }

    return (
        <div className="track-row-item">
            {rowsLength > 1 && (
                <button className="trash" onClick={() => onDeleteRow(rowIndex)}>
                    <Trash />
                </button>
            )}
            <div className={classNames("track-row-item-header", { focus: focused && rowIndex === focusedIndex })}>
                {date}
            </div>
            <div className="track-practice-box-input-container">
                {fields.map((field, index) => (
                    <React.Fragment key={field.column_uuid}>
                        <TrackPracticeTextAreaDesktop
                            name={`rows.${rowIndex}.columns.${index}.answer`}
                            register={register}
                            control={control}
                            onBlur={onBlur}
                            hasFocus={rowIndex === rowsLength - 1 && index === 0 && onAddRowsFocus}
                            className="comment"
                            onFocus={() => onFocus(rowIndex)}
                            placeholder={
                                getValues(`rows.${rowIndex}.columns.${index}.typing_prompt`) || t("Start typing...")
                            }
                        />
                        {index !== fields.length - 1 && <div className="line" />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default TrackPracticeBoxes
