import React, { startTransition, useLayoutEffect } from "react"

import { type NavigateFunction, useNavigate, useParams } from "react-router-dom"

import useMediaQuery from "common/hooks/use-media-query"

import LeftSidebar from "main-app/components/dashboard/LeftSidebar"
import { dashboardURL } from "main-app/components/onboarding/constants"
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { SimulabContextProvider, SimulabHeader } from "main-app/entities/simulab"
import { SimulabCareLink } from "main-app/features/simulab-chat"
import ParticipantLayout from "main-app/layouts/ParticipantLayout"
import { Simulab } from "main-app/widgets/simulab"

import "./styles.scss"

export const SimulabChatPage = () => {
    const { user } = useAuthContext()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const params = useParams()

    const navigate: NavigateFunction = useNavigate()

    useLayoutEffect(
        (): void => (!user.practiceLab && startTransition((): void => navigate(dashboardURL)), void 0),
        [user, navigate]
    )

    return (
        <SimulabContextProvider conversationId={params.conversationId}>
            <ParticipantLayout
                className="simulab-layout"
                noPadding
                showLogoOnMobile={false}
                leftSidebar={<LeftSidebar closedMenu />}
                title={
                    !isMobile && (
                        <SimulabHeader showCoacheesLink withFeedbackReport withTooltip statusTagVariant="outlined" />
                    )
                }
                header={isMobile && <SimulabHeader withFeedbackReport withTooltip statusTagVariant="outlined" />}>
                <Simulab />
                <SimulabCareLink />
            </ParticipantLayout>
        </SimulabContextProvider>
    )
}
