import { SessionScheduleStatus } from "main-app/constants"
import { CohortModalities } from "main-app/models/types"

import { isIndidualCoachSession } from "./gates/session"

export const findThreeWaySession = sessions => sessions.find(session => session.is_three_way_session)

export const findSessionToSchedule = (sessions, threeWaySession) =>
    sessions.find(session => session.session_id !== threeWaySession?.session_id && !session.is_three_way_session)

export const getNotScheduledSessions = sessions =>
    sessions?.upcoming_sessions.filter(
        session =>
            !session.session_time &&
            session.event_status === SessionScheduleStatus.NOT_SCHEDULED &&
            (session.cohort_modality === CohortModalities.Individual || isIndidualCoachSession(session))
    ) ?? []
