import React, { useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"

import Heading from "common/components/Heading/Heading"
import useMediaQuery from "common/hooks/use-media-query"

import LeftSidebar from "main-app/components/dashboard/LeftSidebar"
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { OldBeliefGoal } from "main-app/entities/old-belief-goal"
import ParticipantLayout from "main-app/layouts/ParticipantLayout"
import { BehavioralGoals } from "main-app/widgets/behavioral-goal/ui/BehavioralGoal"
import CoachingGoal from "main-app/widgets/coaching-goal/ui/CoachingGoal"

import { GoalsWrapper } from "./ui/GoalsWrapper"

import "./ui/styles.scss"

export const CoachingPlanPage = () => {
    const { t } = useTranslation()

    const { user } = useAuthContext()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const [isToggledOldBelief, setIsToggledOldBelief] = useState(isMobile)

    if (!user?.availableCoachingPlan) {
        return <Navigate to="/" replace />
    }

    return (
        <ParticipantLayout
            leftSidebar={<LeftSidebar />}
            className="goals-layout"
            title={
                <Heading textAlign="center" fontSize={36} className="mb-0">
                    {t("Coaching Plan")}
                </Heading>
            }>
            <GoalsWrapper>
                <div className={classNames("goals-grid", { toggled: isToggledOldBelief })}>
                    <section className={classNames("goals-main", { expanded: isToggledOldBelief })}>
                        <CoachingGoal />
                        <div className="mb-50" />
                        <BehavioralGoals />
                    </section>

                    <aside className={classNames("goal-aside", { toggled: isToggledOldBelief })}>
                        <OldBeliefGoal
                            toggle={() => setIsToggledOldBelief(!isToggledOldBelief)}
                            isToggled={isToggledOldBelief}
                        />
                    </aside>
                </div>
            </GoalsWrapper>
        </ParticipantLayout>
    )
}
