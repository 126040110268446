import React, { type FC, type ReactElement } from "react"

import { CoachRelinkAccountFallbackSpinner as Spinner } from "."

import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"

import Button from "main-app/shared/button/Button"

import type { THandleRefreshPageButtonClickFunction } from "../types"

type TCoachRelinkAccountsConfirmationBoxProps = {
    handleRefreshPage: THandleRefreshPageButtonClickFunction
    shouldRefreshButtonBeLoading: boolean
}

const CoachRelinkAccountsConfirmationBox: FC<TCoachRelinkAccountsConfirmationBoxProps> = ({
    handleRefreshPage,
    shouldRefreshButtonBeLoading
}: TCoachRelinkAccountsConfirmationBoxProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="coach-relink-accounts__confirmation-box">
            <p className="font-extrabold m-0 text-center">
                {t("coachSide.relinkAccountsPage.confirmationBlock.subTitle")}
            </p>
            <p className="mb-5 text-center">{t("coachSide.relinkAccountsPage.confirmationBlock.description")}</p>
            <Button
                className="font-extrabold"
                disabled={shouldRefreshButtonBeLoading}
                variant={shouldRefreshButtonBeLoading ? "disabled" : "brand"}
                onClick={handleRefreshPage}>
                {shouldRefreshButtonBeLoading ? (
                    <Spinner />
                ) : (
                    t("coachSide.relinkAccountsPage.confirmationBlock.button.title")
                )}
            </Button>
        </div>
    )
}

export { CoachRelinkAccountsConfirmationBox, type TCoachRelinkAccountsConfirmationBoxProps }
