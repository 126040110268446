import React from "react"

import ReactDOM from "react-dom"

import { MAIN_PORTAL_ID } from "main-app/constants"

interface IProps {
    withPortal?: boolean
    portalId?: string
    children?: React.ReactNode
}

const Portal = ({ withPortal = true, children, portalId = MAIN_PORTAL_ID }: IProps): JSX.Element => {
    const domElement = document.getElementById(portalId)
    return withPortal ? ReactDOM.createPortal(children, domElement) : children ? <>{children}</> : null
}

export default Portal
