import React, { DetailedHTMLProps, HTMLAttributes, useRef } from "react"

import classNames from "classnames"

import ThreeDotsMenu from "../menu/ThreeDotsMenu"

import "./styles.scss"

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type Props = {
    className?: string
    withMenu?: boolean
    onMenuClick?: () => void
    showMenu?: boolean
    children: React.ReactNode
    readOnly?: boolean
} & DivProps

const Card = ({
    children,
    className = "",
    withMenu,
    onMenuClick,
    showMenu = withMenu,
    readOnly = false,
    ...rest
}: Props) => {
    const cardRef = useRef()

    return (
        <div className={classNames("card-box", className, { "show-hover": !readOnly })} {...rest} ref={cardRef}>
            {children}
            {withMenu && showMenu && !readOnly && (
                <div className="menu-threedots">
                    <ThreeDotsMenu onClick={onMenuClick} />
                </div>
            )}
        </div>
    )
}

export default Card
