import React, { useMemo } from "react"

import { ComponentType } from "main-app/constants"

import SummaryBlock from "./SummaryBlock"

interface IProps {
    component: any
}

const Summary: React.FC<IProps> = ({ component }) => {
    const summary = useMemo(() => {
        return component?.show_summary_after_finish
            ? // eslint-disable-next-line array-callback-return
              component.summary_list.find(summary => {
                  if (
                      component.answer_data?.rank >= +summary.rank_from &&
                      component.answer_data?.rank <= +summary.rank_to
                  ) {
                      return true
                  }
              }, {})
            : null
    }, [component])

    if (component.type === ComponentType["Question: Likert Group"]) {
        return summary ? (
            <SummaryBlock
                shortSummary={summary.short_summary}
                summary={summary.summary}
                // summarySubtitle={summary.summary_subtitle}
            />
        ) : null
    }

    return (
        <>
            {component?.show_summary_after_finish ? (
                <SummaryBlock
                    summary={component?.summary}
                    shortSummary={component?.short_summary}
                    // summarySubtitle={component?.summary_subtitle}
                />
            ) : null}
        </>
    )
}

export default Summary
