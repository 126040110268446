import React, { memo } from "react"

type Props = {
    className?: string
}

const ActiveCheckMarkSvg = ({ className }: Props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <rect width="20" height="20" rx="10" fill="#FD4D00" />
            <path
                d="M6.28261 10.1033L8.55435 12.5815L13.7174 7.41849"
                stroke="white"
                strokeWidth="1.78125"
                strokeLinecap="square"
            />
        </svg>
    )
}

export default memo(ActiveCheckMarkSvg)
