import React, { useCallback, useState } from "react"

import { AxiosError } from "axios"
import { useTranslation } from "react-i18next"
import { CSSTransition } from "react-transition-group"

import Heading from "common/components/Heading/Heading"
import { isNullOrUndefined } from "common/utils/gates"
import { getErrorMessages } from "common/utils/get-error-messages"

import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage"
import { useAuthContext } from "main-app/context/Auth"
import { useSimulabContext } from "main-app/entities/simulab"
import { useSendSimulabFeedback } from "main-app/entities/simulab/api"
import Button from "main-app/shared/button/Button"
import { DynamicHeightTransition } from "main-app/shared/lib/components/DynamicHeightTransition"
import Modal from "main-app/shared/modal"

import "../styles.scss"

import { SimulabFeedbackForm } from "./SimulabFeedbackForm"

export const SimulabFeedbackModal = () => {
    const { t } = useTranslation()
    const { user } = useAuthContext()
    const { report, setReportData } = useSimulabContext()
    const sendFeedback = useSendSimulabFeedback()
    const [feedbackSent, setFeedbackSent] = useState(false)
    const [showFeedbackAnimation, setShowFeedbackAnimation] = useState(false)
    const [error, setError] = useState(null)

    const onClose = useCallback(() => {
        setReportData(null)
    }, [setReportData])

    const onSubmit = useCallback(values => {
        const submitData = {
            text: values.feedback,
            severity: values.severity,
            context: report.type
        }

        if ("message_id" in report) {
            submitData.message_id = report.message_id
        }

        sendFeedback.mutate(submitData, {
            onSuccess() {
                setFeedbackSent(true)
            },
            onError(error: AxiosError) {
                setError(getErrorMessages(error))
            }
        })
    }, [])

    const handleFormExit = () => {
        if (feedbackSent) {
            setTimeout(() => {
                setShowFeedbackAnimation(true)
            }, 600)
        }
    }

    const onDoneFeedbackClick = useCallback(() => {
        setReportData(null)
    }, [setReportData])

    return (
        <Modal
            show={!isNullOrUndefined(report)}
            onClose={onClose}
            modalBodyClass="simulab-report-modal"
            headerContent={
                <div className="p-1">
                    <Heading tag="h2" className="text-center m-0" fontSize={36}>
                        {t("Beta Feedback")}
                    </Heading>
                </div>
            }>
            <DynamicHeightTransition>
                <section className="simulab-report-modal__body">
                    <CSSTransition
                        in={!feedbackSent}
                        timeout={600}
                        classNames="simulab-report-modal__form-animation"
                        onExit={handleFormExit}
                        unmountOnExit>
                        <div>
                            <SimulabFeedbackForm report={report} user={user} onSubmit={onSubmit} />
                            <WarningMessage message={error} />
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        in={showFeedbackAnimation}
                        timeout={600}
                        classNames="simulab-report-modal__feedback-animation"
                        unmountOnExit>
                        <div className="text-center fs-sm-14">
                            <p className="m-0 font-extrabold">{t("Thank you for your feedback!")}</p>
                            <p className="mb-40">{t("It helps us improve the experience for everyone.")}</p>
                            <Button onClick={onDoneFeedbackClick} className="fs-16">
                                {t("Done")}
                            </Button>
                        </div>
                    </CSSTransition>
                </section>
            </DynamicHeightTransition>
        </Modal>
    )
}
