import React from "react"

import "main-app/assets/header.scss"
import WiserLogo from "main-app/svgs/WiserLogo"

const Header = () => {
    return (
        <header className="header">
            <WiserLogo width={26} height={26} />
        </header>
    )
}

export default Header
