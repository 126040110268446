import React, { useEffect, useRef } from "react"

import classNames from "classnames"
import { t } from "i18next"

import { useKeyPress } from "common/hooks/use-key-press"
import { useOnClickOutside } from "common/hooks/use-outside-click"

import { MAIN_PORTAL_ID } from "main-app/constants"
import CloseIconSvg from "main-app/svgs/CloseIconSvg"

import Portal from "../portal/Portal"

import "./styles.scss"

interface IProps {
    modalBodyClass?: string
    show: boolean
    enableOutSideClick?: boolean
    disableBodyScroll?: boolean
    headerContent?: React.ReactNode | null
    closeOnEscapePress?: boolean
    withPortal?: boolean
    portalId?: string
    showCloseIcon?: boolean
    onClose: () => void
    modalOpenBtnLabel?: string
    children?: React.ReactNode
}

const Modal: React.FC<IProps> = ({
    children,
    show,
    onClose,
    modalBodyClass,
    enableOutSideClick = true,
    disableBodyScroll = false,
    headerContent = null,
    closeOnEscapePress = true,
    withPortal = true,
    showCloseIcon = true,
    portalId = MAIN_PORTAL_ID,
    modalOpenBtnLabel = ""
}) => {
    const modalBodyRef = useRef<HTMLDivElement | null>(null)
    const openModalBtn = document.querySelector(`[aria-label="${modalOpenBtnLabel}"]`) as HTMLButtonElement

    useEffect(() => {
        if (show && disableBodyScroll) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "unset"
        }

        return () => {
            document.body.style.overflow = "unset"
        }
    }, [show, disableBodyScroll])

    const handleOnClick = () => {
        onClose()
        openModalBtn?.focus()
    }

    useKeyPress(() => show && closeOnEscapePress && handleOnClick(), ["Escape"])

    useOnClickOutside(modalBodyRef, () => {
        if (enableOutSideClick && show) {
            handleOnClick()
        }
    })

    useEffect(() => {
        if (show) {
            modalBodyRef.current?.focus()
        }
    }, [show])

    return (
        <>
            {show && (
                <Portal withPortal={withPortal} portalId={portalId}>
                    <div className={classNames("modal__wrapper", { show })}>
                        <div
                            className={classNames("modal__body", { show, [modalBodyClass || ""]: modalBodyClass })}
                            role="dialog"
                            ref={modalBodyRef}
                            tabIndex={0}>
                            {headerContent ? (
                                <div className="modal__header">
                                    {headerContent}
                                    {showCloseIcon && (
                                        <button
                                            type="button"
                                            aria-label={t("Close button")}
                                            className="modal__header-close-btn"
                                            data-testid="close-btn"
                                            onClick={handleOnClick}>
                                            <CloseIconSvg />
                                        </button>
                                    )}
                                </div>
                            ) : null}
                            {children}
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}

export { type IProps as IModalProps, Modal as default }
