import React, { FunctionComponent, ReactElement, useRef, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { ITrackPractice } from "Manager/chapter/models/types"

import TrackPracticeDescriptionBlock from "./TrackPracticeDescriptionBlock"

type TProps = {
    component?: ITrackPractice
    className?: string
}

const TrackPracticeAssignmentSummary: FunctionComponent<TProps> = ({ component, className }: TProps): ReactElement => {
    const [isActive, showIsActive] = useState(false)
    const contentRef = useRef<HTMLDivElement>()

    const toggleAccordion = () => {
        showIsActive(prev => !prev)
    }

    const { t } = useTranslation()

    return (
        <div {...(className && { className })}>
            <div
                className={classNames(
                    "hide_assignment d-flex align-items-center justify-content-center cursor-pointer",
                    { active: isActive }
                )}
                onClick={toggleAccordion}>
                <p className="font-sm font-extrabold">
                    {isActive ? t("Hide") : t("Show")} {t("Practice Assignment")}
                </p>
                <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.75 6.625L6.5 2.375L2.25 6.625"
                        stroke="#FD4D00"
                        strokeWidth="2"
                        strokeLinecap="square"
                    />
                </svg>
            </div>
            <div
                className="accordion-box"
                ref={contentRef}
                style={isActive ? { height: `${contentRef.current?.scrollHeight}px` } : { height: "0px" }}>
                <TrackPracticeDescriptionBlock component={component} showButton={false} size="sm" />
            </div>
        </div>
    )
}

export { TrackPracticeAssignmentSummary }
