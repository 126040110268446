import React, { useMemo } from "react"

import { SimulabStage, useSimulabContext } from "main-app/entities/simulab"
import HtmlContent from "main-app/shared/html-content/HtmlContent"
import LockSvgGray from "main-app/svgs/LockSvgGray"

import { ActiveStageItem } from "./ActiveStageItem"

import "./styles.scss"

type Props = {
    stage: SimulabStage
    showBubbleArrow?: boolean
}

export const StageItem = ({ stage, showBubbleArrow = true }: Props) => {
    const { simulabMessages } = useSimulabContext()

    const stageHasMessages = useMemo(() => {
        return simulabMessages?.messages?.some(message => message.stageId === stage.id)
    }, [simulabMessages])

    const isActiveOrCompleted = stage.completed || stage.current || stageHasMessages

    return (
        <div className="stage">
            {isActiveOrCompleted ? (
                <ActiveStageItem stage={stage} showBubbleArrow={showBubbleArrow} />
            ) : (
                <p className="fs-14 color-gray mb-10">
                    <HtmlContent
                        content={`${stage.title} (${stage.phase})`}
                        renderTag="span"
                        attributes={{ allowedTags: [] }}
                    />{" "}
                    {!stage.completed && !stage.current && !stageHasMessages ? (
                        <LockSvgGray width={12} height={12} />
                    ) : null}
                </p>
            )}
        </div>
    )
}
