import { GoalsSortType } from "main-app/shared/types/sort"

import {
    useArchiveCoachingGoal,
    useArchivedCoachingGoalData,
    useCoachingGoalData,
    useCreateCoachingGoal,
    useEditCoachingGoal,
    useSortCoachingGoals,
    useUnarchiveCoachingGoal
} from "../api/coaching-goal.api"

type Options = {
    userId: number
    sort: GoalsSortType
}

export function useCoachingGoalApi({ userId, sort }: Options) {
    const {
        data: coachingGoal,
        isLoading: isLoadingCoachingGoal,
        error: coachingGoalError
    } = useCoachingGoalData(userId, sort)
    const { data: archivedGoals, error: archiveError } = useArchivedCoachingGoalData(userId)
    const createCoachingGoal = useCreateCoachingGoal(userId)
    const editCoachingGoal = useEditCoachingGoal(userId)
    const archiveGoal = useArchiveCoachingGoal(userId)
    const unarchiveGoal = useUnarchiveCoachingGoal(userId)
    const sortGoals = useSortCoachingGoals(userId)

    return {
        coachingGoal,
        isLoadingCoachingGoal,
        coachingGoalError,
        archivedGoals,
        archiveError,
        createCoachingGoal,
        editCoachingGoal,
        archiveGoal,
        unarchiveGoal,
        sortGoals
    }
}
