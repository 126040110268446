import { Role } from "main-app/constants"
import User from "main-app/models/user"

const isParticipant = (user: User) => user?.role === Role.Participant

const isCoach = (user: User) => user?.role === Role.Coach

const isProgramManager = (user: User) => user?.role === Role.ProgramManager

export { isParticipant, isCoach, isProgramManager }
