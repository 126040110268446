import React from "react"

import { useTranslation } from "react-i18next"

import { withTranslation } from "common/utils/lang"

import { sanitizeContent } from "main-app/utils/common"

import { ChapterComponent, IContent } from "Manager/chapter/models/types"

import NextButton from "./NextButton"

interface IProps {
    component: IContent
    onNext: (component: ChapterComponent) => void
    isLast: boolean
    disabledBtn: boolean
}

const Content: React.FC<IProps> = ({ component, onNext, isLast = false, disabledBtn }) => {
    const { t } = useTranslation()
    const onClick = () => {
        onNext(component)
    }

    return (
        <div className="container--phase">
            <div className="mt-3 mt-lg-0 px-3 px-lg-0">
                <h2 className="h5 font-extrabold text-uppercase color-brand text-center text-lg-left">{t("LEARN")}</h2>
                <h3 className="h2 font-extrabold font-italic text-center text-lg-left">
                    {withTranslation(component?.title)}
                </h3>
                <div
                    className="content-text"
                    dangerouslySetInnerHTML={{ __html: sanitizeContent(withTranslation(component?.content)) }}
                />
                <div className="text-center my-4.5">
                    <NextButton isLast={isLast} onClick={onClick} disabled={disabledBtn} />
                </div>
            </div>
        </div>
    )
}

export default Content
