import React, { ReactElement, useRef } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import useMediaQuery from "common/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA, ParticipantUrls } from "main-app/constants"
import Button from "main-app/shared/button/Button"
import { BetaStatusTag } from "main-app/shared/tags/BetaStatusTag"
import { Tooltip } from "main-app/shared/tooltip/Tooltip"
import { Chevron, WiserLogo } from "main-app/svgs"

import { useSimulabContext } from "../context/SimulabContextProvider"
import { FeedbackTypes } from "../models"

import "./styles.scss"

type Props = {
    withFeedbackReport?: boolean
    withTooltip?: boolean
    statusTagVariant?: "outlined" | "primary"
    showCoacheesLink?: boolean
    showPracticeLink?: boolean
    showLogoOnMobile?: boolean
    handleOnClickShowPracticeLink?(): void
    renderAssignmentSummary?(): ReactElement
}

export const SimulabHeader = ({
    withFeedbackReport = false,
    statusTagVariant = "primary",
    withTooltip = false,
    showCoacheesLink = false,
    showPracticeLink = false,
    showLogoOnMobile = false,
    handleOnClickShowPracticeLink = undefined,
    renderAssignmentSummary = undefined
}: Props) => {
    const { t } = useTranslation()
    const buttonRef = useRef(null)
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { setReportData } = useSimulabContext()

    const onBetaStatusClick = () => {
        if (withFeedbackReport) {
            setReportData({ type: FeedbackTypes.PRACTICE_LAB_GENERAL })
        }
    }

    return (
        <>
            <div className={classNames("simulab-header", { "with-link": showCoacheesLink || showPracticeLink })}>
                {showCoacheesLink && (
                    <Link to={ParticipantUrls.COACHEES} className="d-flex align-items-center">
                        <Chevron position="left" className="mr-1" /> <span>{t("Back to Coachees")}</span>
                    </Link>
                )}
                {showPracticeLink && (
                    <div onClick={handleOnClickShowPracticeLink} className="show-practice-link">
                        <Chevron position="left" className="mr-1" />{" "}
                        <span>{t("chapter.trackPractice.simulabChat.backToPractice")}</span>
                    </div>
                )}
                {isMobile && !showLogoOnMobile && <WiserLogo width={26} height={34} />}
                <div
                    className={classNames("d-flex align-items-center", {
                        "mx-auto": showCoacheesLink || showPracticeLink
                    })}>
                    <span className="simulab-header__text">Wiser SimuLab</span>
                    <Button variant="default" className="p-0" ref={buttonRef} onClick={onBetaStatusClick}>
                        <BetaStatusTag
                            size={isMobile ? "s" : "m"}
                            variant={statusTagVariant}
                            className="cursor-pointer"
                        />
                    </Button>
                </div>
                {withTooltip && (
                    <Tooltip targetRef={buttonRef} position="bottom-end" offset={{ x: 0, y: 5 }}>
                        <span className="color-gray fs-12 font-weight-normal">{t("Submit beta feedback")}</span>
                    </Tooltip>
                )}
            </div>
            {renderAssignmentSummary?.()}
        </>
    )
}
