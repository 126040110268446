import React from "react"

import classNames from "classnames"

import "./styles.scss"

interface IProps {
    id: string
    checked: boolean
    label: string
    name?: string
}

const QuestionOptions: React.FC<IProps> = ({ checked, id, label, name = "" }) => {
    return (
        <div className="question-option">
            <input
                type="radio"
                name={name}
                className={classNames({
                    checked
                })}
                id={id}
                disabled
                checked={checked}
            />
            <label htmlFor={id}>
                <span>{label}</span>
            </label>
        </div>
    )
}

export default QuestionOptions
