import React, { useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { useThreeWayForm } from "../hooks/use-three-way-form"
import { ThreeWayManager } from "../model/three-way"

import { ThreeWayAdd } from "./ThreeWayAdd"
import { ThreeWayForm } from "./ThreeWayForm"

import "./styles.scss"

type Props = {
    sessionId?: number
    className?: string
    threeWayManager: ThreeWayManager | null
}

export const ThreeWay = ({ sessionId, className, threeWayManager }: Props) => {
    const { t } = useTranslation()
    const [showFields, setShowFields] = useState(false)
    const { error, isUpdating, isDirty, register, onUpdateFields, fieldValues, noValues, isSubmitSuccessful } =
        useThreeWayForm({
            threeWayManager,
            sessionId,
            setShowFields
        })
    const isActiveBtn = !noValues || isDirty

    return (
        <section className={classNames("three-way", className)}>
            <div className="text-center">
                <h6 className="mb-1 tree-way-form__heading">{t("This is a three-way session!")}</h6>
                <p className="mb-20 tree-way-form__title">
                    {t("Select a time when both you and your manager are free.")}
                </p>
                <div className="tree-way__form">
                    <p className="font-sm">
                        {isSubmitSuccessful
                            ? t("threeWaySessionModal.form.title.submitted")
                            : t("Please add your manager’s details. They will receive their session invite via email.")}
                    </p>
                    {!showFields ? (
                        <ThreeWayAdd fieldValues={fieldValues} onAddClick={() => setShowFields(true)} />
                    ) : (
                        <ThreeWayForm
                            threeWayManager={threeWayManager}
                            error={error}
                            isUpdating={isUpdating}
                            register={register}
                            onUpdateFields={onUpdateFields}
                            isActiveBtn={isActiveBtn}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}
