import React, { useMemo } from "react"

import classNames from "classnames"

import "./styles.scss"

interface IProps {
    max: number
    current: number
    title: string
    onClick?: () => void
    active?: boolean
    summaryValue: string
    className?: string
}

const ResultsBar: React.FC<IProps> = ({
    max,
    current,
    title,
    onClick,
    active = false,
    className = "",
    summaryValue
}) => {
    const computedWidth = useMemo(() => {
        return Math.round((current / max) * 100)
    }, [max, current])

    return (
        <div className={classNames("result-bar", { [className]: className })} onClick={onClick}>
            <span className="result-bar__title">
                {title} - {summaryValue}
            </span>
            <span
                className={classNames("result-bar__progress", { active, "is-full": computedWidth >= 100 })}
                style={{ width: `${computedWidth}%` }}
            />
        </div>
    )
}

export default ResultsBar
