import React, { ReactNode } from "react"

import "./styles.scss"

type Props = {
    children?: ReactNode
}

const ErrorTooltip = ({ children }: Props) => {
    return <div className="error-tooltip">{children}</div>
}

export default ErrorTooltip
