import React from "react"

import classNames from "classnames"
import { useLocation } from "react-router-dom"

import useIsMobileLandscape from "common/hooks/use-is-mobile-landscape"

import "main-app/assets/footer.scss"

const Footer = () => {
    const { pathname } = useLocation()
    const isLandscape = useIsMobileLandscape()

    return (
        <footer className={classNames("footer", { "is-landscape": isLandscape && pathname.includes("/register") })} />
    )
}

export default Footer
