import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { ServerStateKeys } from "main-app/constants"

const getOffboarding = async () => {
    const { data } = await http.get(Urls.offboardingJourneyReflection())
    return data
}

export default function useOffBoarding() {
    return useQuery([ServerStateKeys.Offboarding], getOffboarding, {
        select: useCallback(data => {
            return data
        }, [])
    })
}
