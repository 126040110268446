import { useCallback, useEffect, useMemo } from "react"

import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"

import { http } from "common/http"
import { isNullOrUndefined } from "common/utils/gates"

import Urls from "main-app/api/urls"
import { useAuthContext } from "main-app/context/Auth"

import { useCalendarProfiles } from "../api/use-calendar-profiles"
import { CalendarPrimaryFormValues } from "../types"

export function useCalendarLinkSetting() {
    const { user } = useAuthContext()
    const profiles = useCalendarProfiles(!isNullOrUndefined(user?.calendar_setup_confirmed))

    const calendars = useMemo(() => {
        return profiles?.data?.reduce((values, calendar) => {
            calendar.profileCalendars.forEach(
                profile => (values[profile.calendarId] = profile.isCoachCalendarAvailable)
            )
            return values
        }, {})
    }, [profiles?.data])

    const methods = useForm<CalendarPrimaryFormValues>({
        resolver: yupResolver(
            yup.object({
                primaryCalendar: yup.string().required("Primary calendar should be selected"),
                calendars: yup
                    .object()
                    .test("at-least-one-truthy", "At least one sub calendar is required", obj =>
                        Object.values(obj).some(value => Boolean(value))
                    )
            })
        )
    })

    useEffect(() => {
        if (profiles?.data) {
            const primaryProfile = profiles?.data?.find(calendar => calendar.coachHasPrimaryCalendarProfile)
            const primaryCalendar = primaryProfile?.profileCalendars?.find(profile => profile.isCalendarPrimary)
            const values = {
                calendars,
                primaryCalendar: primaryCalendar?.calendarId
            }
            methods.reset(values)
        }
    }, [profiles?.data, calendars])

    const onSubmit = useCallback(
        async (values: CalendarPrimaryFormValues) => {
            const primaryCalendar = profiles?.data?.find(item =>
                item.profileCalendars.some(calendar => calendar.calendarId === values.primaryCalendar)
            )

            const calendarIds = Object.entries(values.calendars)
                .filter(([key, value]) => key !== "primaryCalendar" && value)
                .map(([key]) => key)

            const submitData = {
                profile_id: primaryCalendar?.profileId,
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                sync_calendar_ids: calendarIds,
                primary_calendar_id: values.primaryCalendar
            }

            return http.post(Urls.setPrimaryCalendar(), submitData)
        },
        [profiles?.data]
    )

    return { hookForm: methods, api: profiles, onSubmit }
}
