import { useCallback } from "react"

import { type QueryFunction, type QueryObserverOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { ServerStateKeys } from "main-app/constants"

interface IAvailableGuestSessionModel {
    id: number
    startTime: string
    endTime: string
}

interface IAvailableGuestSessionDto {
    id: number
    start: string
    end: string
}

type TUseAvailableGuestSessionsQueryParams = QueryObserverOptions & {
    id?: number
}

type TUseAvailableGuestSessionsQuery = UseQueryResult<IAvailableGuestSessionModel[], AxiosError>

function availableGuestSessionsAdapter(dtos: IAvailableGuestSessionDto[]): IAvailableGuestSessionModel[] {
    return dtos.map(
        ({ end, start, ...rest }: IAvailableGuestSessionDto): IAvailableGuestSessionModel => ({
            ...rest,
            startTime: start,
            endTime: end
        })
    )
}

const getAvailableGuestSessions: QueryFunction<IAvailableGuestSessionDto[], [string, { id: number }]> = async ({
    queryKey
}): Promise<IAvailableGuestSessionDto[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id: originalSessionId }] = queryKey
    const { data } = await http.get(Urls.getAvailableGuestSessions({ originalSessionId }))

    return data
}

function useAvailableGuestSessionsQuery({
    id,
    ...params
}: TUseAvailableGuestSessionsQueryParams): TUseAvailableGuestSessionsQuery {
    return useQuery({
        queryKey: [ServerStateKeys.AvailableGuestSessions, { id }],
        queryFn: useCallback(getAvailableGuestSessions, []),
        select: useCallback(availableGuestSessionsAdapter, []),
        enabled: params.enabled
    })
}

export {
    useAvailableGuestSessionsQuery,
    type IAvailableGuestSessionModel,
    type TUseAvailableGuestSessionsQueryParams,
    type TUseAvailableGuestSessionsQuery
}
