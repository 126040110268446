import React from "react"

import classNames from "classnames"

import "./styles.scss"

interface IProps {
    heigth?: number
    width?: number
    className?: string
}

const GroupIcon: React.FC<IProps> = ({ heigth = 55, width = 55, className = "" }) => {
    return (
        <div style={{ width: `${width}px`, height: `${heigth}px` }} className={classNames("group-icon", className)}>
            <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.0142 8.62877C16.3858 8.62877 18.3286 6.68582 18.3286 4.31438C18.3286 1.94295 16.3858 0 14.0142 0C11.6427 0 9.69984 1.94295 9.69984 4.31438C9.69984 6.68591 11.6427 8.62877 14.0142 8.62877ZM14.0142 1.4286C15.6142 1.4286 16.9 2.71435 16.9 4.31438C16.9 5.91442 15.6143 7.20016 14.0142 7.20016C12.4142 7.20016 11.1284 5.91442 11.1284 4.31438C11.1284 2.71435 12.4142 1.4286 14.0142 1.4286Z"
                    fill="#FD4D00"
                />
                <path
                    d="M5.70107 8.9709C7.4154 8.9709 8.81543 7.57084 8.81543 5.85654C8.81543 4.14224 7.41537 2.74219 5.70107 2.74219C3.98677 2.74219 2.58672 4.14224 2.58672 5.85654C2.58672 7.57084 3.98677 8.9709 5.70107 8.9709ZM5.70107 4.17079C6.644 4.17079 7.38685 4.91364 7.38685 5.85657C7.38685 6.79949 6.644 7.54234 5.70107 7.54234C4.75815 7.54234 4.0153 6.79949 4.0153 5.85657C4.0153 4.91364 4.75815 4.17079 5.70107 4.17079Z"
                    fill="#FD4D00"
                />
                <path
                    d="M22.331 8.9709C24.0453 8.9709 25.4453 7.57084 25.4453 5.85654C25.4453 4.14224 24.0453 2.74219 22.331 2.74219C20.6167 2.74219 19.2166 4.14224 19.2166 5.85654C19.2166 7.57084 20.6167 8.9709 22.331 8.9709ZM22.331 4.17079C23.2739 4.17079 24.0167 4.91364 24.0167 5.85657C24.0167 6.79949 23.2739 7.54234 22.331 7.54234C21.388 7.54234 20.6452 6.79949 20.6452 5.85657C20.6452 4.91364 21.3881 4.17079 22.331 4.17079Z"
                    fill="#FD4D00"
                />
                <path
                    d="M5.67196 15.2872C6.81484 15.2872 7.58624 15.2301 8.15773 15.1444L8.75772 15.0301V14.3158C8.75772 13.1729 9.12921 12.03 9.81484 11.0014L10.0435 10.6586L9.84349 10.2871C9.01488 8.62987 7.38629 7.57275 5.70054 7.57275C3.15762 7.57275 0.986147 9.85852 0.957575 12.5729C0.957575 15.2872 3.47192 15.2872 5.67197 15.2872L5.67196 15.2872ZM7.35764 13.8015C6.92906 13.8301 6.38617 13.8586 5.67187 13.8586C2.78609 13.8586 2.38608 13.5729 2.38608 12.5729C2.41472 10.6872 3.95764 9.00139 5.70044 9.00139C6.75756 9.00139 7.75758 9.60138 8.38621 10.5729C7.78613 11.5729 7.44329 12.6872 7.35755 13.8015L7.35764 13.8015Z"
                    fill="#FD4D00"
                />
                <path
                    d="M22.3283 15.2872C24.5283 15.2872 27.0427 15.2872 27.0427 12.5729C27.0427 9.85852 24.8713 7.57275 22.3283 7.57275C20.6426 7.57275 18.9854 8.62987 18.1854 10.2871L17.9854 10.6586L18.2139 11.0014C18.6996 11.7157 19.2711 12.8586 19.2425 14.2872V15.0015L19.8425 15.1158C20.414 15.2301 21.1854 15.2872 22.3283 15.2872H22.3283ZM22.3283 9.00136C24.0712 9.00136 25.6141 10.6586 25.6141 12.5729C25.6141 13.5729 25.2141 13.8586 22.3283 13.8586C21.614 13.8586 21.0997 13.8301 20.6711 13.8015C20.614 12.6872 20.2711 11.6015 19.6425 10.5729C20.2712 9.60139 21.2712 9.0014 22.3283 9.0014L22.3283 9.00136Z"
                    fill="#FD4D00"
                />
                <path
                    d="M14.0148 18C17.3006 18 20.7007 18 20.7007 14.3142C20.7007 10.4569 17.6435 7.19971 14.0148 7.19971C10.3862 7.19971 7.32895 10.4569 7.32895 14.3142C7.32895 18 10.729 18 14.0148 18ZM14.0148 8.62831C16.8149 8.62831 19.2721 11.2855 19.2721 14.3142C19.2721 16.1428 18.3006 16.5714 14.0148 16.5714C9.72903 16.5714 8.75756 16.1428 8.75756 14.3142C8.75756 11.2855 11.2148 8.62831 14.0148 8.62831Z"
                    fill="#FD4D00"
                />
            </svg>
        </div>
    )
}

export default GroupIcon
