import React from "react"

import { useTranslation } from "react-i18next"

import { formatShortMonthDay } from "main-app/utils/date"

type Props = {
    previosAnswer: any
}

const DiagnosticPreviousAnswer = ({ previosAnswer }: Props) => {
    const { t } = useTranslation()
    const answeredQuestion = previosAnswer && "answer_created" in previosAnswer && "answer_data" in previosAnswer

    return answeredQuestion ? (
        <div className="previous-diagnostic-answer mb-4">
            <p className="font-extrabold color-gray mb-1">
                {t("My response from {{responseDateTime}}", {
                    responseDateTime: formatShortMonthDay(previosAnswer?.answer_created)
                })}
                :
            </p>
            <span className="font-italic">{previosAnswer?.answer_data?.answer}</span>
        </div>
    ) : null
}

export default DiagnosticPreviousAnswer
