import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "common/http"

import { ConfigKeys, PRODUCT_TYPE_WITH_ENROLLMENTS, ServerStateKeys } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { useStoreContext } from "main-app/context/GlobalStore"
import { ProductType, ProductTypeApi } from "main-app/models/product-type"

import Urls from "./urls"

const getProductType = async () => {
    const { data } = await http.get(Urls.productType())
    return data
}

export default function useSpotCoaching() {
    const { user } = useAuthContext()
    const { setAppInit, appInit, setConfig } = useStoreContext()

    return useQuery<ProductTypeApi, Error, ProductType>([ServerStateKeys.ProductType], getProductType, {
        select: useCallback((data: ProductTypeApi) => new ProductType(data), []),
        onSuccess: data => {
            const isDefaultProductType = PRODUCT_TYPE_WITH_ENROLLMENTS.includes(user.productType || data.internalName)
            setConfig({
                [ConfigKeys.showDashboard]: user.modulesAvailable,
                [ConfigKeys.canApplyModules]: isDefaultProductType,
                [ConfigKeys.hasEnrolledModules]: isDefaultProductType,
                ...data.configs.reduce((configs, item) => {
                    configs[item.title] = item.is_active
                    return configs
                }, {})
            })
            if (!appInit) {
                setAppInit()
            }
        },
        enabled: !!user
    })
}
