import { BONUS_SESSION_RANK, COACHING_SESSION_TYPE_SLUG } from "main-app/constants"
import { Session } from "main-app/models/session"

const isIndidualCoachSession = session => {
    return (
        session.participants_info &&
        !Array.isArray(session.participants_info) &&
        session.participants_info?.participants?.length === 1
    )
}

const isBonusSession = (session: Session) => {
    return (
        session?.sessionType?.slug === COACHING_SESSION_TYPE_SLUG.INDIVIDUAL_BONUS &&
        session?.enrollmentRank === BONUS_SESSION_RANK
    )
}

export { isIndidualCoachSession, isBonusSession }
