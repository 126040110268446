import React, { useCallback, useEffect, useMemo, useRef } from "react"

import useMediaQuery from "common/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA, Role } from "main-app/constants"
import {
    SimulabCoachee,
    SimulabMessage,
    SimulabReport,
    SimulabStage,
    isActiveSimulabMessage
} from "main-app/entities/simulab"
import User from "main-app/models/user"
import Avatar from "main-app/shared/avatar/Avatar"
import WaveLoader from "main-app/shared/spinner/WaveLoader"

import { SimulabChatMessageRow } from "./SimulabChatMessageRow"
import { StageName } from "./StageName"

type Props = {
    stage: SimulabStage
    stageIndex: number
    currentStage: SimulabStage
    messages: SimulabMessage[]
    hasBadMessage: boolean
    user: User
    coachee: SimulabCoachee
    targetMessageId?: number
    isError: boolean
    isUnselectedMessage: boolean
    onFeedbackReportClick: (report: SimulabReport) => void
    onClickMessageRow: (messageId: number) => void
}

export const SimulabChatFeedStage = ({
    stage,
    currentStage,
    stageIndex,
    messages,
    hasBadMessage,
    isError,
    user,
    coachee,
    isUnselectedMessage,
    targetMessageId,
    onClickMessageRow,
    onFeedbackReportClick
}: Props) => {
    const messageRefs = useRef({})
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const hasRelatedMessages = useMemo(() => {
        return messages?.slice(-2)?.some(message => message.stageId === stage.id)
    }, [messages, stage])

    const setMessageRef = useCallback((node, messageId) => {
        if (node) {
            messageRefs.current[messageId] = node
        }
    }, [])

    useEffect(() => {
        let timeout
        if (targetMessageId && messageRefs.current[targetMessageId]) {
            const messageIds = Object.keys(messageRefs.current)
            const behavior = +messageIds[messageIds.length - 1] === targetMessageId ? "auto" : "smooth"
            timeout = setTimeout(() => {
                messageRefs.current[targetMessageId].scrollIntoView({ behavior, block: "center" })
            }, 0)
        }

        return () => clearTimeout(timeout)
    }, [targetMessageId])

    const showStage = stage.completed || stage.current || currentStage?.id === stage.id || hasRelatedMessages

    return (
        <>
            {showStage ? (
                <>
                    {messages?.length > 0 && (
                        <StageName
                            name={stage.title}
                            phase={stage.phase}
                            completed={stage.completed}
                            isCurrentStage={stage.current}
                            isFirstStage={stageIndex === 0}
                        />
                    )}
                    {messages.map((message, index) =>
                        message.stageId === stage.id ? (
                            <React.Fragment key={message.id}>
                                <SimulabChatMessageRow
                                    user={user}
                                    coachee={coachee}
                                    message={message}
                                    isMyMessage={message.authorRole === Role.Participant}
                                    ref={node =>
                                        message?.authorRole === Role.Participant && setMessageRef(node, message.id)
                                    }
                                    isActiveMessage={isActiveSimulabMessage({
                                        message,
                                        messageIndex: index,
                                        messages,
                                        isUnselectedMessage,
                                        selectedMessage: targetMessageId,
                                        badMessage: hasBadMessage
                                    })}
                                    onFeedbackFlagClick={onFeedbackReportClick}
                                    onClickMessageRow={onClickMessageRow}
                                />

                                {message.authorRole === Role.Participant &&
                                messages.length - 1 === index &&
                                !isError ? (
                                    <div className="simulab-message-loader" data-testid="wave-loader">
                                        <Avatar
                                            url={coachee?.photo}
                                            width={isMobile ? 25 : 30}
                                            height={isMobile ? 25 : 30}
                                            className="mr-2"
                                        />
                                        <WaveLoader />
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : null
                    )}
                </>
            ) : null}
        </>
    )
}
