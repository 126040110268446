import React from "react"

import { useTranslation } from "react-i18next"

import Heading from "common/components/Heading/Heading"
import { withTranslation } from "common/utils/lang"

import QuestionOption from "main-app/elements/question-result-options"
import { sanitizeContent } from "main-app/utils/common"

import Card from "../card/Card"
import CheckBox from "../checkbox/Checkbox"

import "./styles.scss"

type Props = {
    answer: any
    questionNumber: number
    questionsCount: number
}

const LikertResultsItem = ({ answer, questionNumber, questionsCount }: Props) => {
    const { t } = useTranslation()

    const hasNotApplicable = answer => {
        return "not_applicable_input" in answer
    }

    return (
        <div className="likert-results-item">
            <Heading tag="h5" fontSize={16} className="mb-3 font-extrabold">
                {t("Scenario {{questionNumber}} out of {{questionsCount}}", { questionNumber, questionsCount })}
            </Heading>
            <div
                className="mb-40"
                dangerouslySetInnerHTML={{
                    __html: sanitizeContent(answer?.question)
                }}
            />
            {hasNotApplicable(answer) && !answer.option_uuid ? (
                <CheckBox checked label={t("This doesn’t apply to me")} checkMarkVariant="full-fill" />
            ) : (
                <div className="level-choice d-flex justify-content-center">
                    {answer.options?.map(o => (
                        <QuestionOption
                            checked={o.uuid === answer.option_uuid}
                            label={withTranslation(o.response)}
                            id={o.uuid}
                            key={o.uuid}
                        />
                    ))}
                </div>
            )}
            {answer.allow_free_response && (
                <Card readOnly className="likert-results-item__card mt-40">
                    <div className="font-italic color-gray">{answer.free_response}</div>
                </Card>
            )}
            {hasNotApplicable(answer) && !answer.option_uuid && (
                <Card readOnly className="likert-results-item__card mt-40">
                    <div className="font-italic color-gray">{answer?.not_applicable_input}</div>
                </Card>
            )}
        </div>
    )
}

export default LikertResultsItem
