import React, { forwardRef } from "react"

import { useTranslation } from "react-i18next"

import { ANIMATION_LINKS } from "main-app/constants"
import { AnimationPlayer } from "main-app/shared/lib/components/AnimationPlayer"
import { HappyOwlSvg } from "main-app/svgs/HappyOwlSvg"

import "../styles.scss"

type Props = {}

export const SimulabEndConversationLoader = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { t } = useTranslation()

    return (
        <div className="simulab-feedback-loader" ref={ref} data-testid="simualab-feedback-loader">
            <div className="simulab-feedback-loader__animation-box">
                <AnimationPlayer
                    src={ANIMATION_LINKS.CONFETTI}
                    autoplay
                    loop
                    className="simulab-feedback-loader__background"
                />
                <div className="simulab-feedback-loader__icon">
                    <HappyOwlSvg />
                    <div className="simulab-feedback-loader__text">
                        <span>{t("You did it!")}</span>
                    </div>
                </div>
            </div>
            <div className="simulab-feedback-loader__wrapper">
                <div className="position-relative ml-3">
                    <AnimationPlayer
                        src={ANIMATION_LINKS.SPINNER}
                        autoplay
                        loop
                        className="simulab-feedback-loader__spinner"
                        autoResizeCanvas={false}
                    />
                    <p className="mb-0">{t("Generating feedback summary")}...</p>
                    <p className="color-gray fs-14 m-0 font-italic">{t("This may take up to 30 seconds")}</p>
                </div>
            </div>
        </div>
    )
})
