import React, { memo } from "react"

type Props = {
    className?: string
    width?: number
    height?: number
    variant?: "default" | "brand"
}

const WarnIcon = (props: Props) => {
    const { className = "", width = 20, height = 20, variant = "default" } = props

    const variants = {
        default: (
            <svg
                width={width}
                height={height}
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                {...props}>
                <rect x="1" y="1" width="16" height="16" rx="8" stroke="#FD4D00" strokeWidth="2" />
                <path
                    d="M8.44941 10.324L7.88541 4.54H10.1174L9.54141 10.324H8.44941ZM8.02941 13V11.08H9.97341V13H8.02941Z"
                    fill="#FD4D00"
                />
            </svg>
        ),
        brand: (
            <svg
                width={width}
                height={height}
                className={className}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <circle cx={16} cy={16} r={15.5} fill="#fff" />
                <path
                    d="M14.25 18.667V8.933h3.472v9.734H14.25zm3.761 3.142c0 .275-.055.537-.165.785-.097.234-.241.441-.434.62-.18.165-.393.296-.64.393-.249.11-.51.165-.786.165s-.538-.048-.786-.145a1.881 1.881 0 01-.62-.413 2.185 2.185 0 01-.434-.62 1.773 1.773 0 01-.165-.765c0-.261.048-.51.145-.744.11-.248.255-.461.434-.64.179-.18.393-.317.64-.414.248-.11.51-.165.786-.165.275 0 .537.055.785.165.248.097.462.235.64.414.194.165.338.372.435.62.11.234.165.482.165.744z"
                    fill="#FE4D00"
                />
            </svg>
        )
    }

    return variants[variant]
}

export default memo(WarnIcon)
