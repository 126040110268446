import { type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { http } from "common/http"

import Urls from "../urls"

interface IAvailableGuestSessionsMutationParams {
    originalSessionId: number
    swappedSessionId: number
}

interface IAvailableGuestSessionsMutationApiData {
    original_session_id: number
}

type TUseAvailableGuestSessionsMutation = UseMutationResult<
    AxiosResponse<IAvailableGuestSessionsMutationApiData>,
    AxiosError
>

async function postAvailableGuestSessionData({
    originalSessionId,
    swappedSessionId
}: IAvailableGuestSessionsMutationParams): Promise<AxiosResponse> {
    return http.post(Urls.postAvailableGuestSessions({ swappedSessionId }), { original_session_id: originalSessionId })
}

function useAvailableGuestSessionsMutation(): ReturnType<() => TUseAvailableGuestSessionsMutation> {
    return useMutation(postAvailableGuestSessionData)
}

export { useAvailableGuestSessionsMutation, type TUseAvailableGuestSessionsMutation }
