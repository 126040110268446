import { http } from "common/http"
import { getErrorMessages } from "common/utils/get-error-messages"

import Urls from "../urls"

export const makePing = async () => {
    try {
        await http.get(Urls.ping())
    } catch (e) {
        console.log(getErrorMessages(e))
    }
}
