import React from "react"

import ProductTypeLogo from "main-app/shared/ProductTypeLogo"
import Spinner from "main-app/shared/spinner/Spinner"

const PageLoader = () => {
    return (
        <div className="w-100 d-flex flex-column align-items-center" style={{ paddingTop: "8rem" }}>
            <ProductTypeLogo className="mb-3" width={42} heigth={42} />

            <Spinner />
        </div>
    )
}

export default PageLoader
