import React from "react"

import { useTranslation } from "react-i18next"

import Heading from "common/components/Heading/Heading"

import ParticipantLayout from "main-app/layouts/ParticipantLayout"

import LeftSidebar from "../dashboard/LeftSidebar"

type Props = {
    children: React.ReactNode
}

const OffboardingContainer = ({ children }: Props) => {
    const { t } = useTranslation()

    return (
        <ParticipantLayout
            leftSidebar={<LeftSidebar />}
            title={
                <div>
                    <span className="d-block mb-30 text-center color-gray fs-14">{t("End-of-Journey")}</span>
                    <Heading textAlign="center" className="m-0">
                        {t("Final Reflection")}
                    </Heading>
                </div>
            }>
            <div className="container">{children}</div>
        </ParticipantLayout>
    )
}

export default OffboardingContainer
