import { DEFAULT_LIKERT_COLOR_VAR } from "main-app/constants"
import { ColorRange } from "main-app/models/types"

const getColor = (colorIndex, colorRange: ColorRange[]) => {
    const foundColorPallete = colorRange.find(
        colorItem => colorIndex >= colorItem.from_option && colorIndex <= colorItem.to_option
    )
    return foundColorPallete ? foundColorPallete.color : DEFAULT_LIKERT_COLOR_VAR
}

export default getColor
