import React, { ReactNode } from "react"

import { DragDropContext, Droppable } from "react-beautiful-dnd"

type Props = {
    onDragEnd: (result: any) => void
    type: string
    droppableId: string
    direction?: "vertical"
    children: ReactNode
}

const DragDropWrapper = ({ onDragEnd, type, droppableId, direction = "vertical", children }: Props) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={droppableId} direction={direction} type={type}>
                {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DragDropWrapper
