import React, { memo } from "react"

interface IProps {
    height?: number
    width?: number
}
const CheckMarkGray: React.FC<IProps> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#6F6F6F" />
            <path
                d="M5.21191 8.07706L6.91572 9.93576L10.788 6.06348"
                stroke="white"
                strokeWidth="1.33594"
                strokeLinecap="square"
            />
        </svg>
    )
}

export default memo(CheckMarkGray)
