import React, { memo } from "react"

const LockedPhase = (props: React.SVGProps<SVGSVGElement>) => {
    const { width = 24, height = 24, className = "" } = props

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5294 9.94127V6.6811C16.5294 4.38824 14.502 2.52954 12 2.52954C9.49846 2.52954 7.47061 4.39005 7.47061 6.6811V9.94127H6.55174C5.69476 9.94127 5.00004 10.68 5.00004 11.587V19.8249C5.00004 20.7339 5.6943 21.4707 6.55174 21.4707H17.4483C18.3053 21.4707 19 20.732 19 19.8249V11.587C19 10.6781 18.3057 9.94127 17.4483 9.94127H16.5294ZM17 12.0001V19.5001H7V12.0001H17ZM9.5 9.94127V6.6811C9.5 5.33251 10.3784 4.50006 12 4.50006C13.6227 4.50006 14.5 5.33126 14.5 6.6811V9.94127H9.5ZM12.8235 14.7049C12.8235 14.2501 12.458 13.8815 12 13.8815C11.5452 13.8815 11.1765 14.2469 11.1765 14.7049V17.1758C11.1765 17.6305 11.542 17.9992 12 17.9992C12.4548 17.9992 12.8235 17.6337 12.8235 17.1758V14.7049Z"
                fill="#6F6F6F"
            />
        </svg>
    )
}

export default memo(LockedPhase)
