import React, { memo } from "react"

type Props = {
    className?: string
}

const DisabledCrossMarkSvg = ({ className }: Props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <rect width="20" height="20" rx="10" fill="#F3F3F3" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9513 13.2113L12.5811 13.841L13.8406 12.5815L13.2108 11.9517L11.2595 10.0005L13.2118 8.04824L13.8415 7.41847L12.582 6.15894L11.9522 6.7887L10 8.74092L8.04778 6.7887L7.41801 6.15894L6.15848 7.41847L6.78824 8.04824L8.74047 10.0005L6.78918 11.9517L6.15941 12.5815L7.41895 13.841L8.04871 13.2113L10 11.26L11.9513 13.2113Z"
                fill="#6F6F6F"
            />
        </svg>
    )
}

export default memo(DisabledCrossMarkSvg)
