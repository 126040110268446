import React from "react"

import ChapterStart from "main-app/components/chapter/ChapterStart"
import Chapters from "main-app/components/chapter/Chapters"
import CoachingSessions from "main-app/components/coaching-sessions/CoachingSessions"
import Dashboard from "main-app/components/dashboard/Dashboard"
import Diagnostic from "main-app/components/diagnostic/Diagnostic"
import GroupInfo from "main-app/components/group-info/GroupInfo"
import Login from "main-app/components/login/Login"
import Offboarding from "main-app/components/offboarding/Offboarding"
import CoachSelectStep from "main-app/components/onboarding/CoachSelectStep"
import DiagnosticStep from "main-app/components/onboarding/DiagnosticStep"
import DiagnosticResponsesStep from "main-app/components/onboarding/DiagnosticeResponsesStep"
import ModuleListStep from "main-app/components/onboarding/ModuleListStep"
import OnBoardingProfileSetup from "main-app/components/onboarding/OnBoardingProfileSetup"
import OnBoardingRedirect from "main-app/components/onboarding/OnBoardingRedirect"
import ReflectionObjectiveStep from "main-app/components/onboarding/ReflectionObjectiveStep"
import SessionHoldTimesStep from "main-app/components/onboarding/SessionHoldTimesStep"
import VideoStep from "main-app/components/onboarding/VideoStep"
import ParticipantSessions from "main-app/components/participant-session/ParticipantSession"
import ParticipantWorks from "main-app/components/participant-works/ParticipantWorks"
import Profile from "main-app/components/profile/Profile"
import PostJourneyRedirect from "main-app/components/redirect-post-journey/PostJourneyRedirect"
import SetPassword from "main-app/components/set-password/SetPassword"
import WaitingListPage from "main-app/components/waiting-list/WaitingListPage"
import { AuthUrls, CoachUrls, ParticipantUrls } from "main-app/constants"
import GuestParticipant from "main-app/features/guest-participant/ui/GuestParticipant"
import AssignModulePage from "main-app/pages/assign-module/AssignModulePage"
import CoachAvailability from "main-app/pages/coach-calendar-setting/CoachAvailability"
import CoachCalendars from "main-app/pages/coach-calendar-setting/CoachCalendars"
import CoachZoomAccount from "main-app/pages/coach-calendar-setting/CoachZoomAccount"
import CoachDashboard from "main-app/pages/coach-dashboard/CoachDashboard"
import { CoachRatingPage } from "main-app/pages/coach-rating/ui/CoachRatingPage"
import { CoachRelinkAccountsPage } from "main-app/pages/coach-relink-accounts"
import AvailabilityStep from "main-app/pages/coach-settings/AvailabilityStep"
import CalendarAdd from "main-app/pages/coach-settings/CalendarAdd"
import ConfirmAvailabilityStep from "main-app/pages/coach-settings/ConfirmAvailabilityStep"
import LinkAccountStep from "main-app/pages/coach-settings/LinkAccountStep"
import LinkCalendarStep from "main-app/pages/coach-settings/LinkCalendarStep"
import { CoachCalendarStepUrls as CoachCalendarStep, CoachSettingsUrls } from "main-app/pages/coach-settings/constants"
import { CoachingPlanPage } from "main-app/pages/coaching-plan"
import { SimulabChatPage } from "main-app/pages/simulab"
import { SimulabCoachees } from "main-app/pages/simulab-coachees"
import StakeHolderSurveyPage from "main-app/pages/stake-holder-survey/ui/StakeHolderSurveyPage"
import { ErrorPage } from "main-app/shared/error-page/ErrorPage"

import { StepUrls, dashboardURL, onboardingProfileSetupURL } from "../components/onboarding/constants"

type Route = {
    path: string
    Component: React.ComponentType<any>
    order?: number
}

const participantRoutes: Route[] = [
    {
        path: onboardingProfileSetupURL,
        Component: OnBoardingProfileSetup
    },
    {
        path: StepUrls.VIDEO_STEP,
        Component: VideoStep
    },
    {
        path: StepUrls.MODULE_LIST_STEP,
        Component: ModuleListStep
    },
    {
        path: StepUrls.DIAGNOSTIC_STEP,
        Component: DiagnosticStep
    },
    {
        path: StepUrls.DIAGNOSTIC_RESPONSE_STEPS,
        Component: DiagnosticResponsesStep
    },
    {
        path: StepUrls.REFLECTION_OBJECTIVE_STEP,
        Component: ReflectionObjectiveStep
    },
    {
        path: StepUrls.SESSION_HOLD_TIMES_STEP,
        Component: SessionHoldTimesStep
    },
    {
        path: StepUrls.ONBOARDING_REDIRECT_PAGE,
        Component: OnBoardingRedirect
    },
    {
        path: StepUrls.COACH_SELECT_STEP,
        Component: CoachSelectStep
    },
    {
        path: dashboardURL,
        Component: Dashboard
    },
    {
        path: `${dashboardURL}/:moduleId`,
        Component: Dashboard
    },
    {
        path: ParticipantUrls.CHAPTER_LEARN_INIT,
        Component: Chapters
    },
    {
        path: ParticipantUrls.COACH_RATING,
        Component: CoachRatingPage
    },
    {
        path: ParticipantUrls.CHAPTER_LEARN_WELCOME,
        Component: ChapterStart
    },
    {
        path: ParticipantUrls.CHAPTER_LEARN_COMPONENT,
        Component: Chapters
    },
    {
        path: ParticipantUrls.DIAGNOSTIC,
        Component: Diagnostic
    },
    {
        path: ParticipantUrls.WAITING_LIST,
        Component: WaitingListPage
    },
    {
        path: ParticipantUrls.WAITING_LIST_SSO,
        Component: WaitingListPage
    },
    {
        path: ParticipantUrls.SESSION_LIST,
        Component: CoachingSessions
    },
    {
        path: ParticipantUrls.SESSION_SWAPPING,
        Component: GuestParticipant
    },
    {
        path: ParticipantUrls.OFFBOARDING,
        Component: Offboarding
    },
    {
        path: ParticipantUrls.PARTICIPANT_REDIRECT_URL_PAGE,
        Component: PostJourneyRedirect
    },
    {
        path: ParticipantUrls.PROFILE,
        Component: Profile
    },
    {
        path: ParticipantUrls.COACHING_PLAN,
        Component: CoachingPlanPage
    },
    {
        path: ParticipantUrls.COACHEES,
        Component: SimulabCoachees
    },
    {
        path: ParticipantUrls.SIMULAB_CHAT,
        Component: SimulabChatPage
    },
    {
        path: ParticipantUrls.ASSIGN_MODULE,
        Component: AssignModulePage
    },
    {
        path: "/error",
        Component: ErrorPage
    }
]

const coachCalendarSetupRoutes: Route[] = [
    {
        path: CoachCalendarStep.LINK_CALENDAR_STEP,
        Component: LinkCalendarStep,
        order: 1
    },
    {
        path: CoachCalendarStep.LINK_ACCOUNT_STEP,
        Component: LinkAccountStep,
        order: 2
    },
    {
        path: CoachCalendarStep.AVAILABILITY_STEP,
        Component: AvailabilityStep,
        order: 3
    },
    {
        path: CoachCalendarStep.AVAILABILITY_CONFIRM_STEP,
        Component: ConfirmAvailabilityStep,
        order: 4
    }
]

const coachRoutes: Route[] = [
    {
        path: CoachUrls.PARTICIPANTS_LIST,
        Component: CoachDashboard
    },
    {
        path: CoachUrls.SESSIONS_LIST,
        Component: CoachDashboard
    },
    {
        path: CoachUrls.GROUP_LIST,
        Component: CoachDashboard
    },
    {
        path: CoachUrls.PARTICIPANT_WORKS,
        Component: ParticipantWorks
    },
    {
        path: CoachUrls.GROUP_INFO,
        Component: GroupInfo
    },
    {
        path: CoachUrls.PARTICIPANT_COACHING_PLAN,
        Component: CoachingPlanPage
    },
    {
        path: CoachUrls.INDIVIDUAL_SESSIONS,
        Component: ParticipantSessions
    },
    {
        path: CoachCalendarStep.CALENDAR_ADD,
        Component: CalendarAdd
    },
    {
        path: CoachSettingsUrls.CALENDARS,
        Component: CoachCalendars
    },
    {
        path: CoachSettingsUrls.AVAILABILITY,
        Component: CoachAvailability
    },
    {
        path: CoachSettingsUrls.ZOOM_ACCOUNT,
        Component: CoachZoomAccount
    },
    { path: CoachCalendarStep.RELINK_ACCOUNTS, Component: CoachRelinkAccountsPage },
    ...coachCalendarSetupRoutes
]

const authRoutes: Route[] = [
    {
        path: AuthUrls.SET_PASSWORD,
        Component: SetPassword
    },
    {
        path: AuthUrls.RESET_PASSWORD,
        Component: SetPassword
    },
    {
        path: AuthUrls.LOGIN,
        Component: Login
    }
]

const unanothorizedRoutes: Route[] = [
    {
        path: "/survey",
        Component: StakeHolderSurveyPage
    }
]

export { authRoutes, participantRoutes, coachRoutes, unanothorizedRoutes, coachCalendarSetupRoutes }
