import React, { type FC, type ReactElement } from "react"

import { CoachRelinkAccountsContainer } from "."

import SimpleLayout from "main-app/layouts/SimpleLayout"
import DateWidget from "main-app/shared/DateWidget"

import "./CoachRelinkAccounts.scss"

const CoachRelinkAccountsPage: FC = (): ReactElement => (
    <SimpleLayout
        header={<DateWidget className="coach-date-title" />}
        headerVariant="gray"
        showProfilePhoto
        withUserDropdownWithoutMargin>
        <CoachRelinkAccountsContainer />
    </SimpleLayout>
)

export { CoachRelinkAccountsPage }
