import React, {
    type Dispatch,
    type FC,
    type ReactElement,
    type SetStateAction,
    memo,
    startTransition,
    useCallback,
    useDeferredValue,
    useEffect,
    useState
} from "react"

import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import useDebounce from "common/hooks/use-debounce"
import { DEFAULT_PAGINATION_PAGE } from "common/hooks/use-pagination"
import useOnRouteChange from "common/hooks/use-route-change"
import { isEmptyString } from "common/utils/gates"
import { getErrorMessages } from "common/utils/get-error-messages"
import { withTranslation } from "common/utils/lang"

import useGetCoachSessions from "main-app/api/use-coach-sessions"
import useParticipants from "main-app/api/use-participants"
import { CoachDashboardTab, SortKeysSession } from "main-app/constants"
import CoachLayout from "main-app/layouts/CoachLayout"
import { Participant } from "main-app/models/participants"
import { Session } from "main-app/models/session"
import CareTeamLink from "main-app/shared/CareTeamLink"
import DateWidget from "main-app/shared/DateWidget"
import CoachSetupScheduleModal from "main-app/widgets/coach-setup-schedule-modal/ui/CoachSetupScheduleModal"

import Header from "./ui/Header"
import { CoachTable } from "./ui/tables/CoachTable"
import { coachGroupsTableConfig, coachParticipantsTableConfig, coachSessionsTableConfig } from "./ui/tables/config"
import { ECoachTableHeaderName, ECoachTableType, type TDataStateForTable } from "./ui/types"
import { getDataStateForTable, getTableStatusText } from "./ui/utils"

import "./styles.scss"

const CoachDashboard: FC = (): ReactElement => {
    const location = useLocation()
    const [searchValue, setSearchValue] = useState("")
    const [selectedCohort, setSelectCohort] = useState("")
    const [selectedPeriod, setSelectedPeriod] = useState("upcoming_sessions")
    const [sessionTimeOrderSort, setSessionTimeOrderSort] = useState(SortKeysSession.Desc)
    const [selectedTab, setSelectedTab] = useState<CoachDashboardTab>(location.pathname.slice(1) as CoachDashboardTab)
    const debouncedValue = useDebounce(searchValue, 300)
    const [showSetupModal, setShowSetupModal] = useState(false)

    const [currentParticipantsPaginationPage, setCurrentParticipantsPaginationPage]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState<number>(DEFAULT_PAGINATION_PAGE)

    const {
        data: participantsData,
        isLoading: isParticipantsDataLoading,
        isError: isErrorParticipants,
        error: errorParticipants
    } = useParticipants({
        search: debouncedValue,
        cohort: selectedCohort,
        enabled: location.pathname.includes(CoachDashboardTab.PARTICIPANTS),
        page: currentParticipantsPaginationPage
    })
    const isGroupsOrSessionsTab =
        location.pathname.includes(CoachDashboardTab.SESSIONS) || location.pathname.includes(CoachDashboardTab.GROUP)

    const [currentSessionsPaginationPage, setCurrentSessionsPaginationPage]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState<number>(DEFAULT_PAGINATION_PAGE)

    const {
        data: sessionData,
        isFetching: isFetchingSessions,
        isError,
        error
    } = useGetCoachSessions({
        search: debouncedValue,
        order: sessionTimeOrderSort,
        cohort: selectedCohort,
        period: selectedPeriod,
        enabled: isGroupsOrSessionsTab && (isEmptyString(selectedCohort) || !Number.isNaN(parseInt(selectedCohort))),
        page: currentSessionsPaginationPage
    })

    useOnRouteChange(
        useCallback(() => {
            startTransition(() => {
                const tab = location.pathname.slice(1) as CoachDashboardTab
                setSelectCohort("")
                setSelectedTab(tab)
                setCurrentSessionsPaginationPage(DEFAULT_PAGINATION_PAGE)
            })
        }, [location.pathname])
    )

    const changeCurrentParticipantsPaginationPage = useCallback(
        (page: number): void => startTransition((): void => setCurrentParticipantsPaginationPage(page)),
        []
    )

    const changeCurrentSessionsPaginationPage = useCallback(
        (page: number): void => startTransition((): void => setCurrentSessionsPaginationPage(page)),
        []
    )

    const sortBySessionTime = useCallback((order: boolean) => {
        setSessionTimeOrderSort(order ? SortKeysSession.Desc : SortKeysSession.Asc)
    }, [])

    const onSelectSessionPeriod = useCallback((value: string) => {
        setSelectedPeriod(value)
        setCurrentSessionsPaginationPage(DEFAULT_PAGINATION_PAGE)
    }, [])

    const onShowSetupModal = useCallback((value: boolean) => {
        setShowSetupModal(value)
    }, [])

    const deferredSessionsData: Session[] = useDeferredValue(sessionData?.sessions)

    const [sortedParticipantsData, setSortedParticipantsData]: [
        Participant[],
        Dispatch<SetStateAction<Participant[]>>
    ] = useState<Participant[]>(participantsData?.participants ?? [])

    const { noSessionsInCohort, noSearchResults, noSessionsData, noParticipantsData }: TDataStateForTable =
        getDataStateForTable({
            sessionsData: deferredSessionsData,
            participantsData: sortedParticipantsData,
            isSessionsDataLoading: isFetchingSessions,
            searchValue,
            selectedCohort
        })

    useEffect(
        (): void => (setSortedParticipantsData(participantsData?.participants), void 0),
        [participantsData?.participants]
    )

    const handleParticipantDataSort: (order: boolean, name?: ECoachTableHeaderName) => void = useCallback(
        (order: boolean, name: ECoachTableHeaderName) =>
            startTransition(
                () => (
                    name === ECoachTableHeaderName.Participants_Name &&
                        setSortedParticipantsData(prev =>
                            [...prev].sort((a, b) =>
                                order ? a.firstName.localeCompare(b.firstName) : b.firstName.localeCompare(a.firstName)
                            )
                        ),
                    name === ECoachTableHeaderName.Participants_CurrentModule &&
                        setSortedParticipantsData(prev =>
                            [...prev].sort((a, b) =>
                                order
                                    ? withTranslation(a.currentModule?.versionName)?.localeCompare(
                                          withTranslation(b.currentModule?.versionName)
                                      )
                                    : withTranslation(b.currentModule?.versionName)?.localeCompare(
                                          withTranslation(a.currentModule?.versionName)
                                      )
                            )
                        )
                )
            ),
        []
    )

    const { t } = useTranslation()

    return (
        <CoachLayout leftSidebar={null} headerTitle={<DateWidget className="coach-date-title" />}>
            <Header
                onSearch={setSearchValue}
                onClickSetupCalendar={onShowSetupModal}
                selectedPeriod={selectedPeriod}
                selectedTab={selectedTab}
                onSelectSessionPeriod={onSelectSessionPeriod}
            />

            {selectedTab === CoachDashboardTab.SESSIONS && (
                <CoachTable
                    {...coachSessionsTableConfig}
                    dataSource={deferredSessionsData}
                    currentPaginationPage={currentSessionsPaginationPage}
                    handlePageChange={changeCurrentSessionsPaginationPage}
                    totalPaginationCount={sessionData?.totalSessionsCount}
                    isLoading={isFetchingSessions}
                    onSort={sortBySessionTime}
                    testId="sessions-table"
                    error={isError ? getErrorMessages(error).join("") : null}
                    errorStatusText={getTableStatusText({
                        noSessionsData,
                        noSessionsInCohort,
                        noSearchResults,
                        tableType: ECoachTableType.Sessions
                    })}
                />
            )}
            {selectedTab === CoachDashboardTab.PARTICIPANTS && (
                <CoachTable
                    {...coachParticipantsTableConfig}
                    dataSource={sortedParticipantsData}
                    currentPaginationPage={currentSessionsPaginationPage}
                    handlePageChange={changeCurrentParticipantsPaginationPage}
                    totalPaginationCount={participantsData?.totalParticipantsCount}
                    isLoading={isParticipantsDataLoading}
                    onSort={handleParticipantDataSort}
                    testId="participants-table"
                    error={isErrorParticipants ? getErrorMessages(errorParticipants)?.join("") : null}
                    errorStatusText={getTableStatusText({
                        noSearchResults,
                        noParticipantsData,
                        tableType: ECoachTableType.Participants
                    })}
                />
            )}
            {selectedTab === CoachDashboardTab.GROUP && (
                <CoachTable
                    {...coachGroupsTableConfig}
                    dataSource={deferredSessionsData?.filter((s: Session): boolean => s?.cohortModality === "group")}
                    currentPaginationPage={currentSessionsPaginationPage}
                    handlePageChange={changeCurrentSessionsPaginationPage}
                    totalPaginationCount={sessionData?.totalSessionsCount}
                    isLoading={isFetchingSessions}
                    onSort={sortBySessionTime}
                    testId="groups-table"
                    error={isError ? getErrorMessages(error).join("") : null}
                    errorStatusText={getTableStatusText({
                        noSessionsData,
                        noSessionsInCohort,
                        noSearchResults,
                        tableType: ECoachTableType.Groups
                    })}
                />
            )}

            <CareTeamLink className="care-team-link" text={t("coachSide.careLink.questionText")} />
            <CoachSetupScheduleModal show={showSetupModal} onClose={() => onShowSetupModal(false)} />
        </CoachLayout>
    )
}

export default memo(CoachDashboard)
