import React from "react"

import ParticipantWrapper from "main-app/elements/participant-wrapper"
import { StakeHolderSurvey } from "main-app/features/stake-holder-survey"
import SimpleLayout from "main-app/layouts/SimpleLayout"

const StakeHolderSurveyPage = () => {
    return (
        <SimpleLayout withProfileHeader withoutHeader isSimpleHeader withFooter={false}>
            <ParticipantWrapper size="max-md">
                <StakeHolderSurvey />
            </ParticipantWrapper>
        </SimpleLayout>
    )
}

export default StakeHolderSurveyPage
