import React, { useCallback, useRef, useState } from "react"

import classNames from "classnames"

import useMediaQuery from "common/hooks/use-media-query"
import { useOnClickOutside } from "common/hooks/use-outside-click"
import { Option } from "common/models/option"

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"

import ContextMenu from "../menu/ContextMenu"

import Card from "./Card"

type Props = {
    className?: string
    menuItems: Option[]
    menuPosition?: "right" | "left" | "top" | "bottom"
    menuClassName?: string
    cardClassName?: string
    children: React.ReactNode
    showThreeDots?: boolean
    showContextMenu?: boolean
    menuOffsets?: Offset
    onClickOutside?: () => void
    onMenuItemClick: (item: Option) => void
}

type Offset = {
    right?: number
    left?: number
    top?: number
    bottom?: number
}

const OFFSET_TOP_MOBILE = 50
const OFFSET_LEFT_MOBILE = 80

const getPosition = (
    position: "right" | "left" | "top" | "bottom",
    target: HTMLElement,
    // eslint-disable-next-line default-param-last
    offsets: Offset = {},
    isMobile: boolean,
    _menuTarget: HTMLElement
) => {
    const { right = 0, left = 0, top = 0 } = offsets

    const targetHtml = target as HTMLElement

    if (!targetHtml) return {}

    if (isMobile) {
        return {
            top: `${OFFSET_TOP_MOBILE + top}px`,
            left: `${target.clientLeft + target.offsetWidth - OFFSET_LEFT_MOBILE + right}px`,
            transform: "translateX(-50%)"
        }
    }

    switch (position) {
        case "top":
            return {
                top: `${-target.offsetTop + top}px`,
                left: `${target.clientLeft + target.clientWidth / 2 + right}px`,
                transform: "translateX(-50%)"
            }
        case "bottom":
            return {
                top: `${target.offsetTop + target.offsetHeight + 10 + top}px`,
                left: `${target.clientLeft + target.offsetWidth + right}px`
            }
        case "left":
            return {
                top: `${top}px`,
                left: `${target.clientLeft - 200 + left}px`
            }
        case "right":
            return {
                top: `${top}px`,
                left: `${target.clientLeft + target.offsetWidth + right}px`
            }
        default:
            return {}
    }
}

const WithMenuCard = ({
    className = "",
    menuItems,
    menuClassName = "",
    cardClassName = "",
    menuPosition = "right",
    menuOffsets = {},
    showThreeDots = true,
    showContextMenu = true,
    children,
    onMenuItemClick,
    onClickOutside
}: Props) => {
    const ref = useRef<HTMLDivElement>()
    const menuRef = useRef<HTMLUListElement>()
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const onMenuClick = useCallback(() => {
        setIsOpenMenu(open => !open)
    }, [])

    useOnClickOutside(ref, _event => {
        setIsOpenMenu(false)
        onClickOutside?.()
    })

    return (
        <div ref={ref} className={classNames("position-relative cursor-pointer", className)}>
            <Card className={cardClassName} withMenu onMenuClick={onMenuClick} showMenu={showThreeDots}>
                {children}
            </Card>
            {isOpenMenu && showContextMenu && (
                <ContextMenu
                    ref={menuRef}
                    items={menuItems}
                    onClick={onMenuItemClick}
                    styles={getPosition(menuPosition, ref.current, menuOffsets, isMobile, menuRef.current)}
                    className={classNames("context-menu-card", menuClassName)}
                />
            )}
        </div>
    )
}

export default WithMenuCard
