import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { CoachRatingSource, ServerStateKeys } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { CoachRating } from "main-app/models/coach-rating"

const getCoachRating = async (source: CoachRatingSource) => {
    const { data } = await http.get(Urls.coachRating(source))
    return data
}

export default function useCoachRating(source: CoachRatingSource) {
    const { user } = useAuthContext()

    return useQuery([ServerStateKeys.CoachRating, { source }], () => getCoachRating(source), {
        select: useCallback(data => {
            return new CoachRating(data)
        }, []),
        enabled: !!user
    })
}
