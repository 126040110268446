import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import { ServerStateKeys } from "main-app/constants"

import Urls from "./urls"

export const getParticipantOnboardingResults = async ({ queryKey }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id }] = queryKey
    const { data } = await http.get(Urls.participantOnboardingInfo(id))
    return data
}

export default function useOnboardingResults(id: string) {
    return useQuery<any, AxiosError, any>([ServerStateKeys.OnboardingResults, { id }], getParticipantOnboardingResults)
}
