import React from "react"

import { Link } from "react-router-dom"

import Heading from "common/components/Heading/Heading"
import { isNullOrUndefined } from "common/utils/gates"

import useSpotCoaching from "main-app/api/use-spot-coaching"
import { CARE_TEAM_EMAIL, WISER_COACHING_LINK } from "main-app/constants"
import { SURVEY_STATUS } from "main-app/entities/survey/constants"
import { SurveyStatus } from "main-app/entities/survey/models"
import CareTeamLink from "main-app/shared/CareTeamLink"
import Button from "main-app/shared/button/Button"
import { LearnMore } from "main-app/shared/learn-more/LearnMore"

type Props = {
    status: SurveyStatus
    isSelfAssessment: boolean
}

export const FeedbackSurvey = ({ status, isSelfAssessment }: Props) => {
    const { data: productType } = useSpotCoaching()

    const productTypeData = isNullOrUndefined(productType)
        ? {
              supportEmail: CARE_TEAM_EMAIL.split(":")[1],
              supportTeamName: "Wiser Care"
          }
        : productType

    const text = {
        [SURVEY_STATUS.COMPLETED]: {
            heading: "Thank you for submitting.",
            description: "Your participation in this survey has supported the growth of another."
        },
        [SURVEY_STATUS.EXPIRED]: {
            heading: "Submission window has closed.",
            description: `This survey link has expired. If you think you’re seeing this message in error, please <a href="mailto:${productTypeData?.supportEmail}" class="stakeholder-survey-expired-care-link">contact Wiser Care.</a>`
        }
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <Heading textAlign="center">{text?.[status]?.heading ?? "Thank you for submitting."}</Heading>
            {!isSelfAssessment ? (
                <p
                    className="color-gray fs-14 text-center mb-50"
                    dangerouslySetInnerHTML={{ __html: text?.[status]?.description ?? "" }}
                />
            ) : (
                <div className="text-center mt-30">
                    <Link to="/">
                        <Button className="font-extrabold">Back To Dashboard</Button>
                    </Link>
                </div>
            )}

            {status === SURVEY_STATUS.COMPLETED && !isSelfAssessment && <LearnMore link={WISER_COACHING_LINK} />}
            <CareTeamLink text="Questions?" variant="brand" className="stakeholder-feedback-care-link" />
        </div>
    )
}
