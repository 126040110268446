import React, { useState } from "react"

import { withTranslation } from "common/utils/lang"

import { ReflectionComponentType } from "main-app/constants"
import { PairingQuestionAnswerOption } from "main-app/entities/pairing-question"
import { SurveyQuestionPreviosAnswer } from "main-app/entities/survey/models"
import { ColorRange, LikertColorType } from "main-app/models/types"
import getColor from "main-app/utils/get-color"

import LikertRadio from "../LikertRadio"

type Props = {
    options: PairingQuestionAnswerOption[]
    colorRange?: ColorRange[]
    likertColorType: LikertColorType
    likertType: ReflectionComponentType.LIKERT_GROUP | ReflectionComponentType.COLORED_LIKERT_GROUP
    register: any
    control: any
    name: any
    className?: string
    previosAnswer?: SurveyQuestionPreviosAnswer
    showOddLabels?: boolean
}

export const LikertRow = ({
    options,
    likertColorType,
    likertType,
    colorRange = [],
    register,
    control,
    previosAnswer,
    name,
    className = "",
    showOddLabels = false
}: Props) => {
    const isDefaultLikert = likertType === ReflectionComponentType.LIKERT_GROUP
    const [hoveredId, setHoveredId] = useState<string | null>(null)
    const likertColor = (index: number) => {
        if (isDefaultLikert) return ""
        return getColor(index, colorRange)
    }

    return (
        <fieldset data-testid="likert-row">
            <div
                className={`mb-4 level-choice level-choice--${likertColorType} ${className} d-flex justify-content-center`}>
                {options?.map((checkbox, index, options) => (
                    <LikertRadio
                        name={name}
                        previousAnswer={previosAnswer?.[index + 1] ?? ""}
                        register={register}
                        control={control}
                        hoveredId={hoveredId}
                        setHovered={setHoveredId}
                        showHover
                        label={withTranslation(checkbox.optionText)}
                        id={checkbox.uuid}
                        value={`${index + 1}`}
                        isFirstOption={index === 0}
                        key={checkbox.uuid}
                        color={likertColor(index + 1)}
                        showLabel={showOddLabels && options?.length % 2 !== 0 ? (index + 1) % 2 !== 0 : true}
                        isLastOption={index === options.length - 1}
                    />
                ))}
            </div>
            <legend className="d-none" />
        </fieldset>
    )
}
