import { DateTime } from "luxon"

import { i18n } from "common/i18n"

import { DATE_FORMAT } from "main-app/constants"

const getHoursMinutesTime = (datetime: DateTime) => {
    return datetime.toFormat(DATE_FORMAT.HOUR_MINUTES_MERIDIEM).toLowerCase().replace(/\s/g, "")
}

const formatDateSessions = (date: string, withDays: boolean = true) => {
    const parsedTime = DateTime.fromISO(date)
    const currentDate = DateTime.now()
    const isToday = currentDate.hasSame(parsedTime, "day")
    const isTomorrow = currentDate.plus({ day: 1 }).hasSame(parsedTime, "day")
    if (withDays) {
        if (isToday) {
            return `${i18n.t("Today")}, ${getHoursMinutesTime(parsedTime)}`
        }

        if (isTomorrow) {
            return `${i18n.t("Tomorrow")}, ${getHoursMinutesTime(parsedTime)}`
        }
    }

    return `${parsedTime.toFormat(DATE_FORMAT.SHORT_MONTH_DAY_NOT_PADDED)}, ${getHoursMinutesTime(parsedTime)}`
}

const formatHoldDate = (datetime: string, duration: number) => {
    const time = DateTime.fromISO(datetime)
    const endTime = time.plus({ minutes: duration })

    const startHour = getHoursMinutesTime(time)
    const endHour = getHoursMinutesTime(endTime)

    return `${time.toFormat(DATE_FORMAT.SHORT_WEEK_SHORT_MONTH_DAY)}, ${startHour} - ${endHour}`
}

const getTimezoneAbbreviation = () => DateTime.now().toFormat(DATE_FORMAT.TIMEZONE_ABBREVIATED)

const formatShortMonthDay = (date: string) =>
    DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat(DATE_FORMAT.SHORT_MONTH_DAY_NOT_PADDED) : ""

const formatMonthDayYear = (date: string) =>
    DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat(DATE_FORMAT.MONTH_DAY_YEAR) : ""

export {
    getHoursMinutesTime,
    formatDateSessions,
    formatHoldDate,
    getTimezoneAbbreviation,
    formatShortMonthDay,
    formatMonthDayYear
}
