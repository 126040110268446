import * as React from "react"

import { Link } from "react-router-dom"

import { ErrorPage } from "./ErrorPage"

export const ForbiddenComponent = () => {
    return (
        <ErrorPage
            errorContent={
                <div className="text-center">
                    <span className="font-extrabold color-gray">Access Denied.</span>{" "}
                    <br className="d-block d-md-none" />
                    <Link to="/" className="color-brand font-bold">
                        Back to dashboard
                    </Link>
                </div>
            }
        />
    )
}
