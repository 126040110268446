import React, { memo } from "react"

type Props = {
    className?: string
}

const AddIconSvg = ({ className }: Props) => {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <rect x="0.5" width="20" height="20" rx="10" fill="#FD4D00" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.66669 10.8333V15.3333H11.3334V10.8333H15.8334V9.16663H11.3334V4.66663H9.66669V9.16663H5.16669V10.8333H9.66669Z"
                fill="white"
            />
        </svg>
    )
}

export default memo(AddIconSvg)
