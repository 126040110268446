import { useEffect, useState } from "react"

import { PARTICIPANTS_IN_ROW_MAX_COUNT } from "main-app/constants"
import { ParticipantBase } from "main-app/models/participants"

interface ICroppedAndSortedParticipantList {
    sortedParticipantList: ParticipantBase[]
    isParticipantsAmountExceedingViewLimit: boolean
    exceedingParticipantsCount: number
}

type TParams = { participantList: ParticipantBase[] | []; viewLimit?: number }

type TReturn = ReturnType<({ participantList }: TParams) => ICroppedAndSortedParticipantList>

function useCroppedAndSortedParticipantList({
    participantList,
    viewLimit = PARTICIPANTS_IN_ROW_MAX_COUNT
}: TParams): TReturn {
    const [sortedParticipantList, setSortedParticipantList] = useState<ParticipantBase[] | []>(participantList ?? [])

    const isParticipantsAmountExceedingViewLimit: boolean = participantList.length > viewLimit

    const exceedingParticipantsCount: number = participantList.length - sortedParticipantList.length

    useEffect((): void => {
        const sortedArray: ParticipantBase[] = sortedParticipantList.sort(sortParticipantByPhotoAndName)

        // eslint-disable-next-line no-unused-expressions
        isParticipantsAmountExceedingViewLimit && setSortedParticipantList(sortedArray?.slice(0, viewLimit))
    }, [isParticipantsAmountExceedingViewLimit, sortedParticipantList, viewLimit])

    return { sortedParticipantList, isParticipantsAmountExceedingViewLimit, exceedingParticipantsCount }
}

export { useCroppedAndSortedParticipantList, type ICroppedAndSortedParticipantList }

function sortParticipantByPhotoAndName(pFirst?: ParticipantBase, pNext?: ParticipantBase): number {
    return pFirst?.photo && !pNext?.photo
        ? -1
        : !pFirst?.photo && pNext?.photo
          ? 1
          : pFirst?.firstName?.localeCompare(pNext.firstName)
}
