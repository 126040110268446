import React, { startTransition, useLayoutEffect } from "react"

import { useTranslation } from "react-i18next"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import useMediaQuery from "common/hooks/use-media-query"

import LeftSidebar from "main-app/components/dashboard/LeftSidebar"
import { dashboardURL } from "main-app/components/onboarding/constants"
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { SimulabHeader } from "main-app/entities/simulab"
import { SimulabCoacheeList } from "main-app/features/simulab-coachees"
import ParticipantLayout from "main-app/layouts/ParticipantLayout"
import CareTeamLink from "main-app/shared/CareTeamLink"

import "./styles.scss"

export const SimulabCoachees = () => {
    const { t } = useTranslation()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { user } = useAuthContext()

    const navigate: NavigateFunction = useNavigate()

    useLayoutEffect(
        (): void => (!user.practiceLab && startTransition((): void => navigate(dashboardURL)), void 0),
        [user, navigate]
    )

    return (
        <ParticipantLayout
            className="practice-lab-layout"
            noPadding
            showLogoOnMobile={false}
            leftSidebar={<LeftSidebar />}
            title={!isMobile && <SimulabHeader />}
            header={isMobile && <SimulabHeader />}>
            <section className="practice-lab-coachee">
                <p className="practice-lab-coachee__text">
                    {t("Who would you like to practice Spot Coaching with today?")}
                </p>
                <main className="practice-lab-coachee__wrapper">
                    <SimulabCoacheeList />
                </main>
            </section>
            <div className="practice-lab-layout__help">
                <CareTeamLink
                    text={t("If you experience any issues with our new feature:")}
                    variant="brand"
                    className="fs-14 m-0"
                />
            </div>
        </ParticipantLayout>
    )
}
