import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import OrangeStar from "main-app/svgs/OrangeStar"

import "./styles.scss"

interface IProps {
    className?: string
    text?: string
}

const ParticipantCaughtUp: React.FC<IProps> = ({ className, text = "" }) => {
    const { t } = useTranslation()
    const defaultText = t("Come back after your first coach meeting.")

    return (
        <div className={classNames("caught-up__hint", { [className]: className })}>
            <div>
                <OrangeStar />
                <span className="caught-up__colored ml-2">{t("You’re all caught up!")}</span>
                <span className="caught-up__gray d-block d-md-inline">{text || defaultText}</span>
            </div>
        </div>
    )
}

export default ParticipantCaughtUp
