import { useMutation } from "@tanstack/react-query"
import { AxiosPromise } from "axios"

import { http } from "common/http"

import { SsoOrganization } from "main-app/models/api/sso"

import Urls from "../urls"

export default function useSsoEmail() {
    return useMutation((email: string): AxiosPromise<SsoOrganization | ""> => http.post(Urls.ssoEmail(), { email }))
}
