import { useMutation } from "@tanstack/react-query"

import { http } from "common/http"

import Urls from "main-app/api/urls"

import { OnboardingSteps } from "../../constants"

interface StepDataApi {
    step: OnboardingSteps
    step_data?: any // TODO add model to handle different step data
}

export default function useNextStep() {
    return useMutation((data: StepDataApi) => http.post(Urls.onboardingNext(), data))
}
