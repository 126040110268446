import React, { useCallback, useState } from "react"

import { ThreeWayManagerAvatar, ThreeWayManagerSession } from "main-app/entities/three-way"
import { AttendanceStatus, AttendanceStatuses } from "main-app/models/types"

import { AttendeeRadioButton } from "./AttendeeRadioButton"

interface IProps {
    participant: ThreeWayManagerSession
    onMarkAttendanceExtraParty: (participant: ThreeWayManagerSession) => void
}

const AttendeeExtraPartyRow: React.FC<IProps> = ({ participant, onMarkAttendanceExtraParty }) => {
    const [marked, setMarked] = useState<{ checked: boolean; name: AttendanceStatus | "" }>({
        checked: !!participant.attendanceStatus,
        name: participant.attendanceStatus || ""
    })

    const handleRadioButtonClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const {
                checked,
                dataset: { id }
            } = event.target

            setMarked({ checked, name: id as AttendanceStatus })

            onMarkAttendanceExtraParty({ ...participant, attendanceStatus: id as AttendanceStatus })
        },
        [onMarkAttendanceExtraParty]
    )

    return (
        <div className="row mt-3 align-items-center attendee-row">
            <div className="col-4">
                <div className="d-flex align-items-center">
                    <ThreeWayManagerAvatar className="participant-image" />
                    <span className="font-extrabold ml-2">
                        {participant?.firstName} {participant?.lastName}
                    </span>
                </div>
            </div>
            <div className="col-4">
                <div className="d-flex justify-content-center">
                    <AttendeeRadioButton
                        status={AttendanceStatuses.NOT_PRESENT}
                        className="mr-2"
                        marked={marked}
                        id={participant.email}
                        type="cross"
                        name="attendee"
                        handleRadioButtonClick={handleRadioButtonClick}
                    />
                    <AttendeeRadioButton
                        status={AttendanceStatuses.PRESENT}
                        marked={marked}
                        id={participant.email}
                        name="attendee"
                        type="check"
                        handleRadioButtonClick={handleRadioButtonClick}
                    />
                </div>
            </div>
            <div className="col-4" />
        </div>
    )
}

export default AttendeeExtraPartyRow
