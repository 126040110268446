import React from "react"

import { useFormContext } from "react-hook-form"

import Switcher from "main-app/shared/switcher/Switcher"

import { ProfileCalendar } from "../models"
import { CalendarPrimaryFormValues } from "../types"

import "./styles.scss"

type Props = {
    name: string
    profile: ProfileCalendar
}

const CalendarSyncAvailabilityItem = ({ name, profile }: Props) => {
    const { watch, setValue } = useFormContext<CalendarPrimaryFormValues>()
    const value = watch(`calendars.${name}`)

    return (
        <div className="sync-calendar__item">
            <div className="flex-1">{profile.calendarName}</div>
            <Switcher
                isToggled={value}
                onToggle={() => setValue(`calendars.${name}`, !value, { shouldDirty: true })}
                label={value ? "Sync" : "Don't sync"}
            />
        </div>
    )
}

export default CalendarSyncAvailabilityItem
