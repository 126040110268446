import { useMutation } from "@tanstack/react-query"

import { http } from "common/http"

import { SurveySubmitData } from "../../models"
import { Urls } from "../urls"

export function useRespondentSurveySubmit(accessCode: string) {
    return useMutation((data: { response: SurveySubmitData[] }) => http.post(Urls.submitSurvey(accessCode), data))
}
