import React, { memo } from "react"

import classNames from "classnames"

import { SimulabFeedbackReport, SimulabTipBadge } from "main-app/entities/simulab"
import { WiserLogo } from "main-app/svgs"

import "../styles.scss"

type Props = {
    reportData: SimulabFeedbackReport
    className?: string
}

export const OwlFeedbackStageItem = memo(({ reportData, className = "" }: Props) => {
    return (
        <div className={classNames("owl-feedback-wrapper", className)} data-testid="owl-feedback-stage-item">
            <div className="wiser-logo-feedback">
                <WiserLogo width={30} height={20} />
            </div>
            <div className="owl-feedback-item">
                <header className="owl-feedback-item__header">
                    <p className="m-0">
                        {reportData?.emoji} {reportData?.title}
                    </p>
                </header>
                <div className="owl-feedback-item__body">
                    <p className={classNames({ "mb-10": !!reportData?.tip, "m-0": !reportData?.tip })}>
                        {reportData?.text}
                    </p>
                    {reportData?.tip && (
                        <p className="m-0">
                            <SimulabTipBadge /> <span className="color-gray">{reportData?.tip}</span>
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
})
