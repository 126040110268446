import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useSpotCoaching from "main-app/api/use-spot-coaching"
import { WiserLogo } from "main-app/svgs"

type Props = {
    className?: string
    width?: number
    heigth?: number
}

const ProductTypeLogo = ({ className, heigth = 34, width = 26 }: Props) => {
    const { data: productType } = useSpotCoaching()
    const { t } = useTranslation()

    return (
        <div className={classNames({ [className]: className })}>
            {productType?.logo ? (
                <img src={productType?.logo} width={width} height={heigth} alt={t("Wiser logo")} />
            ) : (
                <WiserLogo width={width} height={heigth} />
            )}
        </div>
    )
}

export default ProductTypeLogo
