import * as React from "react"

import CurrentModuleCircleSvg from "./CurrentModuleCircleSvg"

function InProgressModuleSvg(props: React.SVGProps<SVGSVGElement> & { isCurrent?: boolean }) {
    const { width = 19, height = 19, isCurrent = false } = props

    return (
        <span className="module-svg-wrapper">
            <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9.5" cy="9.5" r="9.5" fill="#FD4D00" />
                <path
                    d="M12.9287 9.5276L8.07597 12.4542C8.04262 12.4743 8.00009 12.4502 8.00015 12.4113L8.01013 6.58799C8.01019 6.5492 8.0525 6.52525 8.08579 6.54517L12.9286 9.44188C12.9609 9.46124 12.961 9.50812 12.9287 9.5276Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="1.78125"
                    strokeLinecap="square"
                />
            </svg>
            {isCurrent && <CurrentModuleCircleSvg />}
        </span>
    )
}

export default React.memo(InProgressModuleSvg)
