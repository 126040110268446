import React from "react"

import classNames from "classnames"

import ChevronToggle from "../chevron-toggle/ChevronToggle"

import Accordion from "./Accordion"

import "./styles.scss"

type Props = {
    className?: string
    active: boolean
    headerContent: React.ReactNode
    children: React.ReactNode
    onToggle: () => void
}

const AccordionCard = ({ className = "", active, children, headerContent, onToggle }: Props) => {
    const [isFocused, setIsFocused] = React.useState(false)

    return (
        <div className={classNames("accordion-card", className, { opened: active })} data-testid="accordion-card-box">
            <div
                className={classNames("accordion-card-header", { focused: isFocused })}
                onClick={onToggle}
                onKeyPress={e => e.key === "Enter" && onToggle()}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                role="button"
                tabIndex={0}
                aria-expanded={active}>
                <div className="accordion-card-header-content">{headerContent}</div>
                <div>
                    <ChevronToggle active={active} />
                </div>
            </div>
            <Accordion active={active}>
                <div className={classNames("accordion-card-body")}>{children}</div>
            </Accordion>
        </div>
    )
}

export default AccordionCard
