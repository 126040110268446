import React, { useCallback } from "react"

import { Draggable } from "react-beautiful-dnd"

import DragDropWrapper from "main-app/shared/drag-n-drop/DragDropWrapper"
import { GoalsSortType } from "main-app/shared/types/sort"

import BehavioralPracticeCard from "./BehavioralPracticeCard"

type Props = {
    practices: any[]
    archivedPractices: number[]
    sort: GoalsSortType
    showDrag?: boolean
    onBlurPractice: (index: number) => void
    onArchiveClick: (index: number) => void
    onDragEnd: (result: any) => void
    remove: (index: number) => void
}

const BehavioralPracticeList = ({
    practices,
    archivedPractices,
    sort,
    showDrag,
    onBlurPractice,
    onArchiveClick,
    onDragEnd,
    remove
}: Props) => {
    const isArchived = useCallback(
        id => {
            return archivedPractices?.includes(id)
        },
        [archivedPractices]
    )

    return (
        <DragDropWrapper onDragEnd={onDragEnd} droppableId="droppable" type="practice-goal-drag">
            {practices.map((practice, index) => (
                <Draggable key={`item-${practice.id}`} draggableId={`item-${practice.id}`} index={index}>
                    {(provided, snapshot) => {
                        const isArchivedPractice = isArchived(practice.id)

                        return (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                <BehavioralPracticeCard
                                    onBlurPractice={onBlurPractice}
                                    key={practice.id}
                                    remove={remove}
                                    previousValue={practice.description}
                                    sort={sort}
                                    index={index}
                                    snapshot={snapshot}
                                    provider={provided}
                                    showDrag={practices.length > 1 && !isArchivedPractice && showDrag}
                                    isArchived={isArchivedPractice}
                                    onArchiveClick={onArchiveClick}
                                />
                            </div>
                        )
                    }}
                </Draggable>
            ))}
        </DragDropWrapper>
    )
}

export default BehavioralPracticeList
