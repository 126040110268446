import { GoalsSortType } from "main-app/shared/types/sort"

import {
    useArchiveBehavioralPractice,
    useArchivedBehavioralPracticeData,
    useBehavioralPracticeData,
    useCreateBehavioralPractice,
    useEditBehavioralPractice,
    useSortBehavioralPractice,
    useUnarchiveBehavioralPractice
} from "../api/behavioral-practice.api"

type Options = {
    userId: number
    sort: GoalsSortType
    behavioralId: number
}

export function usePracticeApi({ userId, sort, behavioralId }: Options) {
    const { data: behavioralPractices, isLoading: isLoadingPractices } = useBehavioralPracticeData(
        userId,
        behavioralId,
        sort
    )
    const { data: archivedBehavioralPractices } = useArchivedBehavioralPracticeData(userId, behavioralId)
    const createPractice = useCreateBehavioralPractice(userId)
    const editPractice = useEditBehavioralPractice(userId)
    const archivePractice = useArchiveBehavioralPractice(userId)
    const unarchivePractice = useUnarchiveBehavioralPractice(userId)
    const sortPractice = useSortBehavioralPractice(userId)

    return {
        behavioralPractices,
        isLoadingPractices,
        archivedBehavioralPractices,
        createPractice,
        editPractice,
        archivePractice,
        unarchivePractice,
        sortPractice
    }
}
