import * as React from "react"

function CloseSvgIcon({ width = 24, height = 23 }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.34277 5.84375L17.6565 17.1575" stroke="#6F6F6F" strokeWidth="2.5" />
            <path d="M6.34277 17.1562L17.6565 5.84254" stroke="#6F6F6F" strokeWidth="2.5" />
        </svg>
    )
}

export default React.memo(CloseSvgIcon)
