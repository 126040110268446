import React, { SVGProps } from "react"

import "./styles.scss"

interface Props extends SVGProps<SVGSVGElement> {}

const ChatMessageLoaderSvg = (props: Props) => {
    const { width = 74, height = 29, className = "" } = props

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 74 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0.283203" width="73.1" height="28.6667" rx="7.16667" fill="#F3F3F3" />
            <circle cx="21.783" cy="14.3333" r="3.58333" fill="#B0B0B0" />
            <circle cx="36.8332" cy="14.3332" r="4.3" fill="#B0B0B0" />
            <circle cx="51.8831" cy="14.3333" r="3.58333" fill="#B0B0B0" />
        </svg>
    )
}

export default React.memo(ChatMessageLoaderSvg)
