import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    startTransition,
    useCallback,
    useDeferredValue,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react"

import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import useMediaQuery from "common/hooks/use-media-query"
import { isNullOrUndefined } from "common/utils/gates"
import { getErrorMessages } from "common/utils/get-error-messages"
import { withTranslation } from "common/utils/lang"

import useChapterComplete from "main-app/api/mutations/use-chapter-complete"
import useChapters from "main-app/api/use-chapters"
import usePhases from "main-app/api/use-phases"
import LeftSidebar from "main-app/components/dashboard/LeftSidebar"
import { ComponentType, MAX_WIDTH_MOBILE_MEDIA, ServerStateKeys, Status } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import UserHeaderDropdown from "main-app/elements/participant-layout-header/UserHeaderDropdown"
import { SimulabContextProvider, SimulabHeader } from "main-app/entities/simulab"
import { IChapterApi } from "main-app/models/chapter"
import ErrorBox from "main-app/shared/error-box/ErrorBox"
import Spinner from "main-app/shared/spinner/Spinner"
import { TEmptyCallback } from "main-app/shared/types/functions"
import { Simulab } from "main-app/widgets/simulab"
import { ESimulabBuiltInMode } from "main-app/widgets/simulab/ui/Simulab"

import {
    ChapterComponent,
    IContent,
    IMediaVideoVimeo,
    IQuestionFreeResponse,
    IQuestionLikerGroup,
    ITrackPractice,
    ITrackPracticeLab
} from "Manager/chapter/models/types"

import { dashboardURL } from "../onboarding/constants"

import ChapterLayout, { IChapterLayoutProps } from "./ChapterLayout"
import ChapterStepper from "./ChapterStepper"
import Content from "./Content"
import MediaVideo from "./MediaVideo"
import QuestionFreeResponse from "./QuestionFreeResponse"
import QuestionLikertGroup from "./QuestionLikertGroup"
import TrackPractice from "./TrackPractice"

import "./styles.scss"

type ChapterParams = {
    phaseIndex: string
    chapterIndex: string
    id: string
    moduleId: string
    order: string
    type: string
}

interface IProps {}

const Chapters: React.FC<IProps> = () => {
    const queryClient = useQueryClient()
    const params = useParams<ChapterParams>()
    const navigate = useNavigate()

    const { user, logout } = useAuthContext()
    const { data, isLoading, isError, refetch } = useChapters(+params.id)
    const { data: phases, isLoading: isLoadingPhases } = usePhases(+params.moduleId, { refecthOnMount: "always" })
    const completeChapter = useChapterComplete(+params.id)
    const anchorScroll = useRef(null)
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const [likertQuestionsWithSummary, setLikertQuestionsWithSummary] = useState([])
    const [questionsProgressBar, setQuestionsProgressBar] = useState(0)
    const [currentComponent, setCurrentComponent] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(null)
    const [submitError, setSubmitError] = useState(null)

    const { t } = useTranslation()

    const module = useMemo(
        () => user?.enrolledModules?.find(module => module.id === +params.moduleId),
        [params.moduleId, user]
    )

    if (isError) {
        navigate(`${dashboardURL}/${module?.rank ?? user?.module?.rank}`, { replace: true })
    }

    useEffect(() => {
        if (!isLoading && data) {
            // eslint-disable-next-line array-callback-return,consistent-return
            const component = data.components.find((component, index) => {
                if (params?.order) {
                    if (component?.order === +params?.order) {
                        setCurrentIndex(index)
                        return true
                    }
                    return false
                }
                if (component) {
                    setCurrentIndex(index)
                    return true
                }
            })

            setCurrentComponent(component)
            const url = `/chapter/learn/${params.moduleId}/${params.phaseIndex}/${params.chapterIndex}/${
                params.id
            }/${params?.order ?? component.order}/${params?.type ?? component.type}`

            navigate(url, { replace: true })
        }
    }, [isLoading, data])

    useEffect(() => {
        if (data) {
            const questionsUuids = data.components.reduce((questions, current) => {
                if (current.type === ComponentType["Question: Likert Group"] && current.show_summary_after_finish) {
                    questions.push(current.uuid)
                }
                return questions
            }, [])

            setLikertQuestionsWithSummary(questionsUuids)
        }
    }, [data])

    const onNextComponent = async (component: ChapterComponent, answer?: any, skipNavigation: boolean = false) => {
        const completeComponent = {
            ...component
        }

        const phaseNotComleted = phases?.[+params.phaseIndex]?.status === Status.InProgress

        const isNotEditableChapter = !data.editableAfterFinish

        if (
            data.status === Status.Completed &&
            ((!answer && !isLastComponentInLastPhase && !phaseNotComleted) || isNotEditableChapter)
        ) {
            onNextNavigation()
            return
        }

        if (component.type === ComponentType["Question: Free Response"]) {
            // @ts-expect-error legacy missing type
            completeComponent.answer_data = { answer }
        } else if (component.type === ComponentType["Question: Likert Group"]) {
            // @ts-expect-error legacy missing type
            completeComponent.answer_data = { answer_list: answer }
        } else if (component.type === ComponentType["Track Practice"]) {
            // @ts-expect-error legacy missing type
            completeComponent.answer_data = { rows: answer }
        } else {
            // @ts-expect-error legacy missing type
            completeComponent.answer_data = { completed: true }
        }

        const chapter: IChapterApi = {
            editable_after_finish: data?.editableAfterFinish,
            show_summary_after_finish: data?.showSummaryAfterFinish,
            chapter_summary: {
                summary: data?.chapterSummary?.summary,
                summary_subtitle: data?.chapterSummary?.summarySubtitle,
                summary_title: data?.chapterSummary?.summaryTitle
            },
            enrollment: data?.enrollment,
            phase_progress: data?.phaseProgress,
            uuid: data.uuid,
            title: data.title,
            status: data.status,
            components: data.components
                .reduce((components, current) => {
                    if (current.uuid === completeComponent.uuid) {
                        return [...components, completeComponent]
                    }

                    if ("answer_data" in current) {
                        return [...components, current]
                    }

                    return components
                }, [])
                .map(c =>
                    c.type === ComponentType["Track Practice"]
                        ? {
                              ...c,
                              answer_data: {
                                  ...c?.answer_data,
                                  practice_lab: (
                                      (c as ITrackPractice)?.practice_lab as ITrackPracticeLab
                                  )?.conversation_scenarios?.reduce((acc, cur) => {
                                      if (!isNullOrUndefined(cur?.conversation_id) && !isNullOrUndefined(cur?.id)) {
                                          acc.push({
                                              conversation_id: cur.conversation_id,
                                              conversation_scenario_id: cur.id
                                          })
                                      }
                                      return acc
                                  }, [])
                              }
                          }
                        : c
                )
        }

        completeChapter.mutate(chapter, {
            onSuccess: () => {
                const isQuestionLikertAndNotLastQuestion =
                    component.type === ComponentType["Question: Likert Group"] && skipNavigation

                const isTrackPractice = component.type === ComponentType["Track Practice"] && skipNavigation

                if (isQuestionLikertAndNotLastQuestion || isTrackPractice) {
                    isTrackPractice && refetch()
                    queryClient.invalidateQueries([ServerStateKeys.Phases, { id: +params.moduleId }])
                    return
                }
                onNextNavigation()
            },
            onError: (e: AxiosError) => {
                setSubmitError(getErrorMessages(e))
            }
        })
    }

    const onNextNavigation = () => {
        const chapters = phases?.[+params.phaseIndex]?.chapters

        const isLastComponentInLastChapter =
            +params.chapterIndex === chapters?.length - 1 && currentIndex === data?.components?.length - 1

        const isLastComponent = currentIndex === data.components.length - 1

        if (isMobile) {
            anchorScroll?.current?.scrollIntoView()
        }

        if (isLastComponentInLastChapter) {
            navigate(`${dashboardURL}/${module?.rank ?? user?.module?.rank}`, { replace: true })
            queryClient.invalidateQueries([ServerStateKeys.Phases, { id: +params.moduleId }])
            return
        }

        if (isLastComponent) {
            const nextChapterIndex = +params.chapterIndex + 1
            const nextChapterId = phases?.[+params.phaseIndex]?.chapters?.[nextChapterIndex]?.id

            queryClient.invalidateQueries([ServerStateKeys.ChapterLearn, nextChapterId])
            navigate(`/chapter/learn/${params.moduleId}/${params.phaseIndex}/${nextChapterIndex}/${nextChapterId}`, {
                replace: true
            })
        } else {
            const nextComponent = data.components[currentIndex + 1]
            setCurrentIndex(currentIndex + 1)
            setCurrentComponent(nextComponent)
            queryClient.invalidateQueries([ServerStateKeys.ChapterLearn, +params.id])
            navigate(
                `/chapter/learn/${params.moduleId}/${params.phaseIndex}/${params.chapterIndex}/${params.id}/${nextComponent.order}/${nextComponent.type}`,
                {
                    replace: true
                }
            )
        }
        queryClient.invalidateQueries([ServerStateKeys.Phases, { id: +params.moduleId }])
        setQuestionsProgressBar(0)
    }

    const computeQuestionsProgress = (questionsProgress: number) => {
        setQuestionsProgressBar(questionsProgress)
    }

    const isLastChapter = +params.chapterIndex === phases?.[+params.phaseIndex]?.chapters?.length - 1
    const isLastComponent = currentIndex === data?.components?.length - 1
    const isLastComponentInLastPhase = isLastChapter && isLastComponent

    const title = useMemo(() => {
        const currentModule = user?.enrolledModules.find(module => module.id === +params.moduleId)
        return `${withTranslation(currentModule?.name)}: ${withTranslation(phases?.[+params.phaseIndex]?.name) ?? ""}`
    }, [params.moduleId, user.enrolledModules, phases])

    const disabledBtn = isLoading || isLoadingPhases

    const isSimulabPanelVisible: boolean = useDeferredValue(
        user?.practiceLab &&
            currentComponent?.type === ComponentType["Track Practice"] &&
            currentComponent?.practice_lab?.conversation_scenarios?.length
    )

    const [isSimulabChatActive, setIsSimulabChatActive]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false)
    const [conversationId, setConversationId]: [number, Dispatch<SetStateAction<number>>] = useState<number>()

    const handleConversationScenarioNavigation: (id: number) => void = useCallback(
        (conversationId: number) => (setIsSimulabChatActive(true), setConversationId(conversationId)),
        []
    )

    const handleOnClickBackToPractice: TEmptyCallback = useCallback(
        () => (
            startTransition((): void => setIsSimulabChatActive(false)),
            window.scrollTo({ top: 0, behavior: "smooth" }),
            refetch()
        ),
        [refetch]
    )

    const chapterLayoutProps: Omit<IChapterLayoutProps, "children"> = {
        module,
        title,
        renderFooter: (): ReactElement => (
            <ChapterStepper
                chapters={phases?.[+params.phaseIndex]?.chapters}
                currentChapterIndex={+params.chapterIndex}
                components={data?.components}
                currentComponentIndex={currentIndex}
                currentComponent={data?.components?.[currentIndex]}
                questionsProgress={questionsProgressBar}
                currentPhase={phases?.[+params.phaseIndex]}
            />
        ),
        renderLeftSidebar: (): ReactElement => <LeftSidebar closedMenu integrated />,
        renderUserHeaderDropdown: (): ReactElement => <UserHeaderDropdown user={user} handleLogout={logout} />,
        className: "with-sidebar no-logo",
        ...(isSimulabChatActive && {
            className: "with-sidebar no-logo with-simulab"
        })
    }

    const renderTrackPractice = () =>
        isSimulabChatActive ? (
            <SimulabContextProvider conversationId={conversationId}>
                <SimulabHeader
                    showPracticeLink
                    showLogoOnMobile
                    handleOnClickShowPracticeLink={handleOnClickBackToPractice}
                    withFeedbackReport
                    withTooltip
                    statusTagVariant="outlined"
                />
                <Simulab
                    mode={ESimulabBuiltInMode.Integrated}
                    chatEndConversationButtonProps={{
                        onClick: handleOnClickBackToPractice,
                        children: t("chapter.trackPractice.simulabChat.backToPractice")
                    }}
                />
            </SimulabContextProvider>
        ) : (
            <TrackPractice
                component={currentComponent as ITrackPractice}
                chapterTitle={withTranslation(data?.title)}
                onNext={onNextComponent}
                isSimulabPanelVisible={isSimulabPanelVisible}
                navBarTitle={title}
                disabledBtn={disabledBtn}
                isLast={isLastComponentInLastPhase}
                handleConversationScenarioNavigation={handleConversationScenarioNavigation}
                phaseName={`${withTranslation(phases?.[+params.phaseIndex]?.name)}: ${
                    phases?.[+params.phaseIndex]?.order
                }`}
            />
        )

    return (
        <ChapterLayout {...chapterLayoutProps}>
            <div ref={anchorScroll} className="anchor-scroll" />
            {isLoading && !currentComponent ? (
                <div className="w-100 h-100 d-flex justify-content-center">
                    <Spinner />
                </div>
            ) : (
                <div className={classNames(isSimulabChatActive ? "chapter-chat" : "block-centered py-3")}>
                    {currentComponent?.type === ComponentType.Content && (
                        <Content
                            component={currentComponent as IContent}
                            onNext={onNextComponent}
                            disabledBtn={disabledBtn}
                            isLast={isLastComponentInLastPhase}
                        />
                    )}
                    {currentComponent?.type === ComponentType["Media: Video Vimeo"] && (
                        <MediaVideo
                            component={currentComponent as IMediaVideoVimeo}
                            onNext={onNextComponent}
                            disabledBtn={disabledBtn}
                            isLast={isLastComponentInLastPhase}
                        />
                    )}
                    {currentComponent?.type === ComponentType["Question: Free Response"] && (
                        <QuestionFreeResponse
                            component={currentComponent as IQuestionFreeResponse}
                            onNext={onNextComponent}
                            isEditable={data?.editableAfterFinish}
                            disabledBtn={disabledBtn}
                            isLast={isLastComponentInLastPhase}
                        />
                    )}
                    {currentComponent?.type === ComponentType["Question: Likert Group"] && (
                        <QuestionLikertGroup
                            component={currentComponent as IQuestionLikerGroup}
                            likertQuestionsWithSummary={likertQuestionsWithSummary}
                            onNext={onNextComponent}
                            isEditable={data?.editableAfterFinish}
                            chapterSummary={data?.chapterSummary}
                            isLast={isLastComponentInLastPhase}
                            onNextNavigation={onNextNavigation}
                            disabledBtn={disabledBtn}
                            setQuestionsProgressBar={computeQuestionsProgress}
                        />
                    )}
                    {currentComponent?.type === ComponentType["Track Practice"] && renderTrackPractice()}
                    {submitError ? <ErrorBox className="mx-auto mt-4">{submitError}</ErrorBox> : null}
                </div>
            )}
        </ChapterLayout>
    )
}

export default Chapters
