import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"

import { QueryKey } from "../constants"

const getAvConfirmRules = async () => {
    const { data } = await http.get(Urls.confrimAvRules())
    return data
}

export function useAvailabilityConfirmRules() {
    return useQuery<any, AxiosError, any>([QueryKey.AvConfirmRules], getAvConfirmRules, {
        refetchOnMount: "always"
    })
}
