import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"

import { QueryKey } from "../constants"
import { CalendarAccount } from "../models"

import { CalendarAccountApi } from "./types"

const getCalendarProfiles = async (): Promise<CalendarAccountApi[]> => {
    const { data } = await http.get(Urls.coachCalendarProfiles())
    return data
}

export function useCalendarProfiles(enabled: boolean) {
    return useQuery<CalendarAccountApi[], AxiosError, CalendarAccount[]>([QueryKey.Calendars], getCalendarProfiles, {
        select: useCallback(data => data.map(calendar => new CalendarAccount(calendar)), []),
        enabled,
        refetchOnMount: "always"
    })
}
