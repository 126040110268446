import React from "react"

import classNames from "classnames"

import Button from "main-app/shared/button/Button"
import CheckMarkGray from "main-app/svgs/CheckMarkGray"

import { BehavioralPracticeStatus } from "../../consts"
import { PracticeStatus } from "../../model/behavioral-goal"

import "./styles.scss"

type Props = {
    status: PracticeStatus
    className?: string
    onClick: () => void
}

const BehavioralStatus = ({ status, onClick, className }: Props) => {
    return (
        <Button
            variant="default"
            className={classNames("p-0 behavioral-practice-card-btn-status", className)}
            onClick={onClick}
            data-btn="btn-status">
            <div
                className={classNames("behavioral-practice-card-status d-flex align-items-center", {
                    queued: status === BehavioralPracticeStatus.Queued,
                    active: status === BehavioralPracticeStatus.Active,
                    done: status === BehavioralPracticeStatus.Done
                })}>
                <span className="flex-1">{status}</span>
                {status === BehavioralPracticeStatus.Done && (
                    <span className="ml-1 d-flex align-items-center">
                        <CheckMarkGray />
                    </span>
                )}
            </div>
        </Button>
    )
}

export default BehavioralStatus
