import React from "react"

import classNames from "classnames"
import sanitizeHtml from "sanitize-html"

import { isNullOrUndefined } from "common/utils/gates"
import { TranslationObj, withTranslation } from "common/utils/lang"

import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage"
import { ReflectionComponentType } from "main-app/constants"
import { AnswerOption } from "main-app/entities/pairing-question"
import { SurveyQuestionPreviosAnswer } from "main-app/entities/survey/models"
import { LikertColorType } from "main-app/models/types"

import { LikertRow } from "./NewLikertRow"

type Props = {
    className?: string
    rowClassName?: string
    questionNumber?: number
    item: AnswerOption
    question: TranslationObj
    register: any
    control: any
    errors: any
    error?: any
    name?: string
    color?: LikertColorType
    previousAnswer?: SurveyQuestionPreviosAnswer
    likertType?: ReflectionComponentType.LIKERT_GROUP | ReflectionComponentType.COLORED_LIKERT_GROUP
    showOddLabels?: boolean
    headerTextClassName?: string
    questionStyle?: "one-line" | "brand"
}

export const LikertComponent = ({
    className,
    questionNumber,
    rowClassName,
    item,
    register,
    control,
    errors,
    error,
    question,
    previousAnswer,
    color = "gray",
    name,
    questionStyle = "brand",
    headerTextClassName,
    showOddLabels = false,
    likertType
}: Props) => {
    const isDefaultLikert =
        item.component_type === ReflectionComponentType.LIKERT_GROUP ||
        likertType === ReflectionComponentType.LIKERT_GROUP
    const likertColorType = isDefaultLikert ? color : "colored"
    const likertComponentType = isDefaultLikert
        ? ReflectionComponentType.LIKERT_GROUP
        : ReflectionComponentType.COLORED_LIKERT_GROUP

    const showQuestionNumber =
        !isNullOrUndefined(questionNumber) || (typeof questionNumber === "number" && questionNumber > 0)

    return (
        <div className={className}>
            {questionStyle === "brand" ? (
                <>
                    {showQuestionNumber && (
                        <h4 className="h5 color-brand text-uppercase font-extrabold title-underline mb-3">
                            <span>{questionNumber}</span>
                        </h4>
                    )}
                    <div
                        className="text-container--sm mb-40 text-center"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(withTranslation(question), { allowedTags: [] })
                        }}
                    />
                </>
            ) : (
                <p className={classNames("d-flex mb-20", headerTextClassName)}>
                    {showQuestionNumber && <span className="font-extrabold mr-2">{questionNumber}.</span>}
                    <span
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(withTranslation(question), { allowedTags: [] })
                        }}
                    />
                </p>
            )}

            <LikertRow
                name={name}
                control={control}
                previosAnswer={previousAnswer}
                register={register}
                options={item?.options}
                likertColorType={likertColorType}
                className={rowClassName}
                likertType={likertComponentType}
                showOddLabels={showOddLabels}
            />
            <WarningMessage message={error ?? errors?.[name]?.message?.toString()} />
        </div>
    )
}
