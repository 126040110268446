import React, { useMemo } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import sanitizeHtml from "sanitize-html"

import { withTranslation } from "common/utils/lang"

import usePhases from "main-app/api/use-phases"
import { useAuthContext } from "main-app/context/Auth"

import ChapterLayout from "./ChapterLayout"

interface IProps {}

const ChapterStart: React.FC<IProps> = () => {
    const { t } = useTranslation()
    const params = useParams()
    const navigate = useNavigate()

    const { user } = useAuthContext()
    const { data } = usePhases(+params.moduleId)

    const renderFooter = () => (
        <div className="container-fluid">
            <div className="footer-welcome text-center">
                <span className="footer-welcome-icon">
                    <svg viewBox="0 0 26 24">
                        <path
                            d="M13 0.75L16.9369 8.03535L25.25 9.43973L19.37 15.3467L20.5709 23.5L13 19.8654L5.42908 23.5L6.63 15.3467L0.75 9.43973L9.06312 8.03535L13 0.75Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>
                <h5 className="h5 text-uppercase color-inherit font-extrabold">{t("Optimize the Experience")}</h5>
                <p className="mb-0 font-sm">
                    {" "}
                    {t(
                        "We have to go deep to make change. Be honest with yourself, recognize where you have room to grow, and be engaged in your practice."
                    )}
                </p>
            </div>
        </div>
    )

    const isFirstPhase = +params.phaseIndex === 0

    const currentModule = useMemo(() => {
        return user?.enrolledModules?.find(module => module.id === +params.moduleId)
    }, [user.enrolledModules, params.moduleId])

    const phaseName = data?.[+params.phaseIndex]?.name

    const pageTitle = `${withTranslation(currentModule?.name)}: ${withTranslation(phaseName)}`

    const onClickStart = () => {
        navigate(`/chapter/learn/${params.moduleId}/${params.phaseIndex}/${params.chapterIndex}/${params.id}`, {
            replace: true
        })
    }

    return (
        <ChapterLayout module={currentModule} title={pageTitle} renderFooter={renderFooter}>
            <div className="block-centered py-3 text-center ">
                <div className="h-100 d-flex align-items-center">
                    <div className="container--phase">
                        <h1 className="h1 font-extrabold welcome-title">
                            {isFirstPhase ? `${t("Welcome")}: ${withTranslation(currentModule?.name)}` : pageTitle}
                        </h1>
                        <h2 className="h5 mb-3 text-uppercase color-brand font-extrabold">{t("What’s The Focus?")}</h2>
                        <div
                            className="welcome-short-description"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(withTranslation(data?.[+params.phaseIndex]?.shortDescription))
                            }}
                        />
                        <div
                            className="text-container font-weight-normal"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(withTranslation(data?.[+params.phaseIndex]?.longDescription))
                            }}
                        />
                        <a href="#" className="btn btn-next btn--brand" onClick={onClickStart}>
                            {t("Start")}
                        </a>
                    </div>
                </div>
            </div>
        </ChapterLayout>
    )
}

export default ChapterStart
