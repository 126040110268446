import React from "react"

import classNames from "classnames"
import { Link } from "react-router-dom"

type Props = {
    className?: string
}

export const CopywriteText = ({ className = "" }: Props) => {
    return (
        <p className={classNames("color-gray fs-14 mb-0", className)}>
            © {new Date().getFullYear()} GetWiser, Inc. All Rights Reserved
            <svg
                width="2"
                height="19"
                viewBox="0 0 2 19"
                className="ml-2 mr-2 d-none d-md-inline"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0V19" stroke="#6F6F6F" strokeWidth="0.5" />
            </svg>
            <Link
                to="/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="color-gray text-decoration-underline d-block d-md-inline">
                Privacy Policy
            </Link>
        </p>
    )
}
